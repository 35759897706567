import produce from 'immer';

const estadoInicial = {
  resgatando: false,
  atualizando: false,
  nomeFantasia: null,
  razaoSocial: null,
  cnpj: null,
  responsavel: null,
  email: null,
  celular: null,
  telefone: null,
  endRua: null,
  endNumero: null,
  endBairro: null,
  endCidade: null,
  endEstado: null,
  endCep: null,
  dataExpiracao: null,
  dataResgate: null,
  enderecoEntrega: {},
  programas: null,
  segmetnos: null,
  endComplemento: null,
  saldoPontos: null,
  totalPontos: null,
  aceitouRegulamento: null,
  programa: null,
  cartao: null,
  idTemporada: null,
};

export default function proprietario(state = estadoInicial, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@autenticacaoProprietario/DESAUTENTICAR': {
        draft.resgatando = false;
        draft.atualizando = false;
        draft.nomeFantasia = null;
        draft.razaoSocial = null;
        draft.cnpj = null;
        draft.responsavel = null;
        draft.email = null;
        draft.celular = null;
        draft.telefone = null;
        draft.endRua = null;
        draft.endNumero = null;
        draft.endBairro = null;
        draft.endCidade = null;
        draft.endEstado = null;
        draft.endCep = null;
        draft.endComplemento = null;
        draft.saldoPontos = null;
        draft.totalPontos = null;
        draft.programas = null;
        draft.segmetnos = null;
        draft.aceitouRegulamento = null;
        draft.programa = null;
        draft.programa = null;
        draft.idTemporada = null;
        break;
      }
      case '@autenticacaoProprietario/AUTENTICAR_SUCESSO': {
        draft.nomeFantasia = action.payload.proprietario.nomeFantasia;
        draft.razaoSocial = action.payload.proprietario.razaoSocial;
        draft.cnpj = action.payload.proprietario.cnpj;
        draft.cpf = action.payload.proprietario.cpf;
        draft.email = action.payload.proprietario.email;
        draft.celular = action.payload.proprietario.celular;
        draft.telefone = action.payload.proprietario.telefone;
        draft.endRua = action.payload.proprietario.endRua;
        draft.endNumero = action.payload.proprietario.endNumero;
        draft.endBairro = action.payload.proprietario.endBairro;
        draft.endCidade = action.payload.proprietario.endCidade;
        draft.endEstado = action.payload.proprietario.endEstado;
        draft.endCep = action.payload.proprietario.endCep;
        draft.endComplemento = action.payload.proprietario.endComplemento;
        draft.saldoPontos = action.payload.proprietario.saldoPontos;
        draft.totalPontos = action.payload.proprietario.totalPontos;
        draft.programas = action.payload.proprietario.programas;
        draft.segmento = action.payload.proprietario.segmento;
        draft.responsavel = action.payload.proprietario.responsavel;
        draft.aceitouRegulamento =
          action.payload.proprietario.aceitouRegulamento;
        draft.programa = action.payload.proprietario.programa;
        draft.dataExpiracao = action.payload.proprietario.dataExpiracao;
        draft.dataResgate = action.payload.proprietario.dataResgate;

        draft.enderecoEntrega = action.payload.proprietario.enderecoEntrega;
        draft.cartao = action.payload.proprietario.cartao;
        draft.idTemporada = action.payload.proprietario.idTemporada;
        break;
      }
      case '@proprietario/ATUALIZAR_PERFIL': {
        draft.atualizando = true;
        break;
      }
      case '@proprietario/ATUALIZAR_PERFIL_FALHOU': {
        draft.atualizando = false;
        break;
      }
      case '@proprietario/ATUALIZAR_PERFIL_SUCESSO': {
        draft.nomeFantasia = action.payload.nomeFantasia;
        draft.responsavel = action.payload.responsavel;
        draft.email = action.payload.email;
        draft.celular = action.payload.celular;
        draft.telefone = action.payload.telefone;
        draft.endRua = action.payload.endRua;
        draft.endNumero = action.payload.endNumero;
        draft.endBairro = action.payload.endBairro;
        draft.endCidade = action.payload.endCidade;
        draft.endEstado = action.payload.endEstado;
        draft.endCep = action.payload.endCep;
        draft.endComplemento = action.payload.endComplemento;
        draft.atualizando = false;
        break;
      }
      case '@proprietario/RESGATAR_PREMIO': {
        draft.resgatando = true;
        break;
      }
      case '@proprietario/RESGATAR_PREMIO_FALHOU': {
        draft.resgatando = false;
        break;
      }
      case '@proprietario/RESGATAR_PREMIO_SUCESSO': {
        draft.resgatando = false;
        draft.saldoPontos = action.payload.saldoNovo;
        if (action.payload.cartao) {
          draft.cartao = action.payload.cartao;
        }
        break;
      }
      case '@proprietario/ACEITAR_REGULAMENTO': {
        draft.aceitouRegulamento = '1';
        break;
      }
      case '@proprietario/ALTERAR_STATUS_CARTAO': {
        draft.cartao.bloqueado = action.payload.status;
        draft.cartao.emDesbloqueio = action.payload.emDesbloqueio;
        if (
          action.payload.status === 1 &&
          action.payload.emDesbloqueio === false
        ) {
          draft.cartao = null;
        }
        break;
      }
      default:
    }
  });
}
