import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import './styles.css';
import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import sortFuncDate from '../../../utils/ordernarColunaDate';

const formatarPontos = (celula, valor) => {
  return Number(valor.previsaoPontos).toLocaleString('pt-BR');
};

const formatarPontosRevenda = (celula, valor) => {
  return Number(valor.previsaoPontosRevenda).toLocaleString('pt-BR');
};
const formatarPontosRecebidos = (celula, valor) => {
  return valor?.pontosParcial
    ? Number(valor?.pontosParcial).toLocaleString('pt-BR')
    : '-';
};

const formatarDataValidade = (celula, valor) => {
  return moment(valor.venceEm).format('DD/MM/YYYY');
};

const formatarData = (celula, valor) => {
  return moment(valor.pontuadoEm).format('DD/MM/YYYY');
};

export default function Pontuacoes() {
  const tipoUsuario = useSelector((state) => state.autenticacao.tipoUsuario);
  const columns = [
    {
      dataField: 'parceiro',
      text: 'Nome',
      sortFunc: sortFunc,
      sort: true,
    },
    {
      dataField: 'revenda',
      text: 'PDV',
      sortFunc: sortFunc,
      sort: true,
    },
    {
      dataField: 'responsavel',
      text: 'Responsável',
      sortFunc: sortFunc,
      sort: true,
    },
    {
      dataField: 'realizadoEm',
      text: 'Data da Pontuação',
      formatter: formatarData,
      sortFunc: sortFuncDate,
      sort: true,
    },
    {
      dataField: 'previsaoPontos',
      text: 'Pontos',
      formatter: formatarPontos,
      sortFunc: sortFuncDate,
      sort: true,
      hidden: tipoUsuario === 'proprietario',
    },
    {
      dataField: 'previsaoPontos',
      text: 'Pontos parcial',
      formatter: formatarPontosRecebidos,
      sortFunc: sortFuncDate,
      sort: true,
      hidden: tipoUsuario === 'proprietario',
    },
    {
      dataField: 'previsaoPontosRevenda',
      text: 'Pontos',
      formatter: formatarPontosRevenda,
      sortFunc: sortFunc,
      sort: true,
      hidden: tipoUsuario === 'parceiro',
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'validadePontos',
      text: 'Validade dos Pontos',
      formatter: formatarDataValidade,
      sortFunc: sortFuncDate,
    },
    {
      dataField: 'corrigirPontos',
      text: 'Corrigir Pts.',
      hidden: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: 'realizadoEm',
      order: 'desc',
    },
  ];

  useEffect(() => {
    buscarPontuacoes();
  }, []);

  const buscarPontuacoes = async () => {
    setBuscando(true);
    const resultado = await api.get('/api/pontuacoes');
    if (resultado) {
      setData(resultado.data.pontuacoes);
    }
    setBuscando(false);
  };

  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  return (
    <Container className="mt-5 mb-5">
      <div className="pb-3">
        <h5>PONTUAÇÃO</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div>
        <BootstrapTable
          bootstrap4
          noDataIndication={() =>
            buscando ? (
              <span>
                Carregando
                <i className="fas fa-circle-notch fa-spin ml-3"></i>
              </span>
            ) : (
              <span>Sem resultados</span>
            )
          }
          className="tabela-fundo-claro"
          keyField="id"
          data={data}
          columns={columns}
          pagination={paginationFactory()}
          defaultSorted={defaultSorted}
          headerClasses="tabela-header-claro"
          bodyClasses="tabela-body-claro"
        />
      </div>
    </Container>
  );
}
