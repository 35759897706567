/*  eslint-disable-next-line */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container } from 'react-bootstrap';

export default function Relatorio() {
  return (
    <Container fluid className="h-100">
      <h4 className="pt-3 pb-3 f-black">Relatório</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div className="w-100 h-100">
        <iframe
          title="Smart - Menu"
          className="w-100 h-100 mb-5"
          src="https://app.powerbi.com/reportEmbed?reportId=29a22008-95ce-44bb-b263-b16f6762b9f5&autoAuth=true&ctid=7b19ce6b-5f49-41c9-bbae-e1c12fcd0b0a&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1iLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    </Container>
  );
}
