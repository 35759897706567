/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import { isEmpty, toInteger } from 'lodash';

import { useFetch } from '../../../hooks/useFetch';
import { useObject } from '../../../hooks/useObject';
import { mascaraPontosMilhar } from '../../../helpers/regioes';
import './styles.css';
import { useSelector } from 'react-redux';
import { moment } from '../../../helpers/date';

export default function VerAgir() {
  const { regiaoPermissao, programaPermissao } = useSelector(
    (state) => state.usuario
  );
  const [data, setData] = useState({});
  const [filtros, setFiltro] = useObject({
    programas: programaPermissao ?? '',
    regiao: regiaoPermissao ? regiaoPermissao : '',
    mes: '',
    ano: '',
  });

  const [VerAgir, fetchVerAgir] = useFetch(`/api/ver-agir`, 'post', filtros);
  const [programas, fetchProgramas] = useFetch('/api/programas', 'get');
  const [regioes, fetchRegioes] = useFetch('/api/regioes', 'get');
  const { data: dataVer, isFetching } = VerAgir;

  const buscarDados = async () => {
    if (!isEmpty(dataVer)) {
      setData(dataVer);
    }
  };

  const handleClick = (e) => {
    fetchVerAgir({});
  };

  useEffect(() => {
    fetchVerAgir({});
    fetchProgramas({});
    fetchRegioes({});
  }, []);

  useEffect(() => {
    buscarDados();
  }, [isFetching]);

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Ver e Agir</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={6} lg={3} className="d-flex flex-column">
          <label htmlFor="regiao">Selecione uma Região</label>
          <select
            className="input-select mb-3"
            name="regiao"
            id="regiao"
            onChange={(e) => setFiltro({ ...filtros, regiao: e.target.value })}
            disabled={regiaoPermissao}
            placeholder=""
          >
            <option value="">Todas</option>
            {!isEmpty(regioes.data)
              ? regioes.data.regioes.map((regiao) => {
                  let isSelected = regiaoPermissao === regiao.nome;
                  return (
                    <option
                      selected={isSelected}
                      value={regiao.nome}
                      key={regiao.id}
                    >
                      {regiao.nome}
                    </option>
                  );
                })
              : ''}
          </select>
        </Col>
        <Col xs={12} sm={6} md={6} lg={3} className="d-flex flex-column">
          <label htmlFor="programaSelecionada">Programa</label>
          <select
            className="input-select mb-3"
            name="programaSelecionada"
            id="programaSelecionada"
            onChange={(e) =>
              setFiltro({ ...filtros, programas: e.target.value })
            }
            disabled={programaPermissao}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {!isEmpty(programas.data)
              ? programas.data.programas.map((programa) => {
                  let isSelected =
                    Number(programaPermissao) === Number(programa.id);
                  return (
                    <option
                      value={programa.id}
                      key={programa.id}
                      selected={isSelected}
                    >
                      {programa.nome}
                    </option>
                  );
                })
              : ''}{' '}
          </select>
        </Col>

        <Col
          xs={2}
          md={1}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block searchBtn"
        >
          <button className="btn-busca" type="button" onClick={handleClick}>
            {isFetching ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search text-white"></i>
            )}
          </button>
        </Col>
      </Row>

      {/* <Row>
        <Col className="mb-3" xs={12} md={6}>
          <h5 className="pl-3 mb-0 pt-2">
            <strong>Proprietário</strong>
          </h5>
        </Col>
        <Col className="mb-3" xs={12} md={6}>
          <h5 className="pl-3 mb-0 pt-2">
            <strong>Proprietário</strong>
          </h5>
        </Col>
      </Row> */}

      <Row>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <a
            className="card-link-garcom"
            href="/painel/proprietario-sem-acessar"
          >
            <div className="card-dash" style={{ backgroundColor: '#CE8375' }}>
              <Col xs={2} md={2}>
                <i className="fas fa-store text-white icon-blocks-dash"></i>
              </Col>
              <Col xs={10} md={10} className="pl-5">
                <Row>
                  <span className="card-dash-subtitulo">
                    {isFetching ? (
                      <i className="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      data.proprietarioSemAcessar
                    )}
                  </span>
                </Row>
                <Row>
                  <span className="card-dash-titulo">
                    Donos que não acessaram
                  </span>
                </Row>
              </Col>
            </div>
          </a>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <a
            className="card-link-garcom"
            href="/painel/proprietario-resgate-disponivel"
          >
            <div className="card-dash" style={{ backgroundColor: '#CE8375' }}>
              <Col xs={2} md={2}>
                <i className="fas fa-gift text-white icon-blocks-dash"></i>
              </Col>
              <Col xs={10} md={10} className="pl-5">
                <Row>
                  <span className="card-dash-subtitulo">
                    {isFetching ? (
                      <i className="fas fa-check-circle fa-spin"></i>
                    ) : (
                      data.proprietariosResgatar
                    )}
                  </span>
                </Row>
                <Row>
                  <span className="card-dash-titulo">
                    Donos com resgate disponível
                  </span>
                </Row>
              </Col>
            </div>
          </a>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <a className="card-link-garcom" href="/painel/parceiros-sem-acessar">
            <div className="card-dash" style={{ backgroundColor: '#CE8375' }}>
              <Col xs={2} md={2}>
                <i className="fas fa-user-alt text-white icon-blocks-dash"></i>
              </Col>

              <Col xs={10} md={10} className="pl-5">
                <Row>
                  <span className="card-dash-subtitulo">
                    {isFetching ? (
                      <i className="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      mascaraPontosMilhar(data.garcomSemAcessar7)
                    )}
                  </span>
                </Row>
                <Row>
                  <span className="card-dash-titulo">
                    Garçons com + de 7 dias sem acessar{' '}
                  </span>
                </Row>
              </Col>
            </div>
          </a>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <a
            className="card-link-garcom"
            href="/painel/parceiros-sem-acessar-15"
          >
            <div className="card-dash" style={{ backgroundColor: '#CE8375' }}>
              <Col xs={2} md={2}>
                <i className="fas fa-user-alt text-white icon-blocks-dash"></i>
              </Col>
              <Col xs={10} md={10} className="pl-5">
                <Row>
                  <span className="card-dash-subtitulo">
                    {isFetching ? (
                      <i className="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      mascaraPontosMilhar(data.garcomSemAcessar)
                    )}
                  </span>
                </Row>
                <Row>
                  <span className="card-dash-titulo">
                    Garçons com + de 15 dias sem acessar{' '}
                  </span>
                </Row>
              </Col>
            </div>
          </a>
        </Col>
      </Row>

      <Row>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <a
            className="card-link-garcom"
            href="/painel/garcom-resgate-disponivel"
          >
            <div className="card-dash" style={{ backgroundColor: '#47526E' }}>
              <Col xs={2} md={2}>
                <i className="fas fa-gift text-white icon-blocks-dash"></i>
              </Col>
              <Col xs={10} md={10} className="pl-5">
                <Row>
                  <span className="card-dash-subtitulo">
                    {isFetching ? (
                      <i className="fas fa-check-circle fa-spin"></i>
                    ) : (
                      data.garcomResgatar ?? 0
                    )}
                  </span>
                </Row>
                <Row>
                  <span className="card-dash-titulo">
                    Garçons com resgate disponível
                  </span>
                </Row>
              </Col>
            </div>
          </a>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <a className="card-link-garcom" href="/painel/garcom-aniversariantes">
            <div className="card-dash" style={{ backgroundColor: '#47526E' }}>
              <Col xs={2} md={2}>
                <i className="fas fa-birthday-cake text-white icon-blocks-dash"></i>
              </Col>
              <Col xs={10} md={10} className="pl-5">
                <Row>
                  <span className="card-dash-subtitulo">
                    {isFetching ? (
                      <i className="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      data.aniversariantes
                    )}
                  </span>
                </Row>
                <Row>
                  <span className="card-dash-titulo">
                    Garçons Aniversariantes
                  </span>
                </Row>
              </Col>
            </div>
          </a>
        </Col>

        <Col className="mb-3" xs={12} md={6} lg={3}>
          <a className="card-link-garcom" href="/painel/garcom-regulamento">
            <div className="card-dash" style={{ backgroundColor: '#47526E' }}>
              <Col xs={2} md={2}>
                <i className="fas fa-user-times text-white icon-blocks-dash"></i>
              </Col>
              <Col xs={10} md={10} className="pl-5">
                <Row>
                  <span className="card-dash-subtitulo">
                    {isFetching ? (
                      <i className="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      mascaraPontosMilhar(data.garcomRegulamento)
                    )}
                  </span>
                </Row>
                <Row>
                  <span className="card-dash-titulo">
                    Garçons que não aceitaram regulamento
                  </span>
                </Row>
              </Col>
            </div>
          </a>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <a
            className="card-link-garcom"
            href="/painel/proprietario-regulamento"
          >
            <div className="card-dash" style={{ backgroundColor: ' #47526E' }}>
              <Col xs={2} md={2}>
                <i className="fas fa-store text-white icon-blocks-dash"></i>
              </Col>
              <Col xs={10} md={10} className="pl-5">
                <Row>
                  <span className="card-dash-subtitulo">
                    {isFetching ? (
                      <i className="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      mascaraPontosMilhar(data.donosRegulamento)
                    )}
                  </span>
                </Row>
                <Row>
                  <span className="card-dash-titulo">
                    Donos que não aceitaram regulamento
                  </span>
                </Row>
              </Col>
            </div>
          </a>
        </Col>
      </Row>

      <Row>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <a className="card-link-garcom" href="/painel/garcom-pontos-analise">
            <div className="card-dash" style={{ backgroundColor: '#FEA404' }}>
              <Col xs={2} md={2}>
                <i className="fas fa-user text-white icon-blocks-dash"></i>
              </Col>
              <Col xs={10} md={10} className="pl-5">
                <Row>
                  <span className="card-dash-subtitulo">
                    {isFetching ? (
                      <i className="fas fa-check-circle fa-spin"></i>
                    ) : (
                      data.garcomAnalise ?? 0
                    )}
                  </span>
                </Row>
                <Row>
                  <span className="card-dash-titulo">
                    Garçons com Pontuações em análise
                  </span>
                </Row>
              </Col>
            </div>
          </a>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <a className="card-link-garcom" href="/painel/garcom-enviou-tampa">
            <div className="card-dash" style={{ backgroundColor: '#FEA404' }}>
              <Col xs={2} md={2}>
                <i className="fas fa-user text-white icon-blocks-dash"></i>
              </Col>
              <Col xs={10} md={10} className="pl-5">
                <Row>
                  <span className="card-dash-subtitulo">
                    {isFetching ? (
                      <i className="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      <>
                        {!isEmpty(data.garcomEnviaramTampa) &&
                          data.garcomEnviaramTampa[0].total}
                        {/* <span className="sub-title">
                        (
                        {!isEmpty(data.garcomEnviaramTampa) &&
                          data.garcomEnviaramTampa[0].totalPontos}{' '}
                        tampas)
                      </span> */}
                      </>
                    )}
                  </span>
                </Row>
                <Row>
                  <span className="card-dash-titulo">
                    Garçons enviaram tampas em{' '}
                    {moment().subtract(1, 'months').format('MMMM')}
                  </span>
                </Row>
              </Col>
            </div>
          </a>
        </Col>

        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash" style={{ backgroundColor: '#FEA404' }}>
            <Col xs={2} md={2}>
              <i className="fas fa-user text-white icon-blocks-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <Row>
                <span className="card-dash-subtitulo">
                  {isFetching ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    <>
                      {!isEmpty(data.qtdTampasGarconsEnviadosOntem) &&
                        data.qtdTampasGarconsEnviadosOntem[0].totalTampas}
                      {/* <span className="sub-title">
                        (
                        {!isEmpty(data.garcomEnviaramTampa) &&
                          data.garcomEnviaramTampaOntem[0].totalPontos}{' '}
                        tampas)
                      </span> */}
                    </>
                  )}
                </span>
              </Row>
              <Row>
                <span className="card-dash-titulo">
                  Tampas enviadas ontem (
                  {!isEmpty(data.qtdTampasGarconsEnviadosOntem) &&
                    data.qtdTampasGarconsEnviadosOntem[0].totalGarcons}{' '}
                  {!isEmpty(data.qtdTampasGarconsEnviadosOntem) &&
                  data.qtdTampasGarconsEnviadosOntem[0].totalGarcons > 1
                    ? 'Garçons'
                    : 'Garçom'}
                  )
                </span>
              </Row>
            </Col>
          </div>
        </Col>

        {/* <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash" style={{ backgroundColor: '#FEA404' }}>
            <Col xs={2} md={2}>
              <i className="fas fa-store text-white icon-blocks-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <Row>
                <span className="card-dash-subtitulo">
                  {isFetching ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    data.pdvsSaldo ?? 0
                  )}
                </span>
              </Row>
              <Row>
                <span className="card-dash-titulo">PDVs com saldo</span>
              </Row>
            </Col>
          </div>
        </Col> */}
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash" style={{ backgroundColor: '#FEA404' }}>
            <Col xs={2} md={2}>
              <i className="fas fa-user text-white icon-blocks-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <Row>
                <span className="card-dash-subtitulo">
                  {isFetching ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    <>
                      {!isEmpty(data.qtdTampasGarconsEnviadosHoje) &&
                        data.qtdTampasGarconsEnviadosHoje[0].totalTampas}
                      {/* <span className="sub-title">
                        (
                        {!isEmpty(data.garcomEnviaramTampa) &&
                          data.garcomEnviaramTampaOntem[0].totalPontos}{' '}
                        tampas)
                      </span> */}
                    </>
                  )}
                </span>
              </Row>
              <Row>
                <span className="card-dash-titulo">
                  Tampas enviadas hoje (
                  {!isEmpty(data.qtdTampasGarconsEnviadosHoje) &&
                    data.qtdTampasGarconsEnviadosHoje[0].totalGarcons}{' '}
                  {!isEmpty(data.qtdTampasGarconsEnviadosHoje) &&
                  data.qtdTampasGarconsEnviadosHoje[0].totalGarcons > 1
                    ? 'Garçons'
                    : 'Garçom'}
                  )
                </span>
              </Row>
            </Col>
          </div>
        </Col>
      </Row>

      <hr className="hr-loja mt-0 mb-4" />

      <Row>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash" style={{ backgroundColor: '#D9AF7B' }}>
            <Col xs={2} md={2}>
              <i className="fas fa-store-alt text-white icon-blocks-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <Row>
                <span className="card-dash-subtitulo">
                  {isFetching ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    data.pdvsGarcons
                  )}
                </span>
              </Row>
              <Row>
                <span className="card-dash-titulo">Estação + Boteco</span>
              </Row>
            </Col>
          </div>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash" style={{ backgroundColor: '#D9AF7B' }}>
            <Col xs={2} md={2}>
              <i className="fas fa-plus-circle text-white icon-blocks-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <Row>
                <span className="card-dash-subtitulo">
                  {isFetching ? (
                    <i className="fas fa-check-circle fa-spin"></i>
                  ) : (
                    data.pdvsTampas
                  )}
                </span>
              </Row>
              <Row>
                <span className="card-dash-titulo">PDVs com tampas</span>
              </Row>
            </Col>
          </div>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash" style={{ backgroundColor: '#D9AF7B' }}>
            <Col xs={2} md={2}>
              <i className="fas fa-store-alt text-white icon-blocks-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <Row>
                <span className="card-dash-subtitulo">
                  {isFetching ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    data.pdvsSemTampas
                  )}
                </span>
              </Row>
              <Row>
                <span className="card-dash-titulo">PDVs sem tampas</span>
              </Row>
            </Col>
          </div>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash" style={{ backgroundColor: '#D9AF7B' }}>
            <Col xs={2} md={2}>
              <i className="fas fa-times-circle text-white icon-blocks-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <Row>
                <span className="card-dash-subtitulo">
                  {isFetching ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    data.pdvsSemGarcom
                  )}
                </span>
              </Row>
              <Row>
                <span className="card-dash-titulo">PDVs sem Garçom </span>
              </Row>
            </Col>
          </div>
          {/* <a className="card-link-garcom" href="/painel/pdv-sem-garcom">
          </a> */}
        </Col>
      </Row>
      <Row>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash" style={{ backgroundColor: '#4B602A' }}>
            <Col xs={2} md={2}>
              <i className="fas fa-plus-circle text-white icon-blocks-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <Row>
                <span className="card-dash-subtitulo">
                  {isFetching ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    data.garcomComTampas
                  )}
                </span>
              </Row>
              <Row>
                <span className="card-dash-titulo">Garçons com tampas</span>
              </Row>
            </Col>
          </div>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash" style={{ backgroundColor: '#4B602A' }}>
            <Col xs={2} md={2}>
              <i className="fas fa-beer text-white icon-blocks-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <a
								href="/painel/garcons/nao-pontuaram"
								className="link-card"
              >
                <Row>
                  <span className="card-dash-subtitulo">
                    {isFetching ? (
                      <i className="fas fa-check-circle fa-spin"></i>
                    ) : (
                      data.garcomSemTampas
                    )}
                  </span>
                </Row>
                <Row>
                  <span className="card-dash-titulo">Garçons sem tampas</span>
                </Row>
              </a>
            </Col>
          </div>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash" style={{ backgroundColor: '#4B602A' }}>
            <Col xs={2} md={2}>
              <i className="fas fa-check-circle text-white icon-blocks-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <Row>
                <span className="card-dash-subtitulo">
                  {isFetching ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    mascaraPontosMilhar(data.tampasEnviadas)
                  )}
                </span>
              </Row>
              <Row>
                <span className="card-dash-titulo">Tampas Enviadas</span>
              </Row>
            </Col>
          </div>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash" style={{ backgroundColor: '#4B602A' }}>
            <Col xs={2} md={2}>
              <i className="fas fa-plus-circle text-white icon-blocks-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <Row>
                <span className="card-dash-subtitulo">
                  {isFetching ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    mascaraPontosMilhar(data.totalDePontos)
                  )}
                </span>
              </Row>
              <Row>
                <span className="card-dash-titulo">Total de Pontos </span>
              </Row>
            </Col>
          </div>
        </Col>
      </Row>

      <hr className="hr-loja mt-0 mb-4" />
      <h5 className="pt-3 pb-3 f-black">Tampas enviadas Estação Eisenbahn </h5>

      <Row>
        {data.tampasEnviadasP2?.map((item, index) => (
          <Col key={index} className="mb-3" xs={12} md={6} lg={2}>
            <div className="card-dash" style={{ backgroundColor: '#394045' }}>
              <Col xs={12} md={12} className="p-3">
                <Row>
                  <span className="card-dash-subtitulo">
                    {isFetching ? (
                      <i className="fas fa-circle-notch fa-spin"></i>
                    ) : item.total ? (
                      mascaraPontosMilhar(item.total)
                    ) : (
                      0
                    )}
                  </span>
                </Row>
                <Row>
                  <span className="card-dash-titulo">{item.mes}</span>
                </Row>
              </Col>
            </div>
          </Col>
        ))}
      </Row>
      <hr className="hr-loja mt-0 mb-4" />
      <h5 className="pt-3 pb-3 f-black">Tampas enviadas Boteco Perfeito </h5>

      <Row>
        {data.tampasEnviadasP3?.map((item, index) => (
          <Col key={index} className="mb-3" xs={12} md={6} lg={2}>
            <div className="card-dash" style={{ backgroundColor: '#1F787D' }}>
              <Col xs={12} md={12} className="p-3">
                <Row>
                  <span className="card-dash-subtitulo">
                    {isFetching ? (
                      <i className="fas fa-circle-notch fa-spin"></i>
                    ) : item.total ? (
                      mascaraPontosMilhar(item.total)
                    ) : (
                      0
                    )}
                  </span>
                </Row>
                <Row>
                  <span className="card-dash-titulo">{item.mes}</span>
                </Row>
              </Col>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
