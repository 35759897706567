/*  eslint-disable-next-line */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Modal, Col, Row } from 'react-bootstrap';
import api from '../../../../services/API';
import { toast } from 'react-toastify';
import { useObject } from '../../../../hooks/useObject';

export default function ModalEditar(props) {
  const [editando, setEditando] = useState(false);
  const [data, setData] = useObject({});
  const [produtosMaisVendidos, setProdutosMaisVendidos] = useState([]);

  const [produto, setProduto] = useObject({});

  const atualizar = async (e) => {
    e.preventDefault();

    const dataUpdated = {
      ...data,
      produtosMaisVendidos: JSON.stringify(produtosMaisVendidos),
    };

    setEditando(true);
    const resultado = await api.put(
      `/api/pesquisa_mercado/${data.id}`,
      dataUpdated
    );

    if (resultado) {
      toast.success(`Pesquisa alterada com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      props.onHide();
      props.atualizarPesquisa({ ...dataUpdated, id: data.id });
    }
    setEditando(false);
  };

  useEffect(() => {
    if (props.data) {
      const {
        caixasVendidas,
        capacidadeClientes,
        nDeMesas,
        qtdGarcons,
        qtdGarconsFreela,
        tamanho_pdv,
        id,
        qtdeProdutosVendidos,
      } = props.data;

      let body = {
        id,
        caixasVendidas,
        capacidadeClientes,
        nDeMesas,
        qtdGarcons,
        qtdGarconsFreela,
        tamanho_pdv,
        qtdeProdutosVendidos,
      };
      setData(body);
      setProdutosMaisVendidos(
        props.data.produtosMaisVendidos
          ? JSON.parse(props.data.produtosMaisVendidos)
          : []
      );
    }
  }, [props]);

  const addProduto = (e) => {
    e.preventDefault();
    if (!produto) {
      toast.error('Informe produto e quantidade para adicionar', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });

      return;
    }
    const achou = produtosMaisVendidos.find((e) => {
      return e.nome === produto.nome && e.qtde === produto.qtde;
    });

    if (achou) {
      toast.error('Produto já adicionado', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });

      setProduto({ nome: '', qtde: '' });
    } else {
      setProdutosMaisVendidos([...produtosMaisVendidos, { ...produto }]);
      setProduto({ nome: '', qtde: '' });
    }
  };

  const delProduto = (e) => {
    setProdutosMaisVendidos(
      produtosMaisVendidos.filter((selecionado, index) => {
        return Number(index) !== Number(e.target.id);
      })
    );
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-3 mb-3">
        <div>
          <h4 className="pt-3 pb-3 f-black">Cadastrar Pesquisa de Mercado</h4>
          <hr className="hr-loja mt-0 mb-5" />
        </div>
        <div className="d-flex flex-column align-items-center">
          <form
            className="form"
            onSubmit={atualizar}
            style={{ maxWidth: '460px' }}
          >
            <Row>
              <Col xs={12} md={6}>
                <label htmlFor="caixasVendidas">Caixas Vendidas / semana</label>
                <input
                  id="caixasVendidas"
                  name="caixasVendidas"
                  type="number"
                  step="1"
                  required
                  value={data.caixasVendidas}
                  className="w-100"
                  onChange={(e) =>
                    setData({ ...data, caixasVendidas: e.target.value })
                  }
                ></input>
              </Col>
              <Col xs={12} md={6}>
                <label htmlFor="nDeMesas">Nº de Mesas</label>
                <input
                  id="nDeMesas"
                  name="nDeMesas"
                  type="number"
                  step="1"
                  required
                  value={data.nDeMesas}
                  className="w-100"
                  onChange={(e) =>
                    setData({ ...data, nDeMesas: e.target.value })
                  }
                ></input>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <label htmlFor="qntdGarcons">Qntd. Garçons</label>
                <input
                  id="qntdGarcons"
                  name="qntdGarcons"
                  type="number"
                  step="1"
                  required
                  value={data.qtdGarcons}
                  className="w-100"
                  onChange={(e) =>
                    setData({ ...data, qtdGarcons: e.target.value })
                  }
                ></input>
              </Col>
              <Col xs={12} md={4}>
                <label htmlFor="qtdGarconsFreela">Qntd. Garçons Freela</label>
                <input
                  id="qtdGarconsFreela"
                  name="qtdGarconsFreela"
                  type="number"
                  step="1"
                  required
                  value={data.qtdGarconsFreela}
                  className="w-100"
                  onChange={(e) =>
                    setData({ ...data, qtdGarconsFreela: e.target.value })
                  }
                ></input>
              </Col>
              <Col xs={12} md={4}>
                <label htmlFor="qtdGarconsFreela">
                  Qtde. Produtos Vendidos
                </label>
                <input
                  id="qtdeProdutosVendidos"
                  name="qtdeProdutosVendidos"
                  type="number"
                  step="1"
                  required
                  value={data.qtdeProdutosVendidos}
                  className="w-100"
                  onChange={(e) =>
                    setData({ ...data, qtdeProdutosVendidos: e.target.value })
                  }
                ></input>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <label htmlFor="tamanho_pdv">Tamanho PDV (m²)</label>
                <input
                  id="tamanho_pdv"
                  name="tamanho_pdv"
                  type="number"
                  step="1"
                  required
                  value={data.tamanho_pdv}
                  className="w-100"
                  onChange={(e) =>
                    setData({ ...data, tamanho_pdv: e.target.value })
                  }
                ></input>
              </Col>
              <Col xs={12} md={6}>
                <label htmlFor="capacidadeClientes">
                  Capacidade Clientes / Dia
                </label>
                <input
                  id="capacidadeClientes"
                  name="capacidadeClientes"
                  type="number"
                  step="1"
                  required
                  value={data.capacidadeClientes}
                  className="w-100"
                  onChange={(e) =>
                    setData({ ...data, capacidadeClientes: e.target.value })
                  }
                ></input>
              </Col>
            </Row>
            <Row className="d-flex align-items-center">
              <Col className="d-flex flex-column" xs={12} sm={8} md={6}>
                <label htmlFor="produto">
                  Produtos vendidos da concorrencia
                </label>
                <input
                  id="produto"
                  className="w-100"
                  placeholder="Produto"
                  value={produto.nome}
                  onChange={(e) => setProduto({ nome: e.target.value })}
                ></input>
              </Col>
              <Col className="fl-d" xs={12} sm={4} md={4}>
                <label htmlFor="qtde">Unidades</label>
                <input
                  id="qtde"
                  className="w-100"
                  placeholder="Qtde"
                  type="number"
                  step="1"
                  value={produto.qtde}
                  onChange={(e) => setProduto({ qtde: e.target.value })}
                ></input>
              </Col>

              <Col
                className="d-flex justify-content-center text-center align-items-center"
                xs={12}
                sm={3}
                md={2}
              >
                <button onClick={addProduto} className="btn-adicionar">
                  <i className="fas fa-plus"></i>
                </button>
              </Col>
              <Col xs={12} sm={12}>
                <ul className="lista" id="produtos">
                  {produtosMaisVendidos.map((produto, index) => (
                    <li className="lista-item" key={index}>
                      {` ${produto.nome} - ${Number(
                        produto.qtde
                      ).toLocaleString('pt-BR')}`}
                      <button onClick={delProduto} className="btn-limpo ml-3">
                        <i id={index} className="fas fa-trash"></i>
                      </button>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {editando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
              disabled
            >
              SALVANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
            >
              SALVAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
