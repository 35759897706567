/*  eslint-disable-next-line */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useFetch } from '../../../../hooks/useFetch';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import api from '../../../../services/API';

export const Filtros = ({ setFiltro }) => {
  const { regiaoPermissao, programaPermissao, cpf } = useSelector(
    (state) => state.usuario
  );
  const [marcas, setMarcas] = useState('')
  const [marcasPermissao, setMarcasPermissao] = useState('')
  const [rtm, setRtm] = useState('')
  const [rtmPermissao, setRtmPermissao] = useState('')

  const [programas, fetchProgramas] = useFetch(
    '/api/programas?id=3&id=2',
    'get'
  );
  const [dataRegioes, fetchRegioes] = useFetch('/api/regioes', 'get');

  const buscarMarcas = async () => {
    const resultado = await api.get(`/api/marcas`);

    if (resultado) {
      setMarcas(resultado.data.marcas)
    }
  }

  const buscarUsuario = async () => {
    const response = await api.get(`/api/usuario?cpf=${cpf}`)

    if (response) {
      const { marcas, rtm } = response.data.usuario;
      setMarcasPermissao(marcas)
      setRtmPermissao(rtm)
    }
  }

  const rtmSelect = [
    { id: 1, valor: 'dcc', nome: 'DCC' },
    { id: 2, valor: 'dhnk', nome: 'DHNK' },
  ]

  useEffect(() => {
    buscarUsuario()
    buscarMarcas()
    fetchProgramas({});
    fetchRegioes({});
  }, []);

  return (
    <>
      <Col xs={12} sm={6} md={4} lg={2} className="d-flex flex-column">
        <label htmlFor="regiaoSelecionada">Região</label>
        <select
          className="input-select mb-3"
          name="regiaoSelecionada"
          id="regiaoSelecionada"
          onChange={(e) => setFiltro({ regiao: e.target.value })}
          defaultValue=""
          placeholder=""
          disabled={regiaoPermissao}
        >
          <option value="">Todas</option>
          {!isEmpty(dataRegioes.data)
            ? dataRegioes.data.regioes.map((regiao) => {
                let isSelected = regiaoPermissao === regiao.nome;
                return (
                  <option
                    selected={isSelected}
                    value={regiao.nome}
                    key={regiao.id}
                  >
                    {regiao.nome}
                  </option>
                );
              })
            : ''}
        </select>
      </Col>
      <Col xs={12} sm={6} md={4} lg={2} className="d-flex flex-column">
        <label htmlFor="programaSelecionada">Programa</label>
        <select
          className="input-select mb-3"
          name="programaSelecionada"
          id="programaSelecionada"
          onChange={(e) => setFiltro({ programas: e.target.value })}
          disabled={programaPermissao}
          defaultValue=""
          placeholder=""
        >
          <option value="">Todas</option>
          {!isEmpty(programas.data)
            ? programas.data.programas.map((programa) => {
                let isSelected =
                  Number(programaPermissao) === Number(programa.id);
                return (
                  <option
                    value={programa.id}
                    key={programa.id}
                    selected={isSelected}
                  >
                    {programa.nome}
                  </option>
                );
              })
            : ''}{' '}
        </select>
      </Col>
      { window.location.pathname === '/painel/garcons' &&
        (
          <>
            <Col
              xs={12}
              sm={6}
              md={4}
              lg={2}
              className="d-flex flex-column"
            >
              <label htmlFor="programaSelecionada">Marca</label>
              <select
                className="input-select mb-3"
                name="programaSelecionada"
                id="programaSelecionada"
                onChange={(e) =>
                  setFiltro({ marcas: e.target.value })
                }
                disabled={(marcasPermissao === '' || String(marcasPermissao).includes('[')) ? '' : marcasPermissao}
                defaultValue=""
                placeholder=""
              >
                <option value="">Todas</option>
                { marcas ?
                  marcas.map(marca => {
                    let isSelected = (marcasPermissao === '' ||  String(marcasPermissao).includes('[')) ?
                          undefined
                          :
                          String(marcasPermissao) === String(marca.marca).toLowerCase();
                    return (
                      <option
                        value={marca.marca}
                        key={marca.marca}
                        selected={isSelected}
                      >
                        {marca.marca}
                      </option>
                    )
                  })
                  :
                  ''
                }
              </select>
            </Col>
            <Col
              xs={12}
              sm={6}
              md={4}
              lg={2}
              className="d-flex flex-column"
            >
              <label htmlFor="rtmSelecionado">RTM</label>
              <select
                className="input-select mb-3"
                name="rtmSelecionado"
                id="rtmSelecionado"
                onChange={(e) =>
                  setFiltro({ rtm: e.target.value })
                }
                disabled={(rtmPermissao === '' || String(rtmPermissao).includes('[')) ? '' : rtmPermissao}
                defaultValue=""
                placeholder=""
              >
                <option value="">Todas</option>
                {
                  rtmSelect.map(rtm => {
                    let isSelected = (rtmPermissao === '' || String(rtmPermissao).includes('[')) ?
                        undefined
                        :
                        String(rtmPermissao) === String(rtm.nome).toLowerCase();
                    return (
                      <option
                        value={rtm.valor}
                        key={rtm.id}
                        selected={isSelected}
                      >
                        {rtm.nome}
                      </option>
                    )
                  })
                }
              </select>
            </Col>
          </>
        )
      }

      {/* <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
        <label htmlFor="segmentoSelecionada">Selecione um PDV</label>
        <select
          className="input-select mb-3"
          name="segmentoSelecionada"
          id="segmentoSelecionada"
          onChange={(e) => setFiltro({ pdvId: e.target.value })}
          defaultValue=""
          placeholder=""
        >
          <option value="">Todas</option>
          {!isEmpty(dataSegmentos.data)
            ? dataSegmentos.data.segmentos.map((pdv) => (
                <option value={pdv.id} key={pdv.nome}>
                  {pdv.nome}
                </option>
              ))
            : ''}
        </select>
      </Col> */}
    </>
  );
};
