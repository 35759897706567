/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Table } from 'react-bootstrap';

import { isEmpty } from 'lodash';

import { useFetch } from '../../../hooks/useFetch';
import { useObject } from '../../../hooks/useObject';
import {
  mascaraPontosMilhar,
  primeiraLetraMaiscula,
} from '../../../helpers/regioes';
import './styles.css';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

export default function Dashboard() {
  const { regiaoPermissao, programaPermissao } = useSelector(
    (state) => state.usuario
  );

  const [parceirosCadastrados, setParceirosCadastrados] = useState(0);
  const [revendasCadastradas, setRevendasCadastradas] = useState(0);
  const [parceirosPontuados, setParceirosPontuados] = useState(0);
  const [revendasPontuadas, setRevendasPontuadas] = useState(0);

  const [adegasCadastradas, setAdegasCadastradas] = useState(0);
  const [adegasPontuadas, setAdegasPontuadas] = useState(0);

  const [pontosEntreguesAdegas, setPontosEntreguesAdegas] = useState(0);
  const [pontosEntreguesGarcons, setPontosEntreguesGarcons] = useState(0);

  const [topParceirosPontuados, setTopParceirosPontuados] = useState([]);
  const [topRevendasPontuadas, setTopRevendasPontuadas] = useState([]);
  const [topAdegasPontuadas, setTopAdegasPontuadas] = useState([]);

  const [filtros, setFiltro] = useObject({
    programas: programaPermissao ?? '',
    regiao: regiaoPermissao ? regiaoPermissao : '',
    mes: '',
    ano: '',
  });

  const [dashboard, fetchDashboard] = useFetch(
    `/api/dashboard`,
    'post',
    filtros
  );
  const [programas, fetchProgramas] = useFetch('/api/programas', 'get');
  const [regioes, fetchRegioes] = useFetch('/api/regioes', 'get');

  const { data, isFetching } = dashboard;

  const buscarDados = async () => {
    if (!isEmpty(data)) {
      setParceirosCadastrados(data.parceirosCadastrados);
      setRevendasCadastradas(data.revendasCadastradas);
      setParceirosPontuados(data.parceirosPontuados);
      setRevendasPontuadas(data.revendasPontuadas);
      setTopParceirosPontuados(data.topParceirosPontuados);
      setTopRevendasPontuadas(data.topRevendasPontuadas);
      setTopAdegasPontuadas(data.topAdegasPontuadas);
      setAdegasCadastradas(data.adegasCadastradas);
      setAdegasPontuadas(data.adegasPontuadas);
      setPontosEntreguesAdegas(data.pontosEntreguesAdegas);
      setPontosEntreguesGarcons(data.pontosEntreguesGarcons);
    }
  };

  useEffect(() => {
    fetchDashboard({});
    fetchProgramas({});
    fetchRegioes({});
  }, []);

  useEffect(() => {
    buscarDados();
  }, [isFetching]);

  const handleClick = (e) => {
    e.preventDefault();
    if ((filtros.mes && !filtros.ano) || (!filtros.mes && filtros.ano)) {
      toast.error('Informe mês e ano', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    fetchDashboard({});
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Dashboard</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={6} lg={3} className="d-flex flex-column">
          <label htmlFor="regiao">Selecione uma Região</label>
          <select
            className="input-select mb-3"
            name="regiao"
            id="regiao"
            onChange={(e) => setFiltro({ ...filtros, regiao: e.target.value })}
            disabled={regiaoPermissao}
            placeholder=""
          >
            <option value="">Todas</option>
            {!isEmpty(regioes.data)
              ? regioes.data.regioes.map((regiao) => {
                  let isSelected = regiaoPermissao === regiao.nome;
                  return (
                    <option
                      selected={isSelected}
                      value={regiao.nome}
                      key={regiao.id}
                    >
                      {regiao.nome}
                    </option>
                  );
                })
              : ''}
          </select>
        </Col>
        <Col xs={12} sm={6} md={6} lg={3} className="d-flex flex-column">
          <label htmlFor="programaSelecionada">Programa</label>
          <select
            className="input-select mb-3"
            name="programaSelecionada"
            id="programaSelecionada"
            onChange={(e) =>
              setFiltro({ ...filtros, programas: e.target.value })
            }
            disabled={programaPermissao}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {!isEmpty(programas.data)
              ? programas.data.programas.map((segmento) => {
                  let isSelected =
                    Number(programaPermissao) === Number(segmento.id);

                  return (
                    <option
                      value={segmento.id}
                      key={segmento.id}
                      selected={isSelected}
                    >
                      {segmento.nome}
                    </option>
                  );
                })
              : ''}
          </select>
        </Col>

        {/* <Col xs={12} sm={6} md={6} lg={3} className="d-flex flex-column">
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} className="d-flex flex-column">
              <label htmlFor="mes">Mês</label>
              <select
                className="input-select mb-3"
                name="mes"
                id="mes"
                onChange={(e) => setFiltro({ ...filtros, mes: e.target.value })}
                defaultValue=""
                placeholder=""
              >
                <option value="">Todos</option>
                {meses.map((mes) => (
                  <option value={mes.id} key={mes.id}>
                    {mes.nome}
                  </option>
                ))}
              </select>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} className="d-flex flex-column">
              <label htmlFor="ano">Ano</label>
              <select
                className="input-select mb-3"
                name="ano"
                id="ano"
                onChange={(e) => setFiltro({ ...filtros, ano: e.target.value })}
                defaultValue=""
                placeholder=""
              >
                <option value="">Todos</option>
                {anos.map((ano) => (
                  <option value={ano} key={ano}>
                    {ano}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
        </Col> */}
        <Col xs={12} sm={6} md={6} lg={3} className="d-flex flex-column">
          <Row>
            {/* <Col xs={9} sm={9} md={9} lg={9} className="d-flex flex-column">
              <label htmlFor="pdvSelecionado">Selecione um PDV / Adega</label>
              <select
                className="input-select mb-3"
                name="pdvSelecionado"
                id="pdvSelecionado"
                onChange={(e) =>
                  setFiltro({ ...filtros, pdvId: e.target.value })
                }
                defaultValue=""
                placeholder=""
              >
                <option value="">Todas</option>
                {!isEmpty(pdvs.data.adegas)
                  ? sortBy(pdvs.data.adegas, ['nomeFantasia']).map((adega) => (
                      <option value={adega.id} key={adega.id}>
                        {adega.nomeFantasia}
                      </option>
                    ))
                  : ''}
              </select>
            </Col> */}

            <Col
              xs={2}
              md={1}
              sm={1}
              className="d-flex flex-column justify-content-center align-items-center d-sm-block searchBtn"
            >
              <button className="btn-busca" type="button" onClick={handleClick}>
                {isFetching ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  <i className="fas fa-search text-white"></i>
                )}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash verde-claro">
            <Col xs={2} md={2}>
              <i className="fas fa-user-alt text-white icon-blocks-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <Row>
                <span className="card-dash-subtitulo">
                  {isFetching ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    parceirosCadastrados
                  )}
                </span>
              </Row>
              <Row>
                <span className="card-dash-titulo">Garçons Cadastrados</span>
              </Row>
            </Col>
          </div>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash verde-claro">
            <Col xs={2} md={2}>
              <i className="fas fa-user-check text-white icon-blocks-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <Row>
                <span className="card-dash-subtitulo">
                  {isFetching ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    parceirosPontuados
                  )}
                </span>
              </Row>
              <Row>
                <span className="card-dash-titulo">Garçons Pontuados</span>
              </Row>
            </Col>
          </div>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash azul-claro">
            <Col xs={2} md={2}>
              <i className="fas fa-store text-white icon-blocks-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <Row>
                <span className="card-dash-subtitulo">
                  {isFetching ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    adegasCadastradas
                  )}
                </span>
              </Row>
              <Row>
                <span className="card-dash-titulo">Adegas Cadastradas</span>
              </Row>
            </Col>
          </div>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash azul-claro">
            <Col xs={2} md={2}>
              <i className="fas fa-plus-circle text-white icon-blocks-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <Row>
                <span className="card-dash-subtitulo">
                  {isFetching ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    adegasPontuadas
                  )}
                </span>
              </Row>
              <Row>
                <span className="card-dash-titulo">Adegas Pontuadas</span>
              </Row>
            </Col>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash laranja-claro">
            <Col xs={2} md={2}>
              <i className="fas fa-store text-white icon-blocks-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <Row>
                <span className="card-dash-subtitulo">
                  {isFetching ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    revendasCadastradas
                  )}
                </span>
              </Row>
              <Row>
                <span className="card-dash-titulo">
                  Bares Cadastrados: <br /> EE, BP e R+
                </span>
              </Row>
            </Col>
          </div>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash laranja-claro">
            <Col xs={2} md={2}>
              <i className="fas fa-plus-circle text-white icon-blocks-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <Row>
                <span className="card-dash-subtitulo">
                  {isFetching ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    revendasPontuadas
                  )}
                </span>
              </Row>
              <Row>
                <span className="card-dash-titulo">
                  Bares Pontuados: <br /> EE, BP e R+
                </span>
              </Row>
            </Col>
          </div>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash verde-grama">
            <Col xs={2} md={2}>
              <i className="fas fa-store text-white icon-blocks-dash icon-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <Row>
                <span className="card-dash-subtitulo">
                  {isFetching ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    mascaraPontosMilhar(pontosEntreguesGarcons)
                  )}
                </span>
              </Row>
              <Row>
                <span className="card-dash-titulo">
                  Pontos Entregues: <br />
                  EE, BP e R+
                </span>
              </Row>
            </Col>
          </div>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash verde-grama">
            <Col xs={2} md={2}>
              <i className="fas fa-plus-circle text-white icon-blocks-dash"></i>
            </Col>
            <Col xs={10} md={10} className="pl-5">
              <Row>
                <span className="card-dash-subtitulo">
                  {isFetching ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    mascaraPontosMilhar(pontosEntreguesAdegas)
                  )}
                </span>
              </Row>
              <Row>
                <span className="card-dash-titulo">
                  Pontos Entregues: <br /> Adegas{' '}
                </span>
              </Row>
            </Col>
          </div>
        </Col>
      </Row>
      {/* {permissao !== 0 && permissao !== 4 && (
        <>
          <Row>
            <h5 className="pl-3 mb-0 p-3">
              <strong>Ver e Agir</strong>
            </h5>
          </Row>

          <Row>
            <Col className="mb-3" xs={12} md={6} lg={3}>
              <div className="card-dash" style={{ backgroundColor: '#D9AF7B' }}>
                <Col xs={2} md={2}>
                  <i className="fas fa-store-alt text-white icon-blocks-dash"></i>
                </Col>
                <Col xs={10} md={10} className="pl-5">
                  <Row>
                    <span className="card-dash-subtitulo">
                      {isFetching ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        pdvsGarcons
                      )}
                    </span>
                  </Row>
                  <Row>
                    <span className="card-dash-titulo">Estação + Boteco</span>
                  </Row>
                </Col>
              </div>
            </Col>
            <Col className="mb-3" xs={12} md={6} lg={3}>
              <div className="card-dash" style={{ backgroundColor: '#D9AF7B' }}>
                <Col xs={2} md={2}>
                  <i className="fas fa-plus-circle text-white icon-blocks-dash"></i>
                </Col>
                <Col xs={10} md={10} className="pl-5">
                  <Row>
                    <span className="card-dash-subtitulo">
                      {isFetching ? (
                        <i className="fas fa-check-circle fa-spin"></i>
                      ) : (
                        pdvsTampa
                      )}
                    </span>
                  </Row>
                  <Row>
                    <span className="card-dash-titulo">PDVs com tampas</span>
                  </Row>
                </Col>
              </div>
            </Col>
            <Col className="mb-3" xs={12} md={6} lg={3}>
              <div className="card-dash" style={{ backgroundColor: '#D9AF7B' }}>
                <Col xs={2} md={2}>
                  <i className="fas fa-store-alt text-white icon-blocks-dash"></i>
                </Col>
                <Col xs={10} md={10} className="pl-5">
                  <Row>
                    <span className="card-dash-subtitulo">
                      {isFetching ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        pdvsSemTampa
                      )}
                    </span>
                  </Row>
                  <Row>
                    <span className="card-dash-titulo">PDVs sem tampas</span>
                  </Row>
                </Col>
              </div>
            </Col>
            <Col className="mb-3" xs={12} md={6} lg={3}>
              <div className="card-dash" style={{ backgroundColor: '#D9AF7B' }}>
                <Col xs={2} md={2}>
                  <i className="fas fa-times-circle text-white icon-blocks-dash"></i>
                </Col>
                <Col xs={10} md={10} className="pl-5">
                  <Row>
                    <span className="card-dash-subtitulo">
                      {isFetching ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        pdvsSemGarcom
                      )}
                    </span>
                  </Row>
                  <Row>
                    <span className="card-dash-titulo">PDVs sem Garçom </span>
                  </Row>
                </Col>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mb-3" xs={12} md={6} lg={3}>
              <div className="card-dash" style={{ backgroundColor: '#4B602A' }}>
                <Col xs={2} md={2}>
                  <i className="fas fa-plus-circle text-white icon-blocks-dash"></i>
                </Col>
                <Col xs={10} md={10} className="pl-5">
                  <Row>
                    <span className="card-dash-subtitulo">
                      {isFetching ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        garcomComTampas
                      )}
                    </span>
                  </Row>
                  <Row>
                    <span className="card-dash-titulo">Garçons com tampas</span>
                  </Row>
                </Col>
              </div>
            </Col>
            <Col className="mb-3" xs={12} md={6} lg={3}>
              <div className="card-dash" style={{ backgroundColor: '#4B602A' }}>
                <Col xs={2} md={2}>
                  <i className="fas fa-beer text-white icon-blocks-dash"></i>
                </Col>
                <Col xs={10} md={10} className="pl-5">
                  <Row>
                    <span className="card-dash-subtitulo">
                      {isFetching ? (
                        <i className="fas fa-check-circle fa-spin"></i>
                      ) : (
                        garcomSemTampas
                      )}
                    </span>
                  </Row>
                  <Row>
                    <span className="card-dash-titulo">Garçons sem tampas</span>
                  </Row>
                </Col>
              </div>
            </Col>
            <Col className="mb-3" xs={12} md={6} lg={3}>
              <div className="card-dash" style={{ backgroundColor: '#4B602A' }}>
                <Col xs={2} md={2}>
                  <i className="fas fa-check-circle text-white icon-blocks-dash"></i>
                </Col>
                <Col xs={10} md={10} className="pl-5">
                  <Row>
                    <span className="card-dash-subtitulo">
                      {isFetching ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        mascaraPontosMilhar(tampasEnviadas)
                      )}
                    </span>
                  </Row>
                  <Row>
                    <span className="card-dash-titulo">Tampas Enviadas</span>
                  </Row>
                </Col>
              </div>
            </Col>
            <Col className="mb-3" xs={12} md={6} lg={3}>
              <div className="card-dash" style={{ backgroundColor: '#4B602A' }}>
                <Col xs={2} md={2}>
                  <i className="fas fa-plus-circle text-white icon-blocks-dash"></i>
                </Col>
                <Col xs={10} md={10} className="pl-5">
                  <Row>
                    <span className="card-dash-subtitulo">
                      {isFetching ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        mascaraPontosMilhar(totalDePontos)
                      )}
                    </span>
                  </Row>
                  <Row>
                    <span className="card-dash-titulo">Total de Pontos </span>
                  </Row>
                </Col>
              </div>
            </Col>
          </Row>
        </>
      )} */}
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <div className="dash-table-titulo">
            <h5 className="pl-3 mb-0 p-3">Top 10 Garçons</h5>
          </div>
          <Table className="dash-table" responsive variant="dark">
            <thead className="dash-table-header">
              <tr className="tr-gray">
                <th>Participante</th>
                <th>PDV</th>
                <th>Total Pontos</th>
              </tr>
            </thead>
            <tbody className="dash-table-body">
              {isFetching && (
                <tr>
                  <td colSpan={4} className="text-center">
                    Carregando
                    <i className="fas fa-circle-notch fa-spin ml-3"></i>
                  </td>
                </tr>
              )}
              {topParceirosPontuados.map((parceiro) => (
                <tr key={parceiro.nome}>
                  <td>{primeiraLetraMaiscula(parceiro.nome)}</td>
                  <td>{primeiraLetraMaiscula(parceiro.nomeFantasia)}</td>
                  <td>{mascaraPontosMilhar(parceiro.totalPontos)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col xs={12} md={6}>
          <div className="dash-table-titulo">
            <h5 className="pl-3 mb-0 p-3">Top 10 Bares</h5>
          </div>
          <Table className="dash-table" responsive variant="dark">
            <thead className="dash-table-header">
              <tr className="tr-gray">
                <th>PDV</th>
                <th>Cidade</th>
                <th>Região</th>
                <th>Total Pontos</th>
              </tr>
            </thead>
            <tbody className="dash-table-body">
              {isFetching && (
                <tr>
                  <td colSpan={4} className="text-center">
                    Carregando
                    <i className="fas fa-circle-notch fa-spin ml-3"></i>
                  </td>
                </tr>
              )}
              {topRevendasPontuadas.map((revenda) => (
                <tr key={revenda.nomeFantasia}>
                  <td>{primeiraLetraMaiscula(revenda.nomeFantasia)}</td>
                  <td>{primeiraLetraMaiscula(revenda.endCidade)}</td>
                  <td>{revenda.regiao}</td>
                  <td>{mascaraPontosMilhar(revenda.totalPontos)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <div className="dash-table-titulo">
            <h5 className="pl-3 mb-0 p-3">Top 10 Adegas</h5>
          </div>
          <Table className="dash-table" responsive variant="dark">
            <thead className="dash-table-header">
              <tr className="tr-gray">
                <th>Adega</th>
                <th>Cidade</th>
                <th>Região</th>
                <th>Total Pontos</th>
              </tr>
            </thead>
            <tbody className="dash-table-body">
              {isFetching && (
                <tr>
                  <td colSpan={4} className="text-center">
                    Carregando
                    <i className="fas fa-circle-notch fa-spin ml-3 icone"></i>
                  </td>
                </tr>
              )}
              {topAdegasPontuadas.map((adega) => (
                <tr key={adega.nomeFantasia}>
                  <td>{primeiraLetraMaiscula(adega.nomeFantasia)}</td>
                  <td>{primeiraLetraMaiscula(adega.endCidade)}</td>
                  <td>{adega.regiao}</td>
                  <td>{mascaraPontosMilhar(adega.totalPontos)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
