import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Regulamento from '../Regulamento/index';
import './styles.css';
import { aceitarRegulamento as aceitarRegulamentoPaceiro } from '../../../store/modules/parceiro/actions';
import { aceitarRegulamento as aceitarRegulamentoProprietario } from '../../../store/modules/proprietario/actions';
import {
  desautenticarParceiro,
  desautenticarProprietario,
} from '../../../store/modules/autenticacao/actions';
import ModalVideoBemVindo from '../ModalVideoBemVindo';
import api from '../../../services/API';

export default function ModalRegulamento(props) {
  const parceiro = useSelector((state) => {
    return state.parceiro;
  });
  const proprietario = useSelector((state) => {
    return state.proprietario;
  });

  const dispatch = useDispatch();

  const [aceitou, setAceitou] = useState(false);
  const [showModalVideo, setShowModalVideo] = useState(false);
  const tipoUsuario = useSelector((state) => state.autenticacao.tipoUsuario);
  async function handlePontuarAceite() {
    await api.post(`/api/pontuacoes/pontuar-aceite`);
  }

  const clicouAceitar = (e) => {
    e.preventDefault();
    if (tipoUsuario === 'parceiro') {
      setShowModalVideo(true);
      handlePontuarAceite();
      dispatch(aceitarRegulamentoPaceiro());
    } else if (tipoUsuario === 'proprietario') {
      setShowModalVideo(true);
      handlePontuarAceite();
      dispatch(aceitarRegulamentoProprietario());
    }
  };

  const clicouCancelar = (e) => {
    e.preventDefault();
    localStorage.clear();
    if (tipoUsuario === 'parceiro') {
      dispatch(desautenticarParceiro());
    } else if (tipoUsuario === 'proprietario') {
      dispatch(desautenticarProprietario());
    }
  };

  const showModal = () => {
    if (tipoUsuario === 'parceiro') {
      return !parceiro.aceitouRegulamento;
    } else if (tipoUsuario === 'proprietario') {
      return !proprietario.aceitouRegulamento;
    }
  };

  return (
    <>
      <Modal
        show={showModal()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        keyboard={false}
        backdrop="static"
      >
        <Modal.Body>
          <Regulamento className="mt-0" />
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100">
            <div className="d-flex justify-content-center align-items-center w-100 mb-5">
              <input
                type="checkbox"
                name="check"
                id="checkRegulamento"
                className="m-1"
                onChange={(e) => setAceitou(e.target.checked)}
              />
              <label htmlFor="checkRegulamento" className="m-0 f-black f-14">
                Concordo com os termos deste Regulamento.
              </label>
            </div>
            <div className="d-flex justify-content-center align-items-center w-100">
              {aceitou ? (
                <button
                  id="aceitarRegulamento"
                  type="button"
                  className="btn btn-success m-1"
                  onClick={clicouAceitar}
                >
                  ACEITAR
                </button>
              ) : (
                <button
                  id="aceitarRegulamento"
                  type="button"
                  className="btn btn-success m-1"
                  disabled
                >
                  ACEITAR
                </button>
              )}

              <button
                id="recusarRegulamento"
                type="button"
                className="btn btn-danger m-1"
                onClick={clicouCancelar}
              >
                CANCELAR
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <ModalVideoBemVindo
        showModalVideo={showModalVideo}
        setShowModalVideo={setShowModalVideo}
      />
    </>
  );
}
