import React from 'react';
import { Container } from 'react-bootstrap';

export default function Regulamento() {
  return (
    <Container className="mt-5 mb-5">
      <div>
        <h5>REGULAMENTO Cerveja Premiada</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div className="text-justify">
        <div>
          <p>
            O “Cerveja Premiada” é um Programa de Incentivo para participantes
            cadastrados, no qual cada produto participante do Cerveja Premiada
            vendido tem um valor de pontos específico, que poderá ser alterado a
            qualquer momento.
          </p>
          <p>
            O participante cadastrado acumulará pontos e os trocará por prêmios,
            de acordo com regras de participação e pontuação descritas neste
            documento.
          </p>
          <p>
            O presente Programa “Cerveja Premiada” é desenvolvido e realizado
            pela Smart Tecnologia em Vendas S/A, inscrita sob nº
            50.221.019/0001-36, por si e suas filiais, e/ou CERVEJARIAS KAISER
            BRASIL S.A. inscrita sob nº 19.900.000/0001-76 (“Grupo HEINEKEN”
            e/ou “Promotora”).
          </p>
          <p>
            Todas as regras de pontuação, resgates e validade, poderão ser
            atualizadas sem prévio aviso, sendo incluídas neste documento e
            comunicado aos participantes de forma clara. A pré-seleção das
            revendas participantes é de responsabilidade da Cerveja Premiada.
          </p>
          <p>
            O participante, ao se cadastrar no Programa, aceita e concorda com
            as regras abaixo.
          </p>
        </div>
        <br />
        <div>
          <ol>
            <li>
              <span>REGULAMENTO</span>
              <ol>
                <li>
                  <span>
                    O presente Programa será realizado conforme regras descritas
                    neste Regulamento.
                  </span>
                </li>
                <li>
                  <span>
                    O Programa <b>Cerveja Premiada</b> é promovido pela empresa
                    HNK BR INDUSTRIA DE BEBIDAS LTDA. (“Promotora”) e será
                    realizado pela empresa Smart Tecnologia em Vendas que é
                    detentora tanto da plataforma como também do domínio e site
                    www.cervejapremiada.com.br, destinado ao Programa{' '}
                    <b>Cerveja Premiada</b>.
                  </span>
                </li>
                <li>
                  <span>
                    O Programa <b>Cerveja Premiada</b> consiste na criação de
                    incentivo aos <b>participantes</b> usuários dos produtos da
                    linha de fabricação da Promotora, por meio da atribuição de
                    pontuação advinda da aquisição destes produtos, na rede de
                    revendas participantes, devidamente informada no site,
                    possibilitando que o usuário, a partir de certa somatória de
                    pontos acumulados, tenha acesso aos prêmios indicados,
                    observadas as e regras descritas neste Regulamento.
                  </span>
                </li>
                <li>
                  <span>
                    O Programa <b>Cerveja Premiada</b> foi concebido para ser
                    direcionado exclusivamente aos usuários que manifestarem
                    expresso interesse e se cadastrarem como membros nos pdvs
                    participantes.
                  </span>
                </li>
                <li>
                  <span>Significado de pdv e/ou pdvs: Pontos de Vendas.</span>
                </li>
                <li>
                  <span>
                    Não é permitida a participação de colaboradores no programa{' '}
                    <b>Cerveja Premiada</b>.
                  </span>
                </li>
                <li>
                  <span>
                    Poderão ser cadastrados como membros do{' '}
                    <b>Cerveja Premiada</b>, toda pessoa física interessada, que
                    seja maior de 18 (dezoito) anos e que atua na condição de
                    pdv, ou seja, atua na condição de lojista, comerciante, bar,
                    restaurante, enfim, qualquer atividade relacionada ao
                    comércio de produtos da linha de fabricação e/ou portfólio
                    da Promotora.
                  </span>
                </li>
                <li>
                  <span>
                    Portanto, o programa <b>Cerveja Premiada</b> não se aplica
                    para o consumidor final.
                  </span>
                </li>
                <li>
                  <span>
                    A solicitação de ingresso no <b>Cerveja Premiada</b>, deverá
                    ser efetivada pelo interessado, diretamente no pdv
                    participante, a quem caberá receber a solicitação, através
                    do Formulário específico, verificar a viabilidade da
                    inscrição e determinar a efetivação do cadastro para a sua
                    admissão como membro do <b>Cerveja Premiada</b>.
                  </span>
                </li>
                <li>
                  <span>
                    A senha utilizada para login junto ao CPF do membro do{' '}
                    <b>Cerveja Premiada</b> será a senha criada pelo mesmo na
                    hora do cadastro, podendo ser alterada a qualquer momento
                    através de acesso ao site{' '}
                    <a href="http://www.cervejapremiada.com.br">
                      www.cervejapremiada.com.br,
                    </a>
                    , por meio do menu “MINHA CONTA”.
                  </span>
                </li>
                <li>
                  <span>
                    O membro do <b>Cerveja Premiada</b>, está ciente de que
                    jamais deverá fornecer a sua senha de acesso ao site a
                    terceiros, salientando que o manuseio e guarda desta senha é
                    de sua total responsabilidade.
                  </span>
                </li>
                <li>
                  <span>
                    O acesso a área “Minha Conta”, poderá ser realizado no
                    painel ou pelo link:{' '}
                    <a href="http://www.cervejapremiada.com.br/minha-conta">
                      www.cervejapremiada.com.br/minha-conta
                    </a>{' '}
                    e, neste ambiente também se poderá entre outras atividades,
                    efetivar a alteração da senha pessoal provisória inicial. Em
                    caso de dúvidas ou advindo qualquer nível de necessidade ou
                    dificuldade, o membro do <b>Cerveja Premiada</b> poderá
                    entrar em contato com pelo telefone (11) 91100-3423 ou pelo
                    email:{' '}
                    <a href="mailto:contato@cervejapremiada.com.br">
                      contato@cervejapremiada.com.br
                    </a>{' '}
                    .
                  </span>
                </li>
                <li>
                  <span>
                    Os participantes cadastrados do <b>Cerveja Premiada</b>,
                    terão direito a pontuação advinda dos produtos da linha de
                    fabricação da Promotora, adquiridos nas Revendas
                    participantes e estabelecimentos varejistas considerando-se
                    as quantidades e especificidades destes produtos para fins
                    de estabelecimento da pontuação inerente.
                  </span>
                </li>
                <li>
                  <span>
                    Os usuários cadastrados, ora denominados e identificados
                    como “Membros do <b>Cerveja Premiada</b>, somente serão
                    beneficiados, pelo programa desde que atendidas todas as
                    condições descritas no presente Regulamento. É faculdade da
                    Promotora efetivar a exclusão ou inclusão de Revendas que
                    participarão do Programa criado, todavia, tais fatos não
                    interferirão nos direitos adquiridos pelos membros do{' '}
                    <b>Cerveja Premiada</b>, decorrentes das pontuações até
                    então existentes, mesmo que a revenda tenha sido excluída
                    por qualquer motivo, inclusive inadimplemento.
                  </span>
                </li>
                <li>
                  <span>
                    A participação no PROGRAMA não caracteriza, sob nenhum
                    aspecto e/ou hipótese, vínculo empregatício, vínculo
                    associativo ou a constituição de qualquer modalidade de
                    sociedade, parceria, relação comercial, de representação ou
                    qualquer outro vínculo, à que título for, entre qualquer
                    empresa relacionada ao programa e os usuários, se não o
                    expressamente descrito no REGULAMENTO.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>REGRAS DE PONTUAÇÃO</span>
              <ol>
                <li>
                  <span>
                    Após a efetivação da venda ao usuário membro do{' '}
                    <b>Cerveja Premiada</b>, caberá ao mesmo, efetuar a captura
                    da imagem digital da nota/cupom fiscal e a transmissão
                    eletrônica ao endereço do site{' '}
                    <a href="http://www.cervejapremiada.com.br">
                      www.cervejapremiada.com.br
                    </a>
                    , para que a pontuação inerente, seja computada em favor do
                    membro participante e confirmada.
                  </span>
                </li>
                <li>
                  <span>
                    A imagem fotográfica da Nota Fiscal/Cupom Fiscal deve ser
                    capturada e transmitida de forma nítida, legível e
                    inequívoca, onde se possa identificar as suas principais
                    características como a quantidade, descrição de produtos,
                    número da nota/cupom fiscal, recibo e data da compra, pois
                    só serão aceitos comprovantes válidos e legíveis oriundos
                    das revendas participantes do Programa.
                  </span>
                </li>
                <li>
                  <span>
                    Os pontos obtidos pelos membros do Cerveja Premiada são
                    cumuláveis e possuem prazo 8 (oito) meses, a partir da data
                    de cadastro, sendo obrigatório que a conta de pontuação do
                    membro participante, permaneça ativa no período de 6 meses.
                  </span>
                </li>
                <li>
                  <span>
                    Caso decorridos os prazos acima indicados, não tenha o
                    membro do <b>Cerveja Premiada</b> atingido a quantidade
                    suficiente de pontos para troca de prêmios, os pontos serão
                    automaticamente expirados e declarados caducos, sem qualquer
                    aviso prévio. O extrato de pontos poderá ser consultado no
                    site: www.cervejapremiada.com.br, no menu PONTUAÇÕES,
                    juntamente com login (login: número do CPF ou CNPJ) e a
                    senha pessoal, obedecendo o sistema de pontuação, às
                    seguintes diretrizes.
                  </span>
                </li>

                <li>
                  As pontuações são validadas e atribuídas em relação direta ao
                  volume de compras de produtos da Promotora, realizadas pelo
                  membro do Cerveja Premiada na revenda participante;
                </li>
                <li>
                  A Smart se reserva ao direito da revisão dos pontos, até 90
                  (noventa) dias após a data de pontuação, para conferência e
                  eventual correção sempre lastreada neste Regulamento e nos
                  princípios da boa fé;
                </li>

                <li>
                  Os pontos obtidos no decorrer deste Programa poderão ser
                  utilizados de uma única vez ou de forma fracionada;
                </li>

                <li>
                  <span>
                    Caso a Smart tenha interesse na finalização do programa{' '}
                    <b>Cerveja Premiada</b> em período anterior a sua data
                    limite, esta garantirá a contemplação de todos os
                    participantes membros que eventualmente ainda possuam saldo
                    de pontos remanescentes, por meio de entrega de “cartão
                    presente” correlato ao objeto da pontuação.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>REGRAS DE RESGATES DE PRÊMIOS</span>
              <ol>
                <li>
                  <span>
                    Está disponível no site{' '}
                    <a href="http://www.cervejapremiada.com.br">
                      www.cervejapremiada.com.br
                    </a>{' '}
                    o CATÁLOGO DE PRÊMIOS contendo os produtos, que poderão ser
                    obtidos pelos seus membros a partir da utilização do seu
                    crédito de PONTOS válidos e vigentes, nos termos deste
                    REGULAMENTO e de outras regras disponibilizadas no site.
                  </span>
                </li>
                <li>
                  <span>
                    O <b>Cerveja Premiada</b> poderá alterar, substituir ou
                    cancelar os prêmios disponíveis, sempre preservando os
                    direitos adquiridos dos membros que já pontuaram..
                  </span>
                </li>
                <li>
                  <span>
                    Os resgates de prêmios deverão ser realizados através do
                    site{' '}
                    <a href="http://www.cervejapremiada.com.br">
                      www.cervejapremiada.com.br
                    </a>
                    , mediante a login e senha, devendo o membro indicar o
                    prêmio de sua preferência, dentre os descritos no catálogo
                    de prêmio, e desde que possua o saldo suficiente para o item
                    escolhido.
                  </span>
                </li>
                <li>
                  <span>
                    Em caso de falha sistêmica ou em casos que o produto seja
                    resgatado fora da regra de pontuação vigente, os resgates
                    serão automaticamente cancelados, gerando o dever de
                    devolução por parte do membro do <b>Cerveja Premiada</b>{' '}
                    caso este já tenha realizado o resgate.
                  </span>
                </li>
                <li>
                  <span>
                    Após o pedido de resgate de pontuação ser solicitado pelo
                    membro do <b>Cerveja Premiada</b>, este receberá um e-mail
                    confirmatório, podendo dirimir quaisquer dúvidas com a
                    equipe de apoio disponibilizada pela Smart.
                  </span>
                </li>
                <li>
                  <span>
                    O prêmio resgatado, será entregue para o membro do{' '}
                    <b>Cerveja Premiada</b> na revenda participante ou no
                    endereço cadastrado deste, na forma mencionada na
                    solicitação de resgate de pontuação, em até 20 dias úteis,
                    sendo a entrega feita mediante Formulário de Resgate válido
                    como recibo assinado, ou confirmação da transportadora.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>POLÍTICA DE PROTEÇÃO DE DADOS PESSOAIS</span>
              <ol>
                <li>
                  <span>
                    Os dados coletados serão utilizados com a finalidade de
                    administração e operação do PROGRAMA, incluindo o uso para
                    os fins de: (i) cadastro junto ao PROGRAMA e contato com o
                    Membro do <b>Cerveja Premiada</b>; (ii) acúmulo de PONTOS;
                    (iii) entrega de premiações; (iv) otimização do catálogo de
                    prêmios; (v) desenvolvimento de inteligência de mercado,
                    parâmetros e perfil de consumo, bem como outras atividades
                    associadas ao bom funcionamento e desenvolvimento do
                    PROGRAMA.
                  </span>
                </li>
                <li>
                  <span>
                    Os dados serão armazenados em bancos de dados exclusivos do
                    Programa, em ambiente seguro e controlado pela Smart
                  </span>
                </li>
                <li>
                  <span>
                    O Membro do <b>Cerveja Premiada</b> poderá a qualquer
                    momento corrigir dados incompletos, inexatos ou
                    desatualizados informados em cadastro, por meio do menu
                    “MINHA CONTA” após o Login.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>CONSIDERAÇÕES GERAIS</span>
              <ol>
                <li>
                  <span>
                    No primeiro acesso, o Membro do <b>Cerveja Premiada</b> deve
                    efetivar o seu ACEITE a este REGULAMENTO, formalizando,
                    portanto, a sua decisão de participar do PROGRAMA e declarar
                    ter amplo conhecimento e concordar integralmente, de maneira
                    plena e irrestrita, com todos os termos e condições do{' '}
                    <b>Cerveja Premiada</b>, estando apto a usufruir dos
                    benefícios do PROGRAMA que lhe forem aplicáveis.
                  </span>
                </li>
                <li>
                  <span>
                    O ACEITE efetivado pelo membro do <b>Cerveja Premiada</b>
                    também refere-se à autorização para a captura de seus dados
                    pessoais pela Smart para a finalidade específica de
                    participação neste Programa, bem como para o
                    compartilhamento de uso destes dados com a Promotora, as
                    empresas de seu grupo econômico e empresas indicadas pela
                    Promotora, que venham participar do programa, sempre para
                    fins relacionados aos interesses diretos dos titulares dos
                    dados.
                  </span>
                </li>
                <li>
                  <span>
                    Caso o ACEITE não seja efetivado, o acesso do{' '}
                    <b>Cerveja Premiada</b> estará inapto para uso.
                  </span>
                </li>
                <li>
                  <span>
                    A participação neste Programa implica na aceitação total de
                    todos os itens deste Regulamento, que poderá ser alterado
                    pela Realizadora tantas vezes quanto necessário, a seu
                    exclusivo critério, resguardando-se sempre os direitos
                    eventualmente adquiridos pelo membro do{' '}
                    <b>Cerveja Premiada</b>.
                  </span>
                </li>
                <li>
                  <span>
                    No decorrer deste Programa, a Smart ou a Promotora poderão
                    se comunicar com o público-alvo, de forma próxima e direta,
                    utilizando-se dos principais canais de comunicação.
                  </span>
                </li>
                <li>
                  <span>
                    Serão automaticamente desclassificados os participantes que
                    descumprirem os itens do presente regulamento, agirem de
                    má-fé ou que, de alguma forma, burlarem as regras e
                    condições deste Regulamento ou utilizarem mecanismos
                    fraudulentos ou ilícitos.
                  </span>
                </li>
                <li>
                  <span>
                    Se constatado fraude, má-fé ou correção de pontos nos casos
                    onde o resgate já tenha sido entregue, a Smart poderá
                    solicitar a devolução do benefício resgatado.
                  </span>
                </li>
                <li>
                  <span>
                    A Smart poderá solicitar fotos, vídeos ou outros meios de
                    comunicação para validar e confirmar a identidade do
                    participante e assim poder passar informações relacionadas
                    do <b>Cerveja Premiada</b>.
                  </span>
                </li>
                <li>
                  <span>
                    Se constatado fraude, má-fé ou correção de pontos nos casos
                    onde o resgate já tenha sido entregue, a Smart poderá
                    solicitar a devolução do benefício resgatado.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>UTILIZAÇÃO DO CARTÃO</span>
              <ol>
                <li>
                  <span>
                    No Primeiro resgate em dinheiro, você receberá seu cartão
                    Cerveja Premiada. Os próximos resgates em dinheiro, serão
                    creditados automaticamente neste mesmo cartão. Cada Garçom
                    terá apenas 1 (um) cartão, é valido somente 1 por CPF.
                  </span>
                </li>
                <li>
                  <span>
                    Utilize sempre seu CARTÃO MASTERCARD® Cerveja Premiada na
                    função CRÉDITO.
                  </span>
                </li>
                <li>
                  <span>
                    Com o cartão em mãos, você pode fazer o desbloqueio em{' '}
                    <a href="www.cervejapremiada.com.br/cartao ">
                      {' '}
                      www.cervejapremiada.com.br/cartao{' '}
                    </a>{' '}
                    ou pelo telefone 15 99731-7809 de segunda a sexta das 9h às
                    18h.
                  </span>
                </li>
                <li>
                  <span>
                    Para DESBLOQUEIO pelo site, o usuário precisa entrar no
                    sistema com seu CPF e senha.
                  </span>
                </li>
                <li>
                  <span>
                    O prazo da operadora para DESBLOQUEIO do seu cartão é de 24h
                    úteis após a solicitação.
                  </span>
                </li>
                <li>
                  <span>
                    Em caso de perda ou roubo do cartão, solicite o BLOQUEIO em{' '}
                    <a href="www.cervejapremiada.com.br/cartao">
                      www.cervejapremiada.com.br/cartao
                    </a>{' '}
                    ou pelo telefone 15 99731-7809 de segunda a sexta das 9h às
                    18h.
                  </span>
                </li>
                <li>
                  <span>
                    O prazo da operadora para BLOQUEIO do seu cartão é de 24h
                    úteis após a solicitação.
                  </span>
                </li>
                <li>
                  <span>
                    Para alterar a senha, o usuário será direcionado para o site
                    da operadora do cartão onde por segurança, será necessário
                    completar os dados pessoais e fazer um cadastro ao painel de
                    controle.
                  </span>
                </li>
                <li>
                  <span>
                    Sua senha é pessoal e intransferível. Você pode alterar a
                    senha impressa na carta que acompanha seu cartão a qualquer
                    momento, clique aqui.
                  </span>
                </li>
                <li>
                  <span>
                    O Programa Cerveja Premiada, não cobra taxas ou pontos para
                    as operações feitas dentro do painel do usuário no site da
                    operadora. As taxas são cobradas pela operadora do cartão e
                    constam neste regulamento.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>SERVIÇOS E TAXAS DA OPERADORA DO CARTÃO </span>
              <ol>
                <li>
                  <span>
                    O portador do cartão pode utilizar os seguintes serviços no
                    painel da operadora:
                    <br />
                    TRANSFERÊNCIA BANCÁRIA.
                    <br />
                    RECARGA DE CELULAR.
                    <br />
                    PAGAMENTO DE CONTA.
                    <br />
                    CONSULTA DE SALDO E EXTRADO DO CARTÃO.
                    <br />
                    ALTERAÇÃO DE SENHA.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>TRANSFERÊNCIA BANCÁRIA</span>
              <ol>
                <li>
                  <span>O Usuário deverá cadastrar uma conta em seu nome.</span>
                </li>
                <li>
                  <span>
                    Não é permitido transferência para conta de terceiros.
                  </span>
                </li>
                <li>
                  <span>
                    Somente após a confirmação do código SMS e validação do
                    saldo do seu cartão é que a transferência será agendada.
                  </span>
                </li>
                <li>
                  <span>
                    Solicitações efetuadas ATÉ as 15:00 horas são enviadas ao
                    banco no mesmo dia.
                  </span>
                </li>
                <li>
                  <span>
                    Solicitações efetuadas APÓS as 15:00 horas são enviadas ao
                    banco no próximo dia útil. TED - Será postado na conta
                    corrente no mesmo dia. DOC para conta poupança - Será
                    postado na conta no dia seguinte.
                  </span>
                </li>
                <li>
                  <span>Tarifa para Transferência: R$ 5,50.</span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>RECARGA DE CELULAR</span>
              <ol>
                <li>
                  <span>
                    O premiado pode cadastrar o telefone dele ou de outras
                    pessoas para uso em suas recargas. Mas somente telefones
                    cadastrados e validados por um código SMS poderão ser
                    recarregados.
                  </span>
                </li>
                <li>
                  <span>
                    Somente após a confirmação do código SMS e validação do
                    saldo do seu cartão é que a recarregar será feita em no
                    telefone pré-cadastrado.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>PAGAMENTO DE CONTA</span>
              <ol>
                <li>
                  <span>
                    Somente após a confirmação do código SMS e validação do
                    saldo do seu cartão é que o pagamento será feito. Um
                    comprovante será gerado validando a sua transação.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>
                CONSULTA DE SALDO E EXTRATO DO CARTÃO E ALTERAÇÃO DE SENHA
              </span>
              <ol>
                <li>
                  <span>
                    No painel online da operadora de cartão, não haverá custo.
                  </span>
                </li>
                <li>
                  <span>
                    Para consulta de saldo online ou alteração de senha, o
                    premiado deverá estar com o cadastro completo no painel da
                    operadora do cartão.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>BANCO 24h</span>
              <ol>
                <li>
                  <span>
                    O Premiado pode consultar o saldo nos bancos 24h mediante
                    taxa de R$ 2,24.
                  </span>
                </li>
                <li>
                  <span>
                    O Premiado pode efetuar saques na rede Banco 24h mediante
                    tarifa de R$ 9,00.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>TAXA DE INATIVIDADE</span>
              <ol>
                <li>
                  <span>
                    O Cerveja Premiada, deverá enviar uma foto da Nota Fiscal /
                    Cupom Fiscal pelo site www.cervejapremiada.com.br para que a
                    pontuação seja realizada e confirmada.
                  </span>
                </li>
                <li>
                  <span>
                    Em caso do cartão estiver sem saldo, não será cobrado a taxa
                    de inatividade.
                  </span>
                </li>
                <li>
                  <span>
                    Em caso de perda do cartão, você poderá receber um novo
                    cartão sem custo, mas para transferir o saldo para um novo
                    cartão, será cobrado pela operadora do cartão uma taxa de 2%
                    do seu saldo atual.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>MASTERCARD® SURPREENDA</span>
              <ol>
                <li>
                  <span>
                    O Usuário pode se cadastrar no programa MASTERCARD®
                    SURPREENDA, que é um programa de vantagens administrado pela
                    MASTERCARD®.
                  </span>
                </li>
                <li>
                  <span>
                    Todo o acesso e controle do programa MASTERCARD® SURPREENDA
                    é separado e independente do Cerveja Premiada.
                  </span>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </Container>
  );
}
