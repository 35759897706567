import produce from 'immer';

const estadoInicial = {
  tokenLoja: null,
  tokenPainel: null,
  autenticadoLoja: false,
  autenticadoPainel: false,
  autenticandoLoja: false,
  autenticandoLojaProprietario: false,
  autenticadoLojaProprietario: false,
  autenticandoPainel: false,
  redesAntifraude: [],
  tipoUsuario: null,
};

export default function autenticacao(state = estadoInicial, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@autenticacao/DESAUTENTICAR': {
        draft.tokenPainel = null;
        draft.autenticadoPainel = false;
        draft.tokenLoja = null;
        draft.autenticadoLoja = false;
        draft.tipoUsuario = null;
        break;
      }
      case '@autenticacaoUsuario/AUTENTICAR': {
        draft.autenticandoPainel = true;
        break;
      }
      case '@autenticacaoUsuario/DESAUTENTICAR': {
        draft.tokenPainel = null;
        draft.autenticadoPainel = false;
        draft.tipoUsuario = null;
        break;
      }
      case '@autenticacaoUsuario/AUTENTICAR_SUCESSO': {
        draft.tokenPainel = action.payload.tokenPainel;
        draft.autenticadoPainel = true;
        draft.autenticandoPainel = false;
        draft.tipoUsuario = 'administrador';
        break;
      }
      case '@autenticacaoUsuario/AUTENTICAR_FALHOU': {
        draft.autenticandoPainel = false;
        break;
      }
      case '@autenticacaoParceiro/AUTENTICAR': {
        draft.autenticandoLoja = true;
        break;
      }
      case '@autenticacaoParceiro/DESAUTENTICAR': {
        draft.tokenLoja = null;
        draft.autenticadoLoja = false;
        draft.tipoUsuario = null;
        break;
      }
      case '@autenticacaoParceiro/AUTENTICAR_SUCESSO': {
        draft.tokenLoja = action.payload.tokenLoja;
        draft.autenticadoLoja = true;
        draft.autenticandoLoja = false;
        draft.tipoUsuario = 'parceiro';
        break;
      }
      case '@autenticacaoParceiro/AUTENTICAR_FALHOU': {
        draft.autenticandoLoja = false;
        break;
      }
      case '@autenticacaoUsuario/ATUALIZAR_ANTIFRAUDE': {
        draft.redesAntifraude = action.payload.redesAntifraude;
        break;
      }
      //aa
      case '@autenticacaoProprietario/AUTENTICAR': {
        draft.autenticandoLojaProprietario = true;
        break;
      }
      case '@autenticacaoProprietario/DESAUTENTICAR': {
        draft.tokenLoja = null;
        draft.autenticadoLoja = false;
        draft.autenticandoLojaProprietario = false;
        draft.tipoUsuario = null;
        break;
      }
      case '@autenticacaoProprietario/AUTENTICAR_SUCESSO': {
        draft.tokenLoja = action.payload.tokenLoja;
        draft.autenticadoLoja = true;
        draft.autenticandoLojaProprietario = false;
        draft.tipoUsuario = 'proprietario';
        break;
      }
      case '@autenticacaoProprietario/AUTENTICAR_FALHOU': {
        draft.autenticandoLojaProprietario = false;
        break;
      }
      default:
    }
  });
}
