import React, { useState, useEffect } from 'react';
import { Container, Card } from 'react-bootstrap';
import api from '../../../services/API';
import { Row, Col } from 'react-bootstrap';
import './styles.css';
import ModalMidia from '../../Painel/ModalMidia';
import { useSelector } from 'react-redux';

export default function Midias() {
  const [mostrarModalMidia, setMostrarModalMidia] = useState(false);
  const [arquivo, setArquivo] = useState('');
  const [tipo, setTipo] = useState('');
  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const { tipoUsuario } = useSelector((state) => state.autenticacao);

  const buscarMidias = async () => {
    setBuscando(true);
    const resultado = await api.get(`/api/concurso/loja`, {
      params: { tipoUsuario },
    });
    if (resultado) {
      setData(resultado.data.concurso);
    }
    setBuscando(false);
  };

  useEffect(() => {
    buscarMidias();
  }, []);

  const fecharModalMidia = () => {
    setMostrarModalMidia(false);
  };

  return (
    <Container className="mt-5 mb-5">
      <h4 className="pt-3 pb-3 f-black">
        {buscando ? 'Buscando...' : 'Concurso de fotos '}
      </h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        {data.length === 0 && (
          <Col xs={12}>
            <Card className="border-0 p-3">
              <span>Sem resultados</span>
            </Card>
          </Col>
        )}
        {data.length > 0 &&
          data.map((midia, idx) => (
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={3}
              className="d-flex flex-column text-center"
              key={idx}
            >
              <div className="card-midia">
                <div className="card-imagem">
                  <button
                    className="btn-limpo w-100 h-100"
                    onClick={(e) => {
                      setArquivo(midia.url);
                      setMostrarModalMidia(true);
                    }}
                  >
                    <img
                      alt="Foto"
                      src={`${process.env.REACT_APP_API_BASE_URL}/arquivos/${midia.url}`}
                    ></img>
                  </button>
                </div>
                <div className="card-infos">
                  <span className="card-midia-titulo"> {midia.nome}</span>
                  <span className="card-midia-subtitulo">{`${midia.endCidade} - ${midia.endEstado}`}</span>
                </div>
                {/* <div className="card-links">
                  <a
                    href={`${process.env.REACT_APP_API_BASE_URL}/arquivos/${midia.url}`}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="fa-stack fa-lg">
                      <i className="fa fa-circle fa-stack-2x"></i>
                      <i className="fa fa-arrow-down fa-stack-1x fa-inverse"></i>
                    </span>
                  </a>
                </div> */}
              </div>
            </Col>
          ))}
      </Row>
      <ModalMidia
        show={mostrarModalMidia}
        onHide={fecharModalMidia}
        diretorioArquivo={arquivo}
      />
    </Container>
  );
}
