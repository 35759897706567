/*  eslint-disable-next-line */
/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Historico from './services/Historico';
import { store, persistor } from './store';
import rotasPainel from './routes/Painel';
import rotasLoja from './routes/Loja';
import './config/globalStyles.css';
import './config/zoomComponent.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-image-lightbox/style.css';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={Historico}>
          <Switch>
            {rotasPainel}
            {rotasLoja}
          </Switch>
          <ToastContainer transition={Bounce} autoClose={3000} />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
