/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';

export default function ModalMidia(props) {
  const [modal, setModal] = useState('');

  useEffect(() => {
    gerarModal();
  }, [props.show]);

  const fecharModal = () => {
    setModal('');
    props.onHide();
  };

  const gerarModal = () => {
    if (props.show) {
      setModal(
        <Lightbox
          mainSrc={`${process.env.REACT_APP_API_BASE_URL}/arquivos/${props.diretorioArquivo}`}
          onCloseRequest={fecharModal}
        />
      );
    }
  };

  return modal;
}
