/*  eslint-disable-next-line */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';
import sortFuncDate from '../../../utils/ordernarColunaDate';
import ModalEditar from './ModalEditar/index';
import RemoteTable from '../RemoteTable/index';
import { useSelector } from 'react-redux';
import { Filtros } from './Filtros';
import { useObject } from '../../../hooks/useObject';
import { primeiraLetraMaiscula } from '../../../helpers/regioes';

export default function Revendas() {
  const usuario = useSelector((state) => {
    return state.usuario;
  });
  const { regiaoPermissao, programaPermissao } = useSelector(
    (state) => state.usuario
  );

  const columns = [
    {
      dataField: 'codigo',
      text: 'DHNK',
      sort: true,
    },
    {
      dataField: 'nomeFantasia',
      text: 'Nome Fantasia',
      sort: true,
      formatter: (celula, valor) => {
        return primeiraLetraMaiscula(valor.nomeFantasia);
      },
    },
    {
      dataField: 'nomePrograma',
      text: 'Programa',
      sort: true,
      formatter: (celula, valor) => {
        return primeiraLetraMaiscula(valor.nomePrograma);
      },
    },
    {
      dataField: 'marca',
      text: 'Marca',
      formatter: (celula, valor) => {
        if (!valor.marca) return '-';
        return valor.marca;
      },
      sort: true,
    },
    {
      dataField: 'dcc',
      text: 'DCC',
      formatter: (celula, valor) => {
        if (!valor.dcc) return '-';
        return valor.dcc;
      },
      sort: true,
    },
    {
      dataField: 'ocdb',
      text: 'OCDB',
      formatter: (celula, valor) => {
        if (!valor.ocdb) return '-';
        return valor.ocdb;
      },
      sort: true,
    },
    {
      dataField: 'rtm',
      text: 'RTM',
      formatter: (celula, valor) => {
        if (!valor.rtm) return '-';
        return valor.rtm;
      },
      sort: true,
    },
    {
      dataField: 'codigoOperacao',
      text: 'Código Operação',
      formatter: (celula, valor) => {
        if (!valor.codigoOperacao) return '-';
        return valor.codigoOperacao;
      },
      sort: true,
    },
    {
      dataField: 'operacao',
      text: 'Operação',
      formatter: (celula, valor) => {
        if (!valor.operacao) return '-';
        return valor.operacao;
      },
      sort: true,
    },
    {
      dataField: 'razaoSocial',
      text: 'Razão Social',
      formatter: (celula, valor) => {
        return primeiraLetraMaiscula(valor.razaoSocial);
      },
      sort: true,
    },
    {
      dataField: 'cnpj',
      text: 'CPF / CNPJ',
      formatter: (celula, valor) => {
        let cnpj = valor.cnpj.replace(/[^\d]/g, '');

        cnpj =
          cnpj.length === 11
            ? cnpj.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
            : cnpj.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                '$1.$2.$3/$4-$5'
              );
        return cnpj;
      },
      sort: true,
    },
    {
      dataField: 'responsavel',
      text: 'Responsável',
      sort: true,
    },
    {
      dataField: 'telefone',
      text: 'Telefone',
      sort: true,
      formatter: (celula, valor) => {
        return valor.telefone
          ? valor.telefone.length === 10
            ? valor.telefone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
            : valor.telefone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
          : '';
      },
    },
    {
      dataField: 'celular',
      text: 'Celular',
      sort: true,
      formatter: (celula, valor) => {
        return valor.celular
          ? valor.celular.length === 10
            ? valor.celular.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
            : valor.celular.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
          : '';
      },
    },
    {
      dataField: 'endCidade',
      text: 'Cidade',
      sort: true,
    },
    {
      dataField: 'ativadoEm',
      text: 'Data de Cadastro',
      formatter: (celula, valor) => {
        return valor.ativadoEm && valor.ativadoEm !== 0
          ? moment(valor.ativadoEm).format('DD/MM/YYYY ')
          : '';
      },
      sort: true,
    },
    {
      dataField: 'totalParceiros',
      text: 'Nº Garçons PDVs',
      sort: false,
    },
    {
      dataField: 'totalPontos',
      text: 'Pontuação Total',
      formatter: (celula, valor) => {
        return Number(valor.totalPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'saldoPontos',
      text: 'Saldo de Pontos',
      formatter: (celula, valor) => {
        return Number(valor.saldoPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'ultimaPontuacao',
      text: 'Última Pontuação',
      formatter: (celula, valor) => {
        return valor.ultimaPontuacao && valor.ultimaPontuacao !== 0
          ? moment(valor.ultimaPontuacao).format('DD/MM/YYYY ')
          : '-';
      },
      sort: true,
      sortFunc: sortFuncDate,
    },
    {
      text: 'Dias s/ Pontuar',
      dataField: 'diasSemPontuar',
      sort: false,
      sortFunc: sortFunc,
    },
    {
      text: 'Dias s/ Acessar',
      dataField: 'diasSemAcessar',
      sort: false,
      sortFunc: sortFunc,
    },
    {
      dataField: 'aceitouRegulamento',
      text: 'Aceitou Regulamento',
      sort: true,
    },
    {
      dataField: 'temporada',
      text: 'Temporada',
      formatter: (celula, valor) => valor.temporada.split(' ', 2)[1],
      sort: true,
    },
    {
      dataField: 'nomeRegional',
      text: 'Regional',
      sort: true,
    },
    {
      dataField: 'disparadoMensagemBoasVindasWhats',
      text: 'Recebeu Boas-Vindas',
      sort: true,
    },
    {
      dataField: 'disparadoMensagemPesquisaWhats',
      text: 'Recebeu Pesquisa',
      sort: true,
    },
    {
      isDummyField: true,
      dataField: 'ativo',
      text: 'Ativo',
      sort: true,
      hidden: [2].includes(usuario.permissao) ? false : true,
    },
    {
      dataField: 'feedback',
      isDummyField: true,
      text: 'Feedback',
      sort: false,
      hidden: [1, 2].includes(usuario.permissao) ? false : true,
    },
    {
      dataField: 'editar',
      isDummyField: true,
      text: 'Editar',
      sort: false,
      hidden: [1, 2].includes(usuario.permissao) ? false : true,
    },
    {
      dataField: 'apagar',
      isDummyField: true,
      text: 'Apagar',
      sort: false,
      hidden: [2].includes(usuario.permissao) ? false : true,
    },
  ];

  const [cpf, setCpf] = useState(usuario.cpf)

  const [filtros, setFiltro] = useObject({
    programas: programaPermissao ?? '',
    regiao: regiaoPermissao ? regiaoPermissao : '',
    cpf
  });

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">PDVs</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <RemoteTable
          exportar
          dataId="id"
          url={`/api/revendas`}
          colunas={columns}
          ordenacaoInicial={{ campo: 'ultimaPontuacao', direcao: 'desc' }}
          fieldModalExlcluir="nomeFantasia"
          modalEdit={ModalEditar}
          filtros={filtros}
          filtrosRow={<Filtros setFiltro={setFiltro} />}
        />
      </div>
    </Container>
  );
}
