import moment from 'moment-timezone';
import React from 'react';
import { Table, Modal, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { primeiraLetraMaiscula } from '../../../helpers/regioes';
import sortFuncDate from '../../../utils/ordernarColunaDate';
import sortFunc from '../../../utils/ordernarColunaInt';
import RemoteTable from '../RemoteTable';

export default function TableListaParceiroSemAcessar15(props) {
  const usuario = useSelector((state) => {
    return state.usuario;
  });
  const columns = [
    {
      dataField: 'nome',
      text: 'Garçom',
      sort: true,
      formatter: (celula, valor) => {
        return primeiraLetraMaiscula(valor.nome);
      },
    },
    {
      dataField: 'cpf',
      text: 'CPF',
      formatter: (celula, valor) => {
        let cpf = valor.cpf.replace(/[^\d]/g, '');
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'celular',
      text: 'Celular',
      formatter: (celula, valor) => {
        return `(${valor.celular.substring(0, 2)}) ${valor.celular.substring(
          2,
          7
        )}-${valor.celular.substring(7)}`;
      },
      sort: true,
    },
    {
      dataField: 'endCidade',
      text: 'Cidade',
      sort: true,
      formatter: (celula, valor) => {
        return primeiraLetraMaiscula(valor.endCidade);
      },
    },
    {
      text: 'PDV',
      dataField: 'revenda',
      sort: true,
      formatter: (celula, valor) => {
        return primeiraLetraMaiscula(valor.revenda);
      },
    },
    {
      text: 'Programa',
      dataField: 'programa',
      sort: true,
      formatter: (celula, valor) => {
        return primeiraLetraMaiscula(valor.programa);
      },
    },
    {
      text: 'Código PDV',
      dataField: 'codigoRevenda',
      sort: true,
    },
    {
      dataField: 'totalPontos',
      text: 'Pontuação Total',
      formatter: (celula, valor) => {
        return Number(valor.totalPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'saldoPontos',
      text: 'Saldo de Pontos',
      formatter: (celula, valor) => {
        return Number(valor.saldoPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      text: 'Última Pontuação',
      dataField: 'ultimaPontuacao',
      formatter: (celula, valor) => {
        return valor.ultimaPontuacao && valor.ultimaPontuacao !== 0
          ? moment(valor.ultimaPontuacao).format('DD/MM/YYYY')
          : '-';
      },
      sort: true,
      sortFunc: sortFuncDate,
    },
    {
      text: 'Último Acesso',
      dataField: 'ultimoAcessoEm',
      formatter: (celula, valor) => {
        return valor.ultimaPontuacao && valor.ultimaPontuacao !== 0
          ? moment(valor.ultimaPontuacao).format('DD/MM/YYYY')
          : '-';
      },
      sort: true,
      sortFunc: sortFuncDate,
    },
    {
      text: 'Dias s/ Pontuar',
      dataField: 'diasSemPontuar',
      sort: true,
      sortFunc: sortFunc,
      formatter: (celula, valor) => {
        return valor.diasSemPontuar && valor.diasSemPontuar !== 0
          ? valor.diasSemPontuar
          : '-';
      },
    },
    {
      text: 'Dias s/ Acessar',
      dataField: 'diasSemAcessar',
      sort: true,
      sortFunc: sortFunc,
      formatter: (celula, valor) => {
        return valor.diasSemAcessar && valor.diasSemAcessar !== 0
          ? valor.diasSemAcessar
          : '-';
      },
    },
    {
      dataField: 'aceitouRegulamento',
      text: 'Aceitou Regulamento',
      sort: true,
    },
    {
      dataField: 'temporada',
      text: 'Temporada',
      formatter: (celula, valor) => valor.temporada.split(' ', 2)[1],
      sort: true,
    },
    {
      dataField: 'nomeRegional',
      text: 'Regional',
      sort: true,
    },
    {
      dataField: 'disparadoMensagemBoasVindasWhats',
      text: 'Recebeu Boas-Vindas',
      sort: true,
    },
    {
      dataField: 'disparadoMensagemPesquisaWhats',
      text: 'Recebeu Pesquisa',
      sort: true,
    },
    {
      isDummyField: true,
      dataField: 'ativo',
      text: 'Ativo',
      sort: true,
      hidden: [2].includes(usuario.permissao) ? false : true,
    },
  ];

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Garçons Sem Acessar + 15 dias</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <RemoteTable
          dataId="id"
          url={`/api/parceiros/sem-acessar-15`}
          colunas={columns}
          ordenacaoInicial={{ campo: 'nome', direcao: 'asc' }}
        />
      </div>
    </Container>
  );
}
