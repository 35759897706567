/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col } from 'react-bootstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { anos, primeiraLetraMaiscula } from '../../../helpers/regioes';
import { useFetch } from '../../../hooks/useFetch';
import { useObject } from '../../../hooks/useObject';
import sortFunc from '../../../utils/ordernarColunaInt';
import queryString from 'query-string';
import api from '../../../services/API';
import { useSelector } from 'react-redux';
import { CsvComponent } from '../CsvComponent';

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'nome',
    order: 'asc',
  },
];

export default function Historico() {
  const usuario = useSelector((state) => state.usuario)
  const { regiaoPermissao, programaPermissao } = useSelector(
    (state) => state.usuario
  );

  const columns = [
    {
      dataField: 'codigo',
      text: 'DHNK',
      sort: true,
    },
    {
      dataField: 'nome',
      text: 'Nome',
      formatter: (celula, valor) => {
        return primeiraLetraMaiscula(valor.nome);
      },
      sort: true,
    },
    {
      dataField: 'nomePrograma',
      text: 'Programa',
      sort: true,
    },
    {
      dataField: 'regiao',
      text: 'Região',
      sort: true,
    },
    {
      dataField: 'cidade',
      text: 'Cidade',
      sort: true,
    },
    {
      dataField: 'marca',
      text: 'Marca',
      formatter: (celula, valor) => {
        if (!valor.marca) return '-';
        return valor.marca;
      },
      sort: true,
    },
    {
      dataField: 'dcc',
      text: 'DCC',
      formatter: (celula, valor) => {
        if (!valor.dcc) return '-';
        return valor.dcc;
      },
      sort: true,
    },
    {
      dataField: 'ocdb',
      text: 'OCDB',
      formatter: (celula, valor) => {
        if (!valor.ocdb) return '-';
        return valor.ocdb;
      },
      sort: true,
    },
    {
      dataField: 'rtm',
      text: 'RTM',
      formatter: (celula, valor) => {
        if (!valor.rtm) return '-';
        return valor.rtm;
      },
      sort: true,
    },
    {
      dataField: 'janeiro',
      text: 'Janeiro',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.janeiro)
          .toLocaleString('pt-BR')
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'fevereiro',
      text: 'Fevereiro',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.fevereiro)
          .toLocaleString('pt-BR')
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'marco',
      text: 'Março',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.marco)
          .toLocaleString('pt-BR')
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'abril',
      text: 'Abril',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.abril)
          .toLocaleString('pt-BR')
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'maio',
      text: 'Maio',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.maio)
          .toLocaleString('pt-BR')
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'junho',
      text: 'Junho',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.junho)
          .toLocaleString('pt-BR')
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'julho',
      text: 'Julho',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.julho)
          .toLocaleString('pt-BR')
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'agosto',
      text: 'Agosto',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.agosto)
          .toLocaleString('pt-BR')
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'setembro',
      text: 'Setembro',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.setembro)
          .toLocaleString('pt-BR')
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'outubro',
      text: 'Outubro',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.outubro)
          .toLocaleString('pt-BR')
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'novembro',
      text: 'Novembro',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.novembro)
          .toLocaleString('pt-BR')
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'dezembro',
      text: 'Dezembro',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.dezembro)
          .toLocaleString('pt-BR')
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
  ];

  const buscarMarcas = async () => {
    setBuscando(true)
    const resultado = await api.get(`/api/marcas`);

    if (resultado) {
      setMarcas(resultado.data.marcas)
    }
  }

  const buscarUsuario = async () => {
    const response = await api.get(`/api/usuario?cpf=${cpf}`)

    if (response) {
      const { marcas, rtm } = response.data.usuario;
      setMarcaPermissao(marcas)
      setRtmPermissao(rtm)
    }
  }


  const [cpf, setCpf] = useState(usuario.cpf);
  const [filtros, setFiltro] = useObject({
    regiao: regiaoPermissao ? regiaoPermissao : '',
    programa: programaPermissao ?? '',
    ano: 2022,
    cpf
  });
  const [buscando, setBuscando] = useState(false);
  const [rtm, setRtm] = useState([]);
  const [rtmPermissao, setRtmPermissao] = useState('');
  const [marcas, setMarcas] = useState([]);
  const [marcaPermissao, setMarcaPermissao] = useState('');
  const [data, setData] = useState([]);

  const buscarHistorico = async () => {
    setBuscando(true);
    const resultado = await api.get(
      `/api/historico?${queryString.stringify(filtros)}`
    );
    if (resultado) {
      setData(resultado.data.historico);
    }
    setBuscando(false);
  };

  const [regioes, fetchRegioes] = useFetch('/api/regioes', 'get');
  const [programas, fetchProgramas] = useFetch('/api/programas', 'get');

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total px-2">
        {size > 0 ? `${from}-${to} de ${size} resultados` : 'Nenhum resultado'}
      </span>
    ),
  };

  const rtmSelect = [
    { id: 1, valor: 'dcc', nome: 'DCC' },
    { id: 2, valor: 'dhnk', nome: 'DHNK' },
  ]

  useEffect(() => {
    fetchRegioes({});
    fetchProgramas({});
    buscarMarcas()
    buscarHistorico();
    buscarUsuario()
  }, []);

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Histórico</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <ToolkitProvider
        bootstrap4
        keyField="rede"
        data={data}
        columns={columns}
        search
      >
        {(props) => (
          <div>
            <Row>
              <Col xs={12} sm={6} md={4} lg={2} className="d-flex flex-column">
                <label htmlFor="regiaoSelecionada">Regiões</label>
                <select
                  className="input-select mb-3"
                  name="regiaoSelecionada"
                  id="regiaoSelecionada"
                  onChange={(e) =>
                    setFiltro({ ...filtros, regiao: e.target.value })
                  }
                  defaultValue=""
                  placeholder=""
                  disabled={regiaoPermissao}
                >
                  <option value="">Todas</option>
                  {!isEmpty(regioes.data)
                    ? regioes.data.regioes.map((regiao) => {
                        let isSelected = regiaoPermissao === regiao.nome;
                        return (
                          <option
                            selected={isSelected}
                            value={regiao.nome}
                            key={regiao.id}
                          >
                            {regiao.nome}
                          </option>
                        );
                      })
                    : ''}
                </select>
              </Col>
              <Col xs={12} sm={6} md={4} lg={2} className="d-flex flex-column">
                <label htmlFor="programaSelecionada">Programa</label>
                <select
                  className="input-select mb-3"
                  name="programaSelecionada"
                  id="programaSelecionada"
                  onChange={(e) =>
                    setFiltro({ ...filtros, programa: e.target.value })
                  }
                  disabled={programaPermissao}
                  defaultValue=""
                  placeholder=""
                >
                  <option value="">Todas</option>
                  {!isEmpty(programas.data)
                    ? programas.data.programas.map((programa) => {
                        let isSelected =
                          Number(programaPermissao) === Number(programa.id);
                        return (
                          <option
                            value={programa.id}
                            key={programa.id}
                            selected={isSelected}
                          >
                            {programa.nome}
                          </option>
                        );
                      })
                    : ''}
                </select>
              </Col>
              <Col xs={12} sm={6} md={4} lg={2} className="d-flex flex-column">
                <label htmlFor="marcaSelecionada">Marca</label>
                <select
                  className="input-select mb-3"
                  name="marcaSelecionada"
                  id="marcaSelecionada"
                  onChange={(e) =>
                    setFiltro({ ...filtros, marca: e.target.value })
                  }
                  disabled={(marcaPermissao === '' || String(marcaPermissao).includes('[')) ? '' : marcaPermissao}
                  defaultValue=""
                  placeholder=""
                >
                  <option value="">Todas</option>
                  { marcas ?
                      marcas.map(marca => {
                        let isSelected = (marcaPermissao === '' || String(marcaPermissao).includes('[')) ?
                          undefined
                          :
                          String(marcaPermissao) === String(marca.marca).toLowerCase();
                        return (
                          <option
                            value={marca.marca}
                            key={marca.marca}
                            selected={isSelected}
                          >
                            {marca.marca}
                          </option>
                        )
                      })
                      :
                      ''
                    }
                </select>
              </Col>
              <Col xs={12} sm={6} md={4} lg={2} className="d-flex flex-column">
                <label htmlFor="rtmSelecionado">RTM</label>
                <select
                  className="input-select mb-3"
                  name="rtmSelecionado"
                  id="rtmSelecionado"
                  onChange={(e) =>
                    setFiltro({ ...filtros, rtm: e.target.value })
                  }
                  disabled={(rtmPermissao === '' || String(rtmPermissao).includes('[')) ? '' : rtmPermissao}
                  defaultValue=""
                  placeholder=""
                >
                  <option value="">Todas</option>
                  {
                    rtmSelect.map(rtm => {
                      let isSelected = (rtmPermissao === '' || String(rtmPermissao).includes('[')) ?
                          undefined
                          :
                          String(rtmPermissao) === String(rtm.nome).toLowerCase();
                      return (
                        <option
                          value={rtm.valor}
                          key={rtm.id}
                          selected={isSelected}
                        >{rtm.nome}</option>
                      )
                    })
                  }
                </select>
              </Col>
              <Col xs={12} sm={6} md={4} lg={1} className="d-flex flex-column">
                <label htmlFor="ano">Ano</label>
                <select
                  className="input-select mb-3"
                  name="ano"
                  id="ano"
                  onChange={(e) =>
                    setFiltro({ ...filtros, ano: e.target.value })
                  }
                  defaultValue={filtros.ano}
                  placeholder=""
                >
                  <option value="">Todos</option>
                  {anos.map((ano) => (
                    <option value={ano} key={ano}>
                      {ano}
                    </option>
                  ))}
                </select>
              </Col>
              <Col xs={12} sm={6} md={2} className="d-flex flex-column">
                <label>Digite sua busca</label>
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mx-auto w-100"
                />
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={1}
                className="d-flex flex-row row-btn-filtro"
              >
                <button
                  className="btn-busca mr-2"
                  type="submit"
                  onClick={buscarHistorico}
                >
                  {buscando ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    <i className="fas fa-search"></i>
                  )}
                </button>
                <CsvComponent
                  {...props.csvProps}
                  data={data}
                  columns={columns}
                />
              </Col>
            </Row>

            <BootstrapTable
              noDataIndication={() =>
                buscando ? (
                  <span>
                    Carregando
                    <i className="fas fa-circle-notch fa-spin ml-3"></i>
                  </span>
                ) : (
                  <span>Sem resultados</span>
                )
              }
              classes="table-dark text-center rounded"
              wrapperClasses="rounded"
              bordered={false}
              pagination={paginationFactory(options)}
              defaultSorted={defaultSorted}
              {...props.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    </Container>
  );
}
