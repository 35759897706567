/*  eslint-disable-next-line */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useFetch } from '../../../../hooks/useFetch';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const Filtros = ({ filtros, setFiltro }) => {
  const [dataProgramas, fetchProgramas] = useFetch('/api/programas', 'get');
  const [dataRegioes, fetchRegioes] = useFetch('/api/regioes', 'get');
  const { regiaoPermissao, programaPermissao } = useSelector(
    (state) => state.usuario
  );

  useEffect(() => {
    fetchProgramas({});
    fetchRegioes({});
  }, []);

  return (
    <>
      <Col xs={12} sm={6} md={4} lg={2} className="d-flex flex-column">
        <label htmlFor="regiaoSelecionada">Região</label>
        <select
          className="input-select mb-3"
          name="regiaoSelecionada"
          id="regiaoSelecionada"
          onChange={(e) => setFiltro({ regiao: e.target.value })}
          defaultValue=""
          placeholder=""
          disabled={regiaoPermissao}
        >
          <option value="">Todas</option>
          {!isEmpty(dataRegioes.data)
            ? dataRegioes.data.regioes.map((regiao) => {
                let isSelected = regiaoPermissao === regiao.nome;
                return (
                  <option
                    selected={isSelected}
                    value={regiao.nome}
                    key={regiao.id}
                  >
                    {regiao.nome}
                  </option>
                );
              })
            : ''}
        </select>
      </Col>
      <Col xs={12} sm={6} md={4} lg={2} className="d-flex flex-column">
        <label htmlFor="programaSelecionado">Programa</label>
        <select
          className="input-select mb-3"
          name="programaSelecionado"
          id="programaSelecionado"
          onChange={(e) => setFiltro({ programa: e.target.value })}
          disabled={programaPermissao}
          defaultValue=""
          placeholder=""
        >
          <option value="">Todas</option>
          {!isEmpty(dataProgramas.data)
            ? dataProgramas.data.programas.map((programa) => {
                let isSelected =
                  Number(programaPermissao) === Number(programa.id);
                return (
                  <option
                    value={programa.id}
                    key={programa.id}
                    selected={isSelected}
                  >
                    {programa.nome}
                  </option>
                );
              })
            : ''}{' '}
        </select>
      </Col>
      <Col xs={12} sm={6} md={3} lg={2} className="d-flex flex-column">
        <label>De</label>
        <input
          type="date"
          className="input-theme mb-3"
          value={filtros.dataInicial}
          onChange={(e) => setFiltro({ dataInicial: e.target.value })}
          max="2999-12-31"
        ></input>
      </Col>
      <Col xs={12} sm={6} md={3} lg={2} className="d-flex flex-column">
        <label>Até</label>
        <input
          type="date"
          className="input-theme mb-3"
          onChange={(e) => setFiltro({ dataFinal: e.target.value })}
          value={filtros.dataFinal}
          max="2999-12-31"
        ></input>
      </Col>
    </>
  );
};
