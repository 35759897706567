/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Col, Container, Row } from 'react-bootstrap';
import ModalAlterarStatusResgate from '../ModalAlterarStatusResgate';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';
import { CsvComponent } from '../CsvComponent';
import { useFetch } from '../../../hooks/useFetch';
import ModalCancelarResgate from '../ModalCancelarResgate';
import { useSelector } from 'react-redux';
import ModalDigital from '../ModalDigital';
import api from '../../../services/API';
import { isEmpty } from 'lodash';
import ModalEntrega from '../ModalEntrega';
import RemoteTable from '../RemoteTable';
import Utils from '../../../utils/utils';

const { SearchBar } = Search;

export default function Resgates() {
  const [openStatus, setOpenStatus] = useState(false);
  const [openDigital, setOpenDigital] = useState(false);
  const [openEntrega, setOpenEntrega] = useState(false);
  const [codigo, setCodigo] = useState('');
  const [refresh, setRefresh] = useState(0);

  const onRefresh = () => {
    setRefresh(refresh + 1);
  };

  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const [mostrarModalCancelarResgate, setMostrarModalCancelarResgate] =
    useState(false);

  const [resgate, setResgate] = useState('');

  const usuario = useSelector((state) => {
    return state.usuario;
  });

  const formatarData = (celula, valor) => {
    return moment(valor.resgatadoEm).format('DD/MM/YYYY HH:mm:ss');
  };

  const formatarQtde = (celula, valor) => {
    return Number(valor.qtde).toLocaleString('pt-BR');
  };

  const formatarPontos = (celula, valor) => {
    return Number(valor.pontosGastos).toFixed(0).toLocaleString('pt-BR');
  };

  const formatarPremioResgatado = (celula, valor) => {
    const premio = valor.digital
      ? `${valor.premio} - ${valor.digital}`
      : valor.premio;
    return valor.obs ? `${premio} - ${valor.obs}` : `${premio}`;
  };

  const formatarStatus = (celula, valor) => {
    if (valor.status === 0) {
      return (
        <span>
          <i className="fa fa-times"></i> Não
        </span>
      );
    } else if (valor.status === 1) {
      return (
        <span>
          <i className="fa fa-check"></i> Sim
        </span>
      );
    } else if (valor.status === 2) {
      return (
        <span>
          <i className="fa fa-truck"></i> Em trânsito
        </span>
      );
    } else if (valor.status === 3) {
      return (
        <span>
          <i className="fa fa-times"></i> Cancelado
        </span>
      );
    } else if (valor.status === 4) {
      return <span>Devolvido</span>;
    }
  };

  const formatarStatusTexto = (celula, valor) => {
    if (valor.status === 0) {
      return 'Não';
    } else if (valor.status === 1) {
      return 'Sim';
    } else if (valor.status === 2) {
      return 'Em trânsito';
    } else if (valor.status === 3) {
      return 'Cancelado';
    } else if (valor.status === 4) {
      return 'Devolvido';
    }
  };

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
    },
    {
      dataField: 'idParceiro',
      text: 'Participante',
      sort: true,
      formatter: (celula, valor) => {
        if(valor.idParceiro) return 'Garçom';
        return 'Proprietário'
      }
    },
    {
      dataField: 'parceiro',
      text: 'Nome',
      sort: true,
      formatter: (celula, valor) => {
        if(!valor.parceiro) return '-';
        return valor.parceiro;
      }
    },
    {
      dataField: 'cpf',
      text: 'CPF',
      formatter: (celula, value) => {
        return value.cpf
          ? Utils.formatCpf(String(value.cpf))
          : value.cpfRevenda
            ? Utils.formatCpf(String(value.cpfRevenda))
            : '-';
      },
      sort: true,
    },
    {
      dataField: 'codPdv',
      text: 'Cod. PDV',
      sort: true,
    },
    {
      dataField: 'revenda',
      text: 'PDV',
      sort: true,
    },
    {
      dataField: 'nomePrograma',
      text: 'Programa',
      sort: true,
    },
    {
      dataField: 'cidade',
      text: 'Cidade',
      sort: true,
    },
    {
      dataField: 'entregaEndereco',
      text: 'Endereco de Entrega',
      sort: true,
    },
    {
      dataField: 'entregaNome',
      text: 'Nome Entrega',
      sort: true,
    },
    {
      dataField: 'premio',
      text: 'Produto Resgatado',
      formatter: formatarPremioResgatado,
      sort: true,
    },
    {
      dataField: 'qtde',
      text: 'Quantidade',
      formatter: formatarQtde,
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'pontosGastos',
      text: 'Pontos Utilizados',
      formatter: formatarPontos,
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'resgatadoEm',
      text: 'Data / Hora',
      formatter: formatarData,
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Entregue',
      formatter: formatarStatus,
      csvFormatter: formatarStatusTexto,
      sort: true,
    },

    {
      text: 'Alterar Status',
      dataField: 'alterarStatus',
      isDummyField: true,
      hidden: [2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) =>
        valor.status !== 3 && valor.status !== 4 ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalAlterarStatusResgate(valor.id);
            }}
          >
            <i className="fas fa-edit"></i>
          </button>
        ) : (
          '-'
        ),
    },
    {
      dataField: 'prazoEntrega',
      text: 'Prazo entrega',
      formatter: (celula, valor) => {
        return valor.prazoEntrega
          ? moment(valor.prazoEntrega).format('DD/MM/YYYY')
          : '-';
      },
      sort: true,
    },
    {
      text: 'Alterar Entrega',
      dataField: 'alterarEntrega',
      isDummyField: true,
      hidden: [2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) =>
        valor.status !== 4 ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => handleEntrega(valor)}
          >
            <i className="fas fa-edit"></i>
          </button>
        ) : (
          '-'
        ),
    },
    {
      text: 'Digital',
      dataField: 'digital',
      isDummyField: true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            handleDigital(valor.id, valor.digital);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
    },
    {
      text: 'Cancelar Resgate',
      isDummyField: true,
      dataField: 'cancelarResgate',
      hidden: [2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) =>
        valor.status !== 3 ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalCancelarResgate(valor.id);
            }}
          >
            <i className="fas fa-times f-red"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            CANCELADO
          </button>
        ),
    },
  ];

  const defaultSorted = [
    {
      dataField: 'resgatadoEm',
      order: 'desc',
    },
  ];

  useEffect(() => {
    if (isEmpty(data)) {
      buscarResgates();
    }
  }, []);

  const buscarResgates = async () => {
    setIsFetching(true);
    const resultado = await api.get(`/api/resgates`);

    if (resultado) {
      setData(resultado.data.resgates);
    }
    setIsFetching(false);
  };

  const visualizarModalAlterarStatusResgate = (id) => {
    setResgate(id);
    setOpenStatus(true);
  };

  const handleDigital = (id, codigo = '') => {
    setResgate(id);
    setCodigo(codigo);

    setOpenDigital(true);
  };

  const handleEntrega = (resgate = '') => {
    setResgate(resgate);
    setOpenEntrega(!openEntrega);
  };

  const handleCloseEntrega = (toUpdate) => {
    toUpdate &&
      setData(
        data.map((d) => {
          if (d.id === toUpdate.identificador) d.prazoEntrega = toUpdate.date;
          return d;
        })
      );

    onRefresh();

    setResgate('');
    setOpenEntrega(false);
  };

  const handleCloseDigital = (toUpdate) => {
    toUpdate &&
      setData(
        data.map((d) => {
          if (d.id === toUpdate.identificador) {
            d.digital = +toUpdate.digital;
          }
          return d;
        })
      );
    onRefresh();
    setOpenDigital(false);
    setResgate('');
    setCodigo('');
  };

  const fecharModalAlterarStatusResgate = (toUpdate) => {
    setOpenStatus(false);
    onRefresh();
    toUpdate &&
      setData(
        data.map((d) => {
          if (d.id === toUpdate.identificador) {
            d.status = +toUpdate.status;
          }
          return d;
        })
      );
  };

  const visualizarModalCancelarResgate = (id) => {
    setResgate(id);
    setMostrarModalCancelarResgate(true);
  };

  const fecharModalCancelarResgate = (toUpdate) => {
    toUpdate &&
      setData(
        data.map((d) => {
          if (d.id === toUpdate.identificador) {
            d.status = +toUpdate.status;
          }
          return d;
        })
      );
    onRefresh();
    setMostrarModalCancelarResgate(false);
  };

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data ? data.length : 0,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Resgates</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <RemoteTable
          exportar
          dataId="id"
          url={`/api/resgates`}
          colunas={columns}
          ordenacaoInicial={{ campo: 'resgatadoEm', direcao: 'desc' }}
          refresh={refresh}
        />
      </div>
      <ModalCancelarResgate
        show={mostrarModalCancelarResgate}
        onHide={fecharModalCancelarResgate}
        resgate={resgate}
      />
      <ModalAlterarStatusResgate
        show={openStatus}
        onHide={fecharModalAlterarStatusResgate}
        resgate={resgate}
      />
      <ModalDigital
        open={openDigital}
        onClose={handleCloseDigital}
        id={resgate}
        codigo={codigo}
      />
      <ModalEntrega
        open={openEntrega}
        onClose={handleCloseEntrega}
        resgate={resgate}
      />
    </Container>
  );
}
