import React, { useEffect, useState } from 'react';
import { Carousel, Row, Container, CarouselItem } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export default function Slider() {
  const [userIdTemporada, setUserIdTemporada] = useState(null);
  const [urlApp, setUrlApp] = useState(null);
  const parceiro = useSelector((state) => {
    return state.parceiro;
  });

  const proprietario = useSelector((state) => {
    return state.proprietario;
  });

  const tipoUsuario = useSelector((state) => state.autenticacao.tipoUsuario);

  useEffect(() => {
    if (tipoUsuario === 'parceiro') {
      setUserIdTemporada(parceiro.idTemporada);
    } else if (tipoUsuario === 'proprietario') {
      setUserIdTemporada(proprietario.idTemporada);
    }
    if (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform)
    ) {
      setUrlApp('https://apps.apple.com/br/app/cerveja-premiada/id1624581751');
    } else if (['Android'].includes(navigator.platform)) {
      setUrlApp(
        'https://play.google.com/store/apps/details?id=br.com.cervejapremiada'
      );
    } else {
      setUrlApp('/loja/aplicativo');
    }
  }, [tipoUsuario]);

  return (
    <Container fluid="md" className="mb-5">
      <Row>
        {tipoUsuario === 'proprietario' ? (
          <Carousel interval={3000} indicators={false}>
            {/* <Carousel.Item>
              <div
                onClick={() => {
                  window
                    .open(
                      'http://www.abriuachouganhou.com.br/areapontodetroca',
                      '_blank'
                    )
                    .focus();
                }}
                style={{ cursor: 'pointer' }}
              >
                <img
                  className="d-block w-100"
                  src="imagens/microsorftTeams.png"
                  alt="Slider"
                />
              </div>
            </Carousel.Item> */}
            {/* <Carousel.Item>
              <a href={'/palpite-copa'}>
                <img
                  className="d-block w-100"
                  src="imagens/jogoCopaDois.jpg"
                  // src="imagens/jogoCapaModal.jpg"
                  alt="Slider"
                />
              </a>
            </Carousel.Item> */}
            <Carousel.Item>
              <a href={urlApp}>
                <img
                  className="d-block w-100"
                  src="imagens/banner-home-loja2.png"
                  alt="Slider"
                />{' '}
              </a>
            </Carousel.Item>
          </Carousel>
        ) : (
          <>
            <Carousel interval={3000} indicators={false}>
              {/* <Carousel.Item>
                <div
                  onClick={() => {
                    window.open('/loja/super-acelerador', '_blank').focus();
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    className="d-block w-100"
                    src="imagens/banner-home-loja.jpg"
                    alt="Slider"
                  />
                </div>
              </Carousel.Item> */}
              {/* <Carousel.Item>
                <a href={'/palpite-copa'}>
                  <img
                    className="d-block w-100"
                    // src="imagens/jogoCapaModal.jpg"
                    src="imagens/jogoCopaDois.jpg"
                    alt="Slider"
                  />
                </a>
              </Carousel.Item> */}
              <Carousel.Item>
                <a href={urlApp}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-home-loja2.png"
                    alt="Slider"
                  />
                </a>
              </Carousel.Item>
            </Carousel>
          </>
        )}
      </Row>
    </Container>
  );
}
