import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import { useEffect } from 'react';
import { sortBy } from 'lodash';

export default function FotoConcurso() {
  const [enviando, setEnviando] = useState(false);
  const [arquivo, setArquivo] = useState(false);
  const [status, setStatus] = useState('Procurar...');
  const [programas, setProgramas] = useState([]);
  const [idPrograma, setIdPrograma] = useState('');
  const [idParceiro, setIdParceiro] = useState('');
  const [revendasSelect, setRevendasSelect] = useState([]);
  const [idRevenda, setIdRevenda] = useState('');
  const [parceiros, setParceiros] = useState([]);

  const uploadMidia = async (e) => {
    e.preventDefault();

    if (!arquivo) {
      toast.error(`Selecione um arquivo primeiro`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    let formData = new FormData();
    formData.append('arquivo', arquivo);
    if (idPrograma) formData.append('idPrograma', idPrograma);
    if (idParceiro) formData.append('idParceiro', idParceiro);
    if (idRevenda) formData.append('idRevenda', idRevenda);
    setEnviando(true);
    const resultado = await api.post('/api/upload/foto_concurso', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (resultado) {
      toast.success('Mídia anexada com sucesso ao resgate', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setArquivo(null);
      setStatus('Procurar...');
    }
    setEnviando(false);
  };

  useEffect(() => {
    carregarProgramas();
    buscarRevendas();
    buscarParceiro();
  }, []);

  useEffect(() => {
    if (!arquivo) {
      document.getElementById('arquivo').value = null;
    }
  }, [arquivo]);

  const carregarProgramas = async () => {
    const resultado = await api.get('/api/programas?ativo=1');
    if (resultado) {
      setProgramas(resultado.data.programas);
    }
  };

  const buscarRevendas = async () => {
    const resultado = await api.get('/api/revendas');
    if (resultado && resultado.data.revendas) {
      setRevendasSelect(resultado.data.revendas);
    }
  };

  const buscarParceiro = async () => {
    const resultado = await api.get('/api/parceiros');
    if (resultado && resultado.data.parceiros) {
      setParceiros(resultado.data.parceiros);
    }
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Foto concurso</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={uploadMidia}>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={12}>
              <label htmlFor="programa">Selecione o programa</label>
              <select
                id="programa"
                name="programa"
                value={idPrograma}
                onChange={(e) => setIdPrograma(e.target.value)}
              >
                {programas.map((programa) => (
                  <option key={programa.nome} value={programa.id}>
                    {programa.nome}
                  </option>
                ))}
              </select>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} className="d-flex flex-column">
              <label htmlFor="pdvSelecionado">Selecione um PDV</label>
              <select
                className="input-select mb-3"
                name="pdvSelecionado"
                id="pdvSelecionado"
                defaultValue="Todos"
                placeholder="Todos"
                value={idRevenda}
                onChange={(e) => setIdRevenda(e.target.value)}
              >
                <option value="">Selecione o PDV</option>
                {sortBy(revendasSelect, 'nomeFantasia').map((revenda) => (
                  <option value={revenda.id}>
                    {' '}
                    {revenda.codigo} - {revenda.nomeFantasia}
                  </option>
                ))}
              </select>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} className="d-flex flex-column">
              <label htmlFor="pdvSelecionado">Selecione um Parceiro</label>
              <select
                className="input-select mb-3"
                name="parceiro"
                id="parceiro"
                defaultValue="Todos"
                placeholder="Todos"
                value={idParceiro}
                onChange={(e) => setIdParceiro(e.target.value)}
              >
                <option value="">Selecione um Parceiro</option>
                {sortBy(parceiros, 'nome').map((parceiro) => (
                  <option value={parceiro.id}>
                    {parceiro.id} - {parceiro.nome}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <label htmlFor="arquivo">Arquivo de foto ou vídeo</label>
          <input
            type="file"
            id="arquivo"
            name="arquivo"
            style={{ display: 'none' }}
            onChange={(e) => {
              setArquivo(e.target.files[0]);
              e.target.files[0]
                ? setStatus(e.target.files[0].name)
                : setStatus('Procurar...');
            }}
          />
          <input
            id="botao"
            type="button"
            value={status}
            onClick={(e) => {
              document.getElementById('arquivo').click();
            }}
          />

          <button className="btn-primario" type="submit">
            {enviando ? 'ENVIANDO...' : 'ENVIAR'}
          </button>
        </form>
      </div>
    </Container>
  );
}
