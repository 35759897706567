import React, { useEffect } from 'react';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './styles.css';
import { useSelector, useDispatch } from 'react-redux';
import { desautenticarUsuario } from '../../../store/modules/autenticacao/actions';
import { useState } from 'react';
import historico from '../../../services/Historico';
import itens from './itens';
import { Link, useRouteMatch } from 'react-router-dom';

export default function Menu(props) {
  let match = useRouteMatch();
  const [expanded, setMenuExpanded] = useState(true);
  const [perfilAberto, setPerfilAberto] = useState(false);
  const dispatch = useDispatch();
  const usuario = useSelector((state) => {
    return state.usuario;
  });

  // const redesAntifraude = useSelector((state) => {
  //   return state.autenticacao.redesAntifraude;
  // });

  const mostrarLogo = (state) => {
    state
      ? (document.getElementById('logo-nav').style.display = 'block')
      : (document.getElementById('logo-nav').style.display = 'none');
  };

  const mostrarPerfil = (state) => {
    state
      ? (document.getElementById('perfil').style.display = 'flex')
      : (document.getElementById('perfil').style.display = 'none');
  };

  const sair = () => {
    dispatch(desautenticarUsuario());
  };

  const perfil = () => {
    if (window.matchMedia('(max-width: 400px)').matches) {
      setMenuExpanded(false);
      mostrarLogo(false);
      mostrarPerfil(false);
    }
    historico.push('/painel/perfil');
  };

  useEffect(() => {
    if (window.matchMedia('(max-width: 400px)').matches) {
      setMenuExpanded(false);
      mostrarLogo(false);

      mostrarPerfil(false);
    }
  }, []);

  useEffect(() => {
    if (expanded && props.path !== '/painel/cadastrar/ativar-pdv') {
      document.querySelector('.main-painel').style.paddingLeft = '250px';
    } else if (expanded && props.path === '/painel/cadastrar/ativar-pdv') {
      document.querySelector('.main-painel').style.paddingLeft = '0';
      document.getElementsByTagName('nav')[0].style.display = 'none';
    } else if (!expanded && props.path === '/painel/cadastrar/ativar-pdv') {
      document.querySelector('.main-painel').style.paddingLeft = '0';
      document.getElementsByTagName('nav')[0].style.display = 'none';
    } else {
      document.querySelector('.main-painel').style.paddingLeft = '70px';
    }
  }, [expanded, props.path]);

  return (
    <SideNav
      className="position-fixed"
      expanded={expanded}
      onSelect={(item, evento) => {
        if (window.matchMedia('(max-width: 400px)').matches) {
          setMenuExpanded(false);
          mostrarLogo(false);
          mostrarPerfil(false);
        }
        props.selecionarItem(item, evento);
      }}
      onToggle={(state) => {
        setMenuExpanded(state);
        mostrarLogo(state);
        mostrarPerfil(state);
      }}
    >
      <SideNav.Toggle className="nav-toggle" />
      <img
        id="logo-nav"
        src="/imagens/logo-fundo-escuro.png"
        alt="Logo Cerveja Premiada"
      ></img>
      <SideNav.Nav>
        <div id="perfil" className="mb-3">
          <span className="nav-font nav-titulo nome-usuario">
            Olá, <b>{usuario.nome}</b>
            {perfilAberto ? (
              <button
                onClick={(e) => setPerfilAberto(false)}
                className="btn-limpo ml-2"
              >
                <i className="fas fa-angle-double-up"></i>
              </button>
            ) : (
              <button
                onClick={(e) => setPerfilAberto(true)}
                className="btn-limpo ml-2"
              >
                <i className="fas fa-angle-double-down"></i>
              </button>
            )}
          </span>
          {perfilAberto && (
            <>
              <button onClick={perfil} className="nav-sub-titulo">
                Meu Perfil
              </button>
              <button onClick={sair} className="nav-sub-titulo">
                Sair
              </button>
            </>
          )}
        </div>
        {itens.map((item) =>
          item.permissao.includes(usuario.permissao) &&
          item.idTemporada.includes(usuario.idTemporada) ? (
            <NavItem
              eventKey={item.destino ? item.destino : ''}
              key={item.destino ? item.destino : ''}
            >
              <NavIcon>
                {item.subItens || !item.destino ? (
                  <span className="nav-font">
                    <i className={item.icone} />
                  </span>
                ) : (
                  <Link to={`${match.path}${item.destino ? item.destino : ''}`}>
                    <i className={item.icone} />
                  </Link>
                )}
              </NavIcon>
              <NavText>
                {item.subItens || !item.destino ? (
                  <span className="nav-font">{item.titulo}</span>
                ) : item.titulo !== 'Antifraude' ? (
                  <Link
                    to={`${match.path}${item.destino ? item.destino : ''}`}
                    className="nav-font w-100 h-100 d-inline-block"
                  >
                    {item.titulo}
                  </Link>
                ) : (
                  <Link
                    to={`${match.path}${item.destino ? item.destino : ''}`}
                    className="nav-font h-100 d-inline-block"
                  >
                    {item.titulo}
                  </Link>
                )}
                {/*
                {item.titulo === 'Antifraude' && redesAntifraude.length > 0 && (
                  <Link
                    to={`${match.path}${item.destino ? item.destino : ''}`}
                    className="alert-antifraude w-100 h-100 d-inline-block"
                  >
                    {redesAntifraude.length}
                  </Link>
                )} */}
              </NavText>
              {item.subItens &&
                item.subItens.map(
                  (subitem) =>
                    subitem.permissao.includes(usuario.permissao) &&
                    subitem.idTemporada.includes(usuario.idTemporada) && (
                      <NavItem
                        eventKey={`${item.destino ? item.destino : ''}${
                          subitem.destino
                        }`}
                        key={`${item.destino ? item.destino : ''}${
                          subitem.destino
                        }`}
                      >
                        <NavText>
                          <Link
                            to={`${match.path}${
                              item.destino ? item.destino : ''
                            }${subitem.destino}`}
                            className="nav-font w-100 h-100 d-inline-block"
                          >
                            {subitem.titulo}
                          </Link>
                        </NavText>
                      </NavItem>
                    )
                )}
            </NavItem>
          ) : null
        )}
      </SideNav.Nav>
    </SideNav>
  );
}
