/* eslint-disable react-hooks/exhaustive-deps */
// import ModalItensPontuacao from '../ModalItensPontuacao';
// import ModalMidia from '../ModalMidia';

import React, { useState } from 'react';
import moment from 'moment-timezone';
import ModalImage from 'react-modal-image';

import { Container } from 'react-bootstrap';

import ModalCancelarPontuacao from '../ModalCancelarPontuacao';
import sortFunc from '../../../utils/ordernarColunaInt';
import { useObject } from '../../../hooks/useObject';
import { getImage } from '../../../helpers/getImage.js';
import { Filtros } from './Filtros';
import { useSelector } from 'react-redux';
import RemoteTable from '../RemoteTable/index';

export default function Pontuacoes() {
  const [openCancelar, setOpenCancelar] = useState(false);
  const [pontuacao, setPontuacao] = useState('');
  const [openFoto, setOpenFoto] = useState(false);
  const { regiaoPermissao, programaPermissao } = useSelector(
    (state) => state.usuario
  );

  const [filtros, setFiltro] = useObject({
    dataInicial: moment().subtract(7, 'days').toISOString().substr(0, 10),
    dataFinal: moment().subtract().toISOString().substr(0, 10),
    programa: programaPermissao ?? '',
    regiao: regiaoPermissao ? regiaoPermissao : '',
  });

  const columns = [
    {
      text: 'Id da Venda',
      dataField: 'id',
      sort: true,
    },
    {
      text: 'Nome',
      dataField: 'nome',
      sort: true,
    },
    {
      text: 'Cod. PDV',
      dataField: 'codigo',
      sort: true,
    },
    {
      text: 'PDV',
      dataField: 'nomeFantasia',
      sort: true,
    },
    {
      text: 'Regiao',
      dataField: 'regiao',
      sort: true,
    },
    {
      text: 'Cidade',
      dataField: 'endCidade',
      sort: true,
    },
    {
      text: 'Pontos',
      dataField: 'previsaoPontos',
      formatter: (celula, valor) => {
        return Number(valor.previsaoPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      text: 'Pontos PDV',
      dataField: 'previsaoPontosRevenda',
      formatter: (celula, valor) => {
        return Number(valor.previsaoPontosRevenda).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },

    {
      text: 'Data da Pontuação',
      dataField: 'realizadoEm',
      formatter: (celula, valor) => {
        return moment(valor.realizadoEm).format('DD/MM/YYYY');
      },
      sort: true,
    },
    {
      text: 'Validade dos Pontos',
      dataField: 'validoAte',
      formatter: (celula, valor) => {
        return moment(valor.validoAte).format('DD/MM/YYYY');
      },
    },
    {
      text: 'Foto ',
      dataField: 'diretorioArquivo',
      isDummyField: true,
      formatter: (celula, valor) => {
        return (
          <ModalImage
            className={'small-img'}
            small={'/imagens/eye.jpg'}
            large={getImage(valor.diretorioArquivo)}
            alt=""
          />
        );
      },
    },
    {
      dataField: 'temporada',
      text: 'Temporada',
      formatter: (celula, valor) => valor.temporada.split(' ', 2)[1],
      sort: true,
    },
    {
      text: 'Cancelar ',
      dataField: 'id',
      isDummyField: true,
      formatter: (celula, valor) => {
        return (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalCancelarPontuacao(valor.id);
            }}
          >
            <i className="fas fa-times f-red"></i>
          </button>
        );
      },
    },
  ];

  const handleFoto = () => {
    setOpenFoto(!openFoto);
  };

  const visualizarModalCancelarPontuacao = (id) => {
    setPontuacao(id);
    setOpenCancelar(true);
  };

  const fecharModalCancelarPontuacao = () => {
    setOpenCancelar(false);
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Pontuações</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <RemoteTable
          dataId="id"
          url={`/api/pontuacoes/pontuado`}
          colunas={columns}
          ordenacaoInicial={{ campo: 'realizadoEm', direcao: 'desc' }}
          fieldModalExlcluir="nomeFantasia"
          modalEdit={ModalCancelarPontuacao}
          filtros={filtros}
          filtrosRow={<Filtros setFiltro={setFiltro} filtros={filtros} />}
        />
      </div>
      <ModalCancelarPontuacao
        show={openCancelar}
        onHide={fecharModalCancelarPontuacao}
        pontuacao={pontuacao}
      />
    </Container>
  );
}
