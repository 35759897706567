import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { alterarStatusCartao } from '../../../store/modules/parceiro/actions';

export default function ModalBloquearCartao(props) {
  const [alterando, setAlterando] = useState(false);
  const [numero, setNumero] = useState('');
  const dispatch = useDispatch();

  const bloquear = async () => {
    const status = 1;
    setAlterando(true);
    const resultado = await api.post('/api/cartoes/status', {
      numero,
      status,
    });
    if (resultado && resultado.data.sucesso) {
      toast.success(`Solicitação de bloqueio efetuada com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      const emDesbloqueio = false;
      const status = 1;
      dispatch(alterarStatusCartao(status, emDesbloqueio));
      props.onHide();
    } else if (resultado && !resultado.data.sucesso) {
      toast.success(resultado.data.mensagem, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
    setAlterando(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Bloquear Cartão</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-2">
        <form className="d-flex flex-column justify-content-center align-items-center text-center">
          <span className="mt-3 mb-3">Informe os dados abaixo:</span>
          <Row className="form mw-100 d-flex justify-content-center align-items-center flex-column text-left">
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="numero">Número do Cartão</label>
              <input
                id="numero"
                name="numero"
                type="text"
                placeholder="Número do Cartão"
                value={numero}
                onChange={(e) => setNumero(e.target.value)}
              ></input>
            </Col>
          </Row>
          <span>
            A sua solicitação poderá levar até 24h úteis para ser efetivada.
          </span>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {alterando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={bloquear}
              disabled
            >
              ENVIANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={bloquear}
            >
              ENVIAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
