import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles.css';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  desautenticarParceiro,
  desautenticarProprietario,
} from '../../../store/modules/autenticacao/actions';
import Nav from '../../../components/Loja/Nav';
import NavMobile from '../../../components/Loja/NavMobile';

export default function Header({ auto, btnEntrar = true }) {
  const dispatch = useDispatch();

  const parceiro = useSelector((state) => {
    return state.parceiro;
  });

  const proprietario = useSelector((state) => {
    return state.proprietario;
  });

  const autenticado = useSelector(
    (state) => state.autenticacao.autenticadoLoja
  );

  const tipoUsuario = useSelector((state) => state.autenticacao.tipoUsuario);

  const sair = () => {
    localStorage.clear();
    if (tipoUsuario === 'parceiro') {
      return dispatch(desautenticarParceiro());
    } else if (tipoUsuario === 'proprietario') {
      return dispatch(desautenticarProprietario());
    }
    return '';
  };

  const getNome = () => {
    if (tipoUsuario === 'parceiro') {
      return parceiro.nome;
    } else if (tipoUsuario === 'proprietario') {
      return proprietario.nomeFantasia;
    }
    return '';
  };
  const getSaldoPontos = () => {
    if (tipoUsuario === 'parceiro') {
      return Number(parceiro.saldoPontos).toLocaleString('pt-BR');
    } else if (tipoUsuario === 'proprietario') {
      return Number(proprietario.saldoPontos).toLocaleString('pt-BR');
    }
    return '';
  };

  const getPrograma = () => {
    if (tipoUsuario === 'parceiro') {
      if (!parceiro.programa) return '';
      if (parceiro.programa.nome) return parceiro.programa.nome;
    } else if (tipoUsuario === 'proprietario') {
      if (!proprietario.programa) return '';
      return proprietario.programa.nome;
    }
    return '';
  };

  const info =
    getPrograma() !== '' ? (
      <span>
        <i className="fas fa-user-circle"></i> Bem-vindo, <b> {getNome()}</b>!
        <br /> Você faz parte do programa <b>{getPrograma()}</b> e tem{' '}
        <b>{getSaldoPontos()}</b> pontos.
      </span>
    ) : (
      <span>
        <i className="fas fa-user-circle"></i> Bem-vindo, <b> {getNome()}</b>!
        Seu saldo é de
        <b> {getSaldoPontos()}</b> pontos.
      </span>
    );
  return (
    <header className={auto ? 'header-home-auto' : 'header-home'}>
      <Container className="d-flex flex-column justify-content-center align-items-center h-100">
        {!autenticado ? (
          <Row className="w-100">
            <Col className="m-auto p-2 logo-header d-flex flex-column justify-content-center align-items-center h-100">
              <img
                src="/imagens/logo.png"
                alt="logo"
                style={{ maxWidth: '250px' }}
              />
            </Col>
            {/* <Col xs={6} sm={3} className="m-auto p-2 text-right">
              <Link to="login" className="btn-header">
                <i className="fas fa-user-circle"></i> Entrar
              </Link>
            </Col> */}
          </Row>
        ) : (
          <>
            <Row className="d-flex d-md-none w-100">
              <NavMobile />
            </Row>
            <Row className="w-100 p-3">
              <Col md={2}>
                <Link to="/loja">
                  <img
                    className="d-none d-md-block nav-logo"
                    src="/imagens/logo.png"
                    alt="Logo Cerveja Premiada"
                  ></img>
                </Link>
              </Col>
              <Col xs={8} md={8} className="m-auto text-right">
                {info}
              </Col>
              <Col xs={4} md={2} className="m-auto">
                <button onClick={sair} className="nav-sair">
                  | <i className="fas fa-sign-out-alt" /> Sair
                </button>
              </Col>
            </Row>
            <Row className="d-none d-md-flex w-100">
              <Nav />
            </Row>
          </>
        )}
      </Container>
    </header>
  );
}
