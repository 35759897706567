import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import EnviarPontuacao from '../EnviarPontuacao';
import './styles.css';

export default function SuperAcelerador() {
  return (
    <Container className='mb-5 mt-5'>
      <Row>
        <div className='videoAcelerador w-100 d-flex justify-content-center align-items-center mb-5'>
          <video controls>
            <source src='/videos/super-acelerador.mp4' type='video/mp4' />
            Desculpe, seu navegador não suporta esse tipo de vídeo.
          </video>
        </div>
      </Row>
      <Row className='d-flex justify-content-center'>
        <div className='enviarPontuacao'>
          <EnviarPontuacao className='enviaPontuacao' text='white' />
        </div>
      </Row>
    </Container>
  );
}
