import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import historico from '../../../services/Historico';

export default function AtivarPdvSucesso(props) {
  const handleAtivarOutroPdv = () => {
    props.setQtdGarconsCadastros(0);
    props.setPageAtivarPdv(0);
  };
  return (
    <Container fluid>
      <div className="d-flex flex-column align-items-center">
        <form className="form">
          <Row>
            <Col className="d-flex flex-column mb-5" xs={12} sm={12}>
              <h1 className="text-center font-weight-bold">
                PDV {props.dadosRevenda.nomeFantasia} ativo com sucesso.
              </h1>
            </Col>
            {props.dadosRevenda.programas !== '8' && (
              <>
                <Col className="d-flex flex-column mb-5" xs={12} sm={12}>
                  <h1 className="text-center font-weight-bold">
                    {props.qtdGarconsCadastrados}{' '}
                    {props.qtdGarconsCadastrados > 1
                      ? 'Garçons incluídos'
                      : 'Garçom incluído'}
                  </h1>
                </Col>

                <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
                  <button
                    className="btn-primario"
                    onClick={(e) =>
                      props.setPageAtivarPdv(props.pageAtivarPdv - 2)
                    }
                  >
                    Cadastrar outro Garçom
                  </button>
                </Col>
              </>
            )}

            <Col
              className="d-flex flex-column"
              xs={12}
              sm={12}
              lg={props.dadosRevenda.programas === '8' ? 12 : 6}
            >
              <button className="btn-decline" onClick={handleAtivarOutroPdv}>
                Ativar outro PDV
              </button>
            </Col>
          </Row>

          <button
            className="btn-invertido"
            onClick={(e) => historico.push('/painel')}
          >
            Voltar ao painel
          </button>
        </form>
      </div>
    </Container>
  );
}
