/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Card } from 'react-bootstrap';
import api from '../../../services/API';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import './styles.css';
import ModalMidia from '../ModalMidia';
import ModalExcluir from './ModalExcluir';

export default function Concurso() {
  const [mostrarModalMidia, setMostrarModalMidia] = useState(false);
  const [arquivo, setArquivo] = useState('');
  const [tipo, setTipo] = useState('');

  const [programas, setProgramas] = useState([]);
  const [idPrograma, setIdPrograma] = useState('');

  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [id, setId] = useState('');
  const [mostrarModalExcluir, setMostrarModalExcluir] = useState(false);

  useEffect(() => {
    buscarMidias();
    carregarProgramas();
  }, []);

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const carregarProgramas = async () => {
    const resultado = await api.get('/api/programas?ativo=1');
    if (resultado) {
      setProgramas(resultado.data.programas);
    }
  };

  const buscarMidias = async () => {
    setBuscando(true);
    const resultado = await api.get(`/api/concurso?idPrograma=${idPrograma}`);
    if (resultado) {
      setData(resultado.data.concurso);
    }
    setBuscando(false);
  };

  const fecharModalMidia = () => {
    setMostrarModalMidia(false);
  };

  const abrirModalExcluir = (id) => {
    setId(id);
    setMostrarModalExcluir(true);
  };

  const fecharModalExcluir = () => {
    setId('');
    setMostrarModalExcluir(false);
  };

  const atualizarExclusao = (id) => {
    setData(data.filter((d) => d.id !== id));
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Concurso Fotos </h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={6} lg={3} className="d-flex flex-column">
          <label htmlFor="programa">Selecione o programa</label>
          <select
            className="input-select mb-3"
            id="programa"
            name="programa"
            value={idPrograma}
            onChange={(e) => setIdPrograma(e.target.value)}
          >
            <option value="">Todas</option>
            {programas.map((programa) => (
              <option key={programa.nome} value={programa.id}>
                {programa.nome}
              </option>
            ))}
          </select>
        </Col>

        <Col
          xs={12}
          sm={12}
          md={1}
          className="d-flex flex-column justify-content-center align-items-center d-md-block"
        >
          <button className="btn-busca" type="submit" onClick={buscarMidias}>
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <Row>
        {data.length === 0 && (
          <Col xs={12}>
            <Card className="border-0 p-3">
              {buscando ? (
                <span>
                  Carregando
                  <i className="fas fa-circle-notch fa-spin ml-3"></i>
                </span>
              ) : (
                <span>Sem resultados</span>
              )}
            </Card>
          </Col>
        )}
        {data.length > 0 &&
          data.map((midia, idx) => (
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={3}
              className="d-flex flex-column text-center"
              key={idx}
            >
              <div className="card-midia">
                <div className="card-imagem">
                  <button
                    className="btn-limpo w-100 h-100"
                    onClick={(e) => {
                      setArquivo(midia.url);
                      setMostrarModalMidia(true);
                    }}
                  >
                    <img
                      alt="Foto"
                      src={`${process.env.REACT_APP_API_BASE_URL}/arquivos/${midia.url}`}
                    ></img>
                  </button>
                </div>
                <div className="card-infos mb-1" style={{ height: 'auto' }}>
                  <span className="card-midia-titulo">{`${midia.nome}`}</span>
                  <span className="card-midia-subtitulo">{`${midia.endCidade} - ${midia.endEstado}`}</span>
                </div>
                <div className="card-links">
                  <button
                    className="btn-limpo"
                    onClick={(e) => abrirModalExcluir(midia.id)}
                  >
                    <span className="fa-stack fa-lg f-red">
                      <i className="fa fa-circle fa-stack-2x"></i>
                      <i className="fa fa-trash fa-stack-1x fa-inverse"></i>
                    </span>
                  </button>
                </div>
              </div>
            </Col>
          ))}
      </Row>
      <ModalMidia
        show={mostrarModalMidia}
        onHide={fecharModalMidia}
        diretorioArquivo={arquivo}
        tipo={tipo}
      />
      <ModalExcluir
        show={mostrarModalExcluir}
        onHide={fecharModalExcluir}
        id={id}
        atualizarExclusao={atualizarExclusao}
      />
    </Container>
  );
}
