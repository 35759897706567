/*  eslint-disable-next-line */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form } from '@rocketseat/unform';
import api from '../../../services/API';
import './style.css';
import { toast } from 'react-toastify';

export default function Participantes({
  clicarBotaoAvancar,
  setParticipantes,
}) {
  const [participantesSelect, setParticipantesSelect] = useState([]);
  const [participantesSelecionados, setParticipantesSelecionados] = useState(
    []
  );

  useEffect(() => {
    setParticipantes(participantesSelecionados);
  }, [participantesSelecionados]);

  useEffect(() => {
    buscarParticipantes();
  }, []);

  const buscarParticipantes = async () => {
    const resultado = await api.get('/api/participantes?ativo=1');
    if (resultado && resultado.data.participantes) {
      const participantes = resultado.data.participantes.map((participante) => {
        participante.valorBase = 0;
        return participante;
      });
      setParticipantesSelect(participantes);
    }
  };

  const onClickCheckbox = (e) => {
    const { id, checked } = e.currentTarget;

    if (checked) {
      const participante = participantesSelect.filter((p) => p.nome === id);
      setParticipantesSelecionados([
        ...participantesSelecionados,
        participante[0],
      ]);
    } else {
      const filtrados = participantesSelecionados.filter((p) => p.nome !== id);
      setParticipantesSelecionados(filtrados);
    }
  };

  const onChangeValorBase = (e) => {
    const { id, value } = e.currentTarget;
    const idClean = id.replace('-valor-base', '');

    if (Number(value) < 0) {
      toast.error(`O valor base não pode ser negativo`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    const participantesAtualizados = participantesSelect.map((participante) => {
      if (participante.nome === idClean) {
        participante.valorBase = Number(value);
      }
      return participante;
    });

    const participantesSelecionadosAtualizados = participantesSelecionados.map(
      (participante) => {
        if (participante.nome === idClean) {
          participante.valorBase = Number(value);
        }
        return participante;
      }
    );

    setParticipantesSelect(participantesAtualizados);
    setParticipantesSelecionados(participantesSelecionadosAtualizados);
  };

  return (
    <Container fluid>
      <Form onSubmit="">
        <Row className="options-criar-regra">
          <Col xs={12} md={12} className="d-flex flex-column">
            <div className="pb-3">
              <h5>Participantes</h5>
            </div>
            <div className="d-flex flex-column align-items-start space-between-center">
              {participantesSelect.map((participante) => (
                <div key={participante.nome} className="item-participante">
                  <div>
                    <input
                      type="checkbox"
                      id={participante.nome}
                      name={participante.nome}
                      onChange={onClickCheckbox}
                    />
                    <label htmlFor={participante.nome} className="pl-2">
                      {participante.nome}
                    </label>
                  </div>

                  <div className="ml-3">
                    <input
                      type="number"
                      id={`${participante.nome}-valor-base`}
                      name={`${participante.nome}-valor-base`}
                      min="0"
                      onChange={onChangeValorBase}
                    />
                    <label
                      htmlFor={`${participante.nome}-valor-base`}
                      className="pl-2"
                    >
                      VALOR BASE
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </Col>
          <Col xs={12} md={12} className="d-flex justify-content-end pt-4">
            <button type="button" onClick={clicarBotaoAvancar}>
              Avançar &gt;&gt;
            </button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
