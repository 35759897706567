import momentTz from 'moment-timezone';

export const moment = (date) => {
  momentTz.locale('pt-br');
  momentTz.updateLocale('pt-br', {
    months: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
  });

  return momentTz(date);
};
