/*  eslint-disable-next-line */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState, useEffect } from 'react';
import { assign } from 'lodash';

import api from '../services/API';

const initialFetchState = {
  data: [],
  isFetching: false,
  error: false,
  // updatedAt: null,
};

export const useFetch = (endPoint, method, body = {}) => {
  const [state, setState] = useState(initialFetchState);

  const handleState = (newState) =>
    setState((prev) => assign({}, prev, newState));

  const [params, setParams] = useState(null);

  useEffect(() => {
    if (params) {
      handleState({ isFetching: true });
      api({
        method: method,
        url: endPoint,
        data: body,
      })
        .then(({ data }) => {
          handleState({
            data,
            isFetching: false,
            error: false,
            // updatedAt: Date.now(),
          });
        })
        .catch((error) => {
          handleState({
            error,
            isFetching: false,
            // updatedAt: Date.now(),
          });
        });
    }
  }, [params]);

  const clear = useCallback(() => {
    setState({ ...initialFetchState });
  }, []);

  return [state, setParams, clear];
};
