import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';

export default function ProcessarPontuacao() {
  const [atualizando, setAtualizando] = useState(false);

  const processar = async (e) => {
    e.preventDefault();

    setAtualizando(true);
    const resultado = await api.post('/api/pontuacoes/processar');

    if (resultado) {
      toast.success(`Pontuação processada com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }

    setAtualizando(false);
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Processar Pontuação</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={processar}>
          {atualizando ? (
            <button disabled className="btn-primario" type="submit">
              PROCESSANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              PROCESSAR
            </button>
          )}
          <div id="erros" className="d-none"></div>
        </form>
      </div>
    </Container>
  );
}
