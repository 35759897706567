import React, { useState } from 'react';
import moment from 'moment-timezone';
import XLSX from 'xlsx';

export const CsvComponent = (props) => {
  const [gerando, setGerando] = useState(false);
  const handleClick = async (e) => {
    e.preventDefault();

    if (props.data.length === 0) return;
    setGerando(true);
    let data = [];
    if (props.getData) {
      data = await props.getData();
    } else {
      data = [...props.data];
    }

    const columns = [...props.columns].filter((c) => {
      if (c.isDummyField !== undefined && c.isDummyField === true) {
        return false;
      }
      if (c.hidden !== undefined && c.hidden === true) {
        return false;
      }
      if (c.dataField === 'id' && c.hidden === true) {
        return false;
      }
      return true;
    });

    const csvData = [];
    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const csvRow = {};
      for (const column of columns) {
        if (column.csvFormatter !== undefined) {
          csvRow[column.dataField] = column.csvFormatter(
            column.dataField,
            row,
            i,
            null
          );
        } else if (column.formatter !== undefined) {
          csvRow[column.dataField] = column.formatter(
            column.dataField,
            row,
            i,
            null
          );
        } else if (
          row[column.dataField] === null ||
          row[column.dataField] === undefined
        ) {
          csvRow[column.dataField] = '';
        } else {
          csvRow[column.dataField] = row[column.dataField];
        }
      }
      csvData.push(csvRow);
    }

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [[...columns.map((c) => c.text)]]);
    XLSX.utils.sheet_add_json(ws, csvData, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      `relatorio-${moment().format('YYYYMMDDHHMMSS')}`
    );
    XLSX.writeFile(wb, `relatorio-${moment().format('YYYYMMDDHHMMSS')}.xlsx`);
    setGerando(false);
  };

  if (gerando)
    return (
      <button className="btn-exportar">
        <i className="fas fa-circle-notch fa-spin"></i>
      </button>
    );

  return (
    <button className="btn-exportar" type="submit" onClick={handleClick}>
      <i className="fas fa-file"></i>
    </button>
  );
};
