import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Regulamento from '../Regulamento/index';
import './styles.css';
import { aceitarRegulamento as aceitarRegulamentoPaceiro } from '../../../store/modules/parceiro/actions';
import { aceitarRegulamento as aceitarRegulamentoProprietario } from '../../../store/modules/proprietario/actions';
import {
  desautenticarParceiro,
  desautenticarProprietario,
} from '../../../store/modules/autenticacao/actions';
import api from '../../../services/API';

export default function ModalVideoBemVindo(props) {
  return (
    <Modal
      show={props.showModalVideo}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      backdrop="static"
      onHide={() => props.setShowModalVideo(false)}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <video width="100%" controls autoPlay>
          <source
            src="videos/cerveja_premiada_video_bem_vindo.mp4"
            type="video/mp4"
          />
          Seu navegador não suporta este tipo de
        </video>
      </Modal.Body>
    </Modal>
  );
}
