import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { autenticarUsuario } from '../../../store/modules/autenticacao/actions';

export default function Login() {
  const [cpf, setCpf] = useState('');
  const [senha, setSenha] = useState('');
  const [temporadaSelecionada, setTemporadaSelecionada] = useState(0);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const dispatch = useDispatch();
  const enviando = useSelector(
    (state) => state.autenticacao.autenticandoPainel
  );
  useEffect(() => {
    document.title = 'Cerveja Premiada - Login';
  }, []);

  const solicitarAutenticacao = (e) => {
    e.preventDefault();
    dispatch(
      autenticarUsuario(cpf.replace(/[^\d]/g, ''), senha, temporadaSelecionada)
    );
  };

  const handleSelectSeason = (showLogin, season) => {
    setTemporadaSelecionada(season);
    setShowLoginForm(showLogin);
  };

  return (
    <div className="d-flex flex-column justify-content-center bg-black bg-full">
      <Container fluid>
        <Row className="flex-column align-items-center justify-content-center mt-5">
          <img
            className="w-100 form-login-logo"
            src="/imagens/logo.png"
            alt="Logo Cerveja Premiada"
          ></img>
        </Row>
        {!showLoginForm && (
          <>
            <Row className="justify-content-center p-5 bg-black">
              <div className="btn-home">
                <button
                  className="btn-primario"
                  onClick={(e) => handleSelectSeason(true, 1)}
                >
                  TEMPORADA <span>2021</span>
                </button>
              </div>
            </Row>
            <Row className="justify-content-center p-5 bg-black">
              <div className="btn-home">
                <button
                  className="btn-primario"
                  onClick={(e) => handleSelectSeason(true, 2)}
                >
                  TEMPORADA <span>2022</span>
                </button>
              </div>
            </Row>
          </>
        )}

        {showLoginForm && (
          <>
            <Row className="justify-content-center p-4 bg-black">
              <form className="form-login" onSubmit={solicitarAutenticacao}>
                <h5>FAÇA SEU LOGIN</h5>
                <label>Entre com seus dados e acesse sua conta</label>
                <label htmlFor="cpf">CPF</label>
                <input
                  name="cpf"
                  type="text"
                  placeholder="CPF"
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                ></input>
                <label htmlFor="senha">Senha</label>
                <input
                  name="senha"
                  type="password"
                  placeholder="Senha"
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                ></input>{' '}
                {enviando ? (
                  <button disabled className="btn-primario" type="submit">
                    VALIDANDO...
                  </button>
                ) : (
                  <button className="btn-primario" type="submit">
                    ENTRAR
                  </button>
                )}
              </form>
            </Row>{' '}
            <Row className="justify-content-center p-4 bg-black">
              <div className="btn-voltar">
                <button
                  className="btn-primario"
                  onClick={(e) => setShowLoginForm(false)}
                >
                  Selecionar outra temporada
                </button>
              </div>
            </Row>{' '}
            <Row className="d-flex justify-content-center solicitarAcesso bg-black">
              <p>
                <strong>
                  Ainda não tem acesso? <a href="/solicitacao">Solicite aqui</a>
                </strong>
              </p>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
}
