import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import axios from 'axios';
import InputMask from 'react-input-mask';
import historico from '../../../services/Historico';
import { sortBy } from 'lodash';
import validarCpf from '../../../utils/validarCpf';
import { useSelector } from 'react-redux';

export default function CadastrarParceiro() {
  const atualizando = useSelector((state) => state.usuario.atualizando);

  const usuario = useSelector((state) => {
    return state.usuario;
  });

  const [enviando, setEnviando] = useState(false);
  const [cpf, setCpf] = useState('');
  const [idRevenda, setIdRevenda] = useState('');
  const [nome, setNome] = useState('');
  const [sexo, setSexo] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [telefone, setTelefone] = useState('');
  const [celular, setCelular] = useState('');
  const [endRua, setRua] = useState('');
  const [endNumero, setNumero] = useState('');
  const [endComplemento, setComplemento] = useState('');
  const [endBairro, setBairro] = useState('');
  const [endCidade, setCidade] = useState('');
  const [endEstado, setEstado] = useState('');
  const [endCep, setCep] = useState('');
  const [idTemporada, setIdTemporada] = useState('');
  const [revendasSelect, setRevendasSelect] = useState([]);

  const validarCampos = (data) => {
    const entries = Object.entries(data);
    let temNulo = false;
    entries.forEach((param) => {
      if (temNulo) return;
      if (param[1] === '' && param[0] === 'sexo') {
        toast.error(`Sexo obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
      if (
        param[1] === '' &&
        param[0] !== 'endComplemento' &&
        param[0] !== 'telefone' &&
        param[0] !== 'email'
      ) {
        const elemento = document.getElementsByName(param[0])[0];
        toast.error(`${elemento.placeholder} obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
    });

    return temNulo;
  };

  const buscarCep = async (element) => {
    let input = element.target;
    let cep = input.value.replace(/[^\d]/g, '');
    if (!cep) return;
    const resultado = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
    if (!resultado || resultado.data.erro) {
      toast.error('CEP inválido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCep('');
    } else {
      setRua(resultado.data.logradouro);
      setBairro(resultado.data.bairro);
      setCidade(resultado.data.localidade);
      setEstado(resultado.data.uf);
      setComplemento(resultado.data.complemento);
    }
  };

  const validarTelefoneCelular = async (e) => {
    const valor = e.target.value.replace(/[^\d]/g, '');
    if (!valor) return;
    if (valor.length < 10) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      e.target.placeholder === 'Telefone' ? setTelefone('') : setCelular('');
    }
  };

  const validaCpf = async (e) => {
    const valor = e.target.value.replace(/[^\d]/g, '');
    if (validarCpf(valor)) return;

    toast.error(`${e.target.placeholder} inválido`, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
    });
    setCpf('');
  };

  const validarDataNascimento = async (e) => {
    const data = e.target.value.replace(/[^\d]/g, '');
    if (!data) return;
    if (data.length < 8) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setDataNascimento('');
    }
  };

  useEffect(() => {
    buscarRevendas();
  }, []);

  const buscarRevendas = async () => {
    const resultado = await api.get(
      `/api/revendas?filtros=${
        usuario.idTemporada === 1
          ? '{%22programas%22:%222 or r.programas = 3%22}'
          : '{%22programas%22:%226 or r.programas = 7%22}'
      }`
    );
    if (resultado && resultado.data.revendas) {
      setRevendasSelect(resultado.data.revendas);
    }
  };

  const cadastrarParceiro = async (e) => {
    e.preventDefault();
    const data = {
      idRevenda,
      nome,
      cpf,
      telefone,
      celular,
      email,
      senha,
      dataNascimento,
      sexo,
      endRua,
      endNumero,
      endComplemento,
      endBairro,
      endCidade,
      endEstado,
      endCep,
      idTemporada: Number(idTemporada),
    };

    const temNulo = validarCampos(data);
    if (temNulo) {
      return;
    }

    data.endCep = data.endCep.replace(/[^\d]/g, '');
    data.telefone = data.telefone.replace(/[^\d]/g, '');
    data.celular = data.celular.replace(/[^\d]/g, '');
    data.cpf = data.cpf.replace(/[^\d]/g, '');
    data.dataNascimento = data.dataNascimento.replace(/[^\d]/g, '');
    data.dataNascimento = `${data.dataNascimento.substring(
      4
    )}${data.dataNascimento.substring(2, 4)}${data.dataNascimento.substring(
      0,
      2
    )}`;

    setEnviando(true);
    const resultado = await api.post('/api/parceiros', data);

    if (resultado) {
      toast.success('Garçom cadastrado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      historico.push('/painel/cadastrar/parceiro');
    }
    setEnviando(false);
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Garçom</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={cadastrarParceiro}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="d-flex flex-column">
              <label htmlFor="pdvSelecionado">Selecione um PDV</label>
              <select
                className="input-select mb-3"
                name="pdvSelecionado"
                id="pdvSelecionado"
                defaultValue="Todos"
                placeholder="Todos"
                value={idRevenda}
                onChange={(e) => setIdRevenda(e.target.value)}
              >
                <option disabled value="">
                  Selecione o PDV
                </option>
                {sortBy(revendasSelect, 'nomeFantasia').map((revenda) => (
                  <option value={revenda.id}>
                    {' '}
                    {revenda.codigo} - {revenda.nomeFantasia}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={12}>
              <label htmlFor="nome">Nome</label>
              <input
                id="nome"
                name="nome"
                type="text"
                placeholder="Nome e Sobrenome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="cpf">CPF</label>
              <InputMask
                id="cpf"
                name="cpf"
                type="text"
                placeholder="CPF"
                mask="999.999.999-99"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                onBlur={validaCpf}
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="celular">Celular</label>
              <InputMask
                id="celular"
                name="celular"
                type="text"
                placeholder="Celular"
                value={celular}
                mask="(99) 99999-9999"
                onChange={(e) => setCelular(e.target.value)}
                onBlur={validarTelefoneCelular}
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12}>
              <label htmlFor="nome">Email</label>
              <input
                id="email"
                name="email"
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="dataNascimento">Data de Nascimento</label>
              <InputMask
                id="dataNascimento"
                name="dataNascimento"
                type="text"
                placeholder="Data de Nascimento"
                value={dataNascimento}
                onBlur={validarDataNascimento}
                onChange={(e) => setDataNascimento(e.target.value)}
                mask="99/99/9999"
              ></InputMask>
            </Col>

            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endCep">CEP</label>
              <InputMask
                id="endCep"
                name="endCep"
                type="text"
                placeholder="CEP"
                mask="99999-999"
                onBlur={buscarCep}
                value={endCep}
                onChange={(e) => setCep(e.target.value)}
              ></InputMask>
            </Col>
          </Row>

          <Row>
            <Col className="d-flex flex-column" xs={12} sm={9}>
              <label htmlFor="endRua">Endereço</label>
              <input
                id="endRua"
                name="endRua"
                type="text"
                placeholder="Endereço"
                value={endRua}
                onChange={(e) => setRua(e.target.value)}
              ></input>
            </Col>

            <Col className="d-flex flex-column" xs={12} sm={3}>
              <label htmlFor="endNumero">Número</label>
              <input
                id="endNumero"
                name="endNumero"
                type="text"
                placeholder="Número"
                value={endNumero}
                onChange={(e) => setNumero(e.target.value)}
              ></input>
            </Col>
          </Row>

          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endBairro">Bairro</label>
              <input
                id="endBairro"
                name="endBairro"
                type="text"
                placeholder="Bairro"
                value={endBairro}
                onChange={(e) => setBairro(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endComplemento">Complemento</label>
              <input
                id="endComplemento"
                name="endComplemento"
                type="text"
                placeholder="Complemento"
                value={endComplemento}
                onChange={(e) => setComplemento(e.target.value)}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endCidade">Cidade</label>
              <input
                id="endCidade"
                name="endCidade"
                type="text"
                placeholder="Cidade"
                value={endCidade}
                onChange={(e) => setCidade(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endEstado">Estado</label>
              <input
                id="endEstado"
                name="endEstado"
                type="text"
                placeholder="Estado"
                value={endEstado}
                onChange={(e) => setEstado(e.target.value)}
                maxLength={2}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="pdvSelecionado">Sexo</label>
              <select
                className="input-select mb-3"
                name="sexo"
                id="sexo"
                defaultValue=""
                placeholder="Selecione"
                value={sexo}
                onChange={(e) => setSexo(e.target.value)}
              >
                <option disabled value="">
                  Selecione
                </option>

                <option value="M">Masculino</option>
                <option value="F">Feminino</option>
              </select>
            </Col>

            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="nome">Senha</label>
              <input
                id="senha"
                name="senha"
                type="password"
                placeholder="Senha"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12}>
              <label htmlFor="pdvSelecionado">Temporada</label>
              <select
                className="input-select mb-3"
                name="temporada"
                id="temporada"
                defaultValue=""
                placeholder="Selecione"
                value={idTemporada}
                onChange={(e) => setIdTemporada(e.target.value)}
              >
                <option disabled value="">
                  Selecione
                </option>

                <option value="1">Temporada 2021</option>
                <option value="2">Temporada 2022</option>
              </select>
            </Col>
          </Row>

          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              CADASTRAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
