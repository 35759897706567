import React from 'react';
import { Table, Modal } from 'react-bootstrap';

export default function ModalProdutosPesquisa(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Produtos</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-2">
        {/* <div className="dash-table-titulo rounded-0">
          <span className="pl-3">Produtos mais vendidos na loja</span>
        </div> */}
        <Table className="dash-table" responsive variant="dark">
          <thead className="dash-table-header">
            <tr>
              <th>Nome</th>
              <th>Qtde</th>
            </tr>
          </thead>
          <tbody className="dash-table-body">
            {props.produtos.map((produto) => (
              <tr key={produto.nome}>
                <td>{produto.nome}</td>
                <td>{Number(produto.qtde).toLocaleString('pt-BR')}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}
