import React, { useEffect, useState } from 'react';
import { Modal, Col, Row } from 'react-bootstrap';
import api from '../../../services/API';
import { toast } from 'react-toastify';

export default function ModalEntrega({ open, onClose, resgate }) {
  const [alterando, setAlterando] = useState(false);
  const [cancelar, setCancelar] = useState(false);
  const [date, setDate] = useState();

  useEffect(() => {
    setDate(resgate.prazoEntrega);
  }, [resgate]);

  const handleCancelar = () => {
    setCancelar(!cancelar);
  };

  const alterarEntrega = async () => {
    setAlterando(true);
    const resultado = await api.put(`/api/data-resgate`, {
      id: resgate.id,
      date,
    });

    if (resultado) {
      toast.success(`Resgate atualizado com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      onClose({ identificador: resgate.id, date });
    }
    setAlterando(false);
  };

  const cancelarEntrega = async () => {
    setAlterando(true);
    const resultado = await api.post(`/api/cancelarResgate`, {
      identificador: resgate.id,
      status: 4,
    });

    if (resultado) {
      toast.success(`Resgate cancelado com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      onClose();
    }
    setAlterando(false);
  };

  return (
    <Modal
      show={open}
      onHide={onClose}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Nova entrega em: </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        {cancelar ? (
          <div className="d-flex justify-content-center align-items-center text-center w-100 p-4">
            <span>Tem certeza que deseja cancelar a entrega?</span>
          </div>
        ) : (
          <form className="form mw-100 mt-4 d-flex justify-content-center align-items-center">
            <Row>
              <Col className="d-flex flex-column" xs={12}>
                <label htmlFor="data">Data</label>
                <input
                  id="data"
                  name="data"
                  type="date"
                  placeholder="Data"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                ></input>
              </Col>
            </Row>
          </form>
        )}
      </Modal.Body>
      <Modal.Footer>
        {cancelar ? (
          <>
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={cancelarEntrega}
            >
              SIM
            </button>{' '}
            <button
              type="button"
              className="btn btn-danger m-1"
              onClick={handleCancelar}
            >
              NÂO
            </button>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-center align-items-center w-100">
              {alterando ? (
                <button type="button" className="btn btn-success m-1" disabled>
                  ALTERANDO...
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-success m-1"
                  onClick={alterarEntrega}
                >
                  ALTERAR
                </button>
              )}
              <button
                type="button"
                className="btn btn-danger m-1"
                onClick={onClose}
              >
                CANCELAR
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center w-100">
              <button
                type="button"
                className="btn btn-danger m-1"
                onClick={handleCancelar}
              >
                CANCELAR RESGATE POR FALHA NA ENTREGA
              </button>
            </div>{' '}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}
