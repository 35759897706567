import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import { useEffect } from 'react';

export default function UploadMidia() {
  const [enviando, setEnviando] = useState(false);
  const [arquivo, setArquivo] = useState(false);
  const [status, setStatus] = useState('Procurar...');
  const [idResgate, setIdResgate] = useState('');
  const [detalhes, setDetalhes] = useState('');

  const uploadMidia = async (e) => {
    e.preventDefault();

    if (!arquivo) {
      toast.error(`Selecione um arquivo primeiro`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!detalhes) {
      toast.error(`Informe o ID do resgate primeiro`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    let formData = new FormData();
    formData.append('arquivo', arquivo);
    formData.append('idResgate', idResgate);
    setEnviando(true);
    const resultado = await api.post('/api/upload/foto_video', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (resultado) {
      toast.success('Mídia anexada com sucesso ao resgate', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setArquivo(null);
      setStatus('Procurar...');
      setDetalhes(null);
      setIdResgate(null);
    }
    setEnviando(false);
  };

  useEffect(() => {
    if (!arquivo) {
      document.getElementById('arquivo').value = null;
    }
  }, [arquivo]);

  const buscarResgate = async () => {
    const resultado = await api.get(`/api/resgates/${idResgate}`);

    if (resultado) {
      if (resultado.data.resgate) {
        setDetalhes(resultado.data.resgate);
      }
    }
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Upload Foto ou Vídeo</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={uploadMidia}>
          <label htmlFor="idResgate">Informe o ID do resgate</label>
          <input
            type="text"
            id="idResgate"
            name="idResgate"
            onChange={(e) => setIdResgate(e.target.value)}
            placeholder="ID do resgate"
            value={idResgate}
            onBlur={buscarResgate}
          />
          <label htmlFor="arquivo">Arquivo de foto ou vídeo</label>
          <input
            type="file"
            id="arquivo"
            name="arquivo"
            style={{ display: 'none' }}
            onChange={(e) => {
              setArquivo(e.target.files[0]);
              e.target.files[0]
                ? setStatus(e.target.files[0].name)
                : setStatus('Procurar...');
            }}
          />
          <input
            id="botao"
            type="button"
            value={status}
            onClick={(e) => {
              document.getElementById('arquivo').click();
            }}
          />
          {detalhes ? (
            <>
              <Row>
                <Col className="d-flex flex-column mb-3 text-center">
                  <span>
                    <b>PDV </b>
                    {detalhes.revenda}
                  </span>
                  <span>
                    <b>Parceiro </b>
                    {detalhes.parceiro}
                  </span>
                  <span>
                    <b>Resgate </b>
                    {Number(detalhes.qtde).toLocaleString('pt-BR')}x{' '}
                    {detalhes.premio}
                  </span>
                  <span></span>
                </Col>
              </Row>
              <button className="btn-primario" type="submit">
                {enviando ? 'ENVIANDO...' : 'ENVIAR'}
              </button>
            </>
          ) : (
            <button disabled className="btn-primario" type="submit">
              {enviando ? 'ENVIANDO...' : 'ENVIAR'}
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
