import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form } from '@rocketseat/unform';
import './style.css';
import { toast } from 'react-toastify';

export default function Regra({
  clicarBotaoSalvar,
  participantes,
  produtos,
  idPrograma,
  idTemporada,
}) {
  const [enviando, setEnviando] = useState(false);

  async function salvarRegra() {
    if (!idPrograma) {
      toast.error(`Selecione um programa`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    const regras = [];
    let invalid = false;
    for (const produto of produtos) {
      const produtoTotalInput = Number(
        document.getElementsByName(`produto-total-${produto.id}`)[0].value || 0
      );
      let totalParticipantes = 0;

      for (const participante of participantes) {
        const participanteTotalInput = Number(
          document.getElementsByName(
            `produto-${participante.id}-${produto.id}`
          )[0].value || 0
        );

        if (participanteTotalInput < 0) {
          invalid = true;
          toast.error(`O valor da tampa não pode ser negativo`, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 10000,
          });
          break;
        }

        totalParticipantes += participanteTotalInput;

        regras.push({
          idPrograma: idPrograma,

          idProduto: produto.id,
          idTemporada: Number(idTemporada),
          idParticipante: participante.id,
          valorTampa: participanteTotalInput,
          valorTotalTampa: produtoTotalInput,
          valorBase: participante.valorBase,
        });
      }

      if (invalid) {
        return;
      }

      if (totalParticipantes.toFixed(2) !== produtoTotalInput.toFixed(2)) {
        invalid = true;
        toast.error(
          `A soma dos valores das tampas para ${produto.nome} deve ser ${Number(
            produtoTotalInput
          ).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 10000,
          }
        );
        break;
      }
    }

    if (invalid) {
      return;
    }

    setEnviando(true);
    await clicarBotaoSalvar(regras);
    setEnviando(false);
  }
  return (
    <Container fluid>
      <Form className="options-criar-regra">
        <Row>
          <Col xs={12} md={12} lg={12} className="d-flex flex-column">
            <div className="pb-3">
              <h5>Regra</h5>
            </div>
          </Col>
        </Row>
        <Row className="pb-3">
          {produtos.map((produto) => (
            <Col xs={12} key={produto.id} className="mb-3">
              <Row>
                <Col xs={12} className="mb-1">
                  <span>{produto.nome} Total/Tampa</span>
                  <input
                    className="input-moeda ml-1"
                    type="number"
                    min="0"
                    name={`produto-total-${produto.id}`}
                  />
                </Col>
              </Row>
              <Row>
                {participantes.map((participante) => (
                  <Col xs={12} lg={6} key={participante.id}>
                    <div>
                      <input
                        className="input-moeda mr-1"
                        type="number"
                        min="0"
                        name={`produto-${participante.id}-${produto.id}`}
                      />
                      <span>{participante.nome.toUpperCase()}</span>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          ))}
        </Row>
        <Row>
          <Col xs={12} md={12} lg={6} className="pt-3">
            <button
              className="btn_save"
              type="button"
              onClick={salvarRegra}
              disabled={enviando}
            >
              {enviando ? 'SALVANDO' : 'SALVAR'}
            </button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
