import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Form, Input } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import api from '../../../services/API';

export default function CadastrarRede() {
  const [enviando, setEnviando] = useState(false);
  const cadastrarRede = async ({ nome, regiao, valorPonto }, { resetForm }) => {
    if (!nome) {
      toast.error('Informe o nome', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!regiao) {
      toast.error('Informe a região', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!valorPonto) {
      toast.error('Informe o valor do ponto', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    setEnviando(true);
    const resultado = await api.post('/api/redes', {
      nome,
      regiao,
      valorPonto,
    });

    if (resultado) {
      toast.success('Rede cadastrada com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      resetForm();
    }
    setEnviando(false);
  };
  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Segmento</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <Form className="form" onSubmit={cadastrarRede}>
          <label htmlFor="nome">Nome</label>
          <Input id="nome" name="nome" type="text" placeholder="Nome"></Input>
          <label htmlFor="regiao">Região</label>
          <Input
            id="regiao"
            name="regiao"
            type="text"
            placeholder="Região"
          ></Input>
          <label htmlFor="valorPonto">Valor Ponto</label>
          <Input
            id="valorPonto"
            name="valorPonto"
            type="number"
            step="0.01"
            placeholder="Valor Ponto"
          ></Input>
          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              CADASTRAR
            </button>
          )}
        </Form>
      </div>
    </Container>
  );
}
