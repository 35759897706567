import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles.css';

import EnviarPontuacao from '../EnviarPontuacao/index';

export default function UploadFoto() {
  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col>
          <EnviarPontuacao
            displayDetails="d-block"
            colorBtn="text-white bg-success"
            cname="bg-send-points-white"
            large={12}
          />
        </Col>
      </Row>
    </Container>
  );
}
