import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
// import hardSet from 'redux-persist/es/stateReconciler/hardSet';

export default (reducers) => {
  const persistedReducer = persistReducer(
    {
      key: 'cerveja_premiada',
      storage,
      whitelist: ['autenticacao', 'usuario', 'parceiro', 'proprietario'],
      // stateReconciler: hardSet,
    },
    reducers
  );
  return persistedReducer;
};
