/* eslint-disable react-hooks/exhaustive-deps */
// import ModalItensPontuacao from '../ModalItensPontuacao';
// import ModalMidia from '../ModalMidia';

import React from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment-timezone';

import { Container } from 'react-bootstrap';

import sortFunc from '../../../utils/ordernarColunaInt';
import { useObject } from '../../../hooks/useObject';
import ModalImage from 'react-modal-image';

import { Filtros } from './Filtros';
import RemoteTable from '../RemoteTable/index';
import { getImage } from '../../../helpers/getImage.js';

export default function PontuacoesAnalise() {
  const { regiaoPermissao, programaPermissao } = useSelector(
    (state) => state.usuario
  );

  const formatarPontosRecebidos = (celula, valor) => {
    return valor?.pontosParcial
      ? Number(valor?.pontosParcial).toLocaleString('pt-BR')
      : '-';
  };
  const columns = [
    {
      dataField: 'nome',
      text: 'Nome',
      sort: true,
    },
    {
      dataField: 'codigo',
      text: 'Cod. PDV',
      sort: true,
    },
    {
      dataField: 'nomeFantasia',
      text: 'PDV',
      sort: true,
    },

    {
      dataField: 'regiao',
      text: 'Região',
      sort: true,
    },
    {
      dataField: 'endCidade',
      text: 'Cidade',
    },

    {
      dataField: 'previsaoPontos',
      text: 'Pontos',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.previsaoPontos).toFixed(0);
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'previsaoPontosRevenda',
      text: 'Pontos PDV',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.previsaoPontosRevenda).toFixed(0);
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'previsaoPontos',
      text: 'Pontos parcial',
      formatter: formatarPontosRecebidos,
      sort: true,
    },
    {
      text: 'Data da Pontuação',
      sort: true,
      dataField: 'realizadoEm',
      formatter: (celula, valor) => {
        return moment(valor.realizadoEm).format('DD/MM/YYYY');
      },
    },
    {
      dataField: 'temporada',
      text: 'Temporada',
      formatter: (celula, valor) => valor.temporada.split(' ', 2)[1],
      sort: true,
    },
    {
      isDummyField: true,
      text: 'Foto ',
      dataField: 'diretorioArquivo',
      formatter: (celula, valor) => {
        return (
          <ModalImage
            className={'small-img'}
            small={'/imagens/eye.jpg'}
            large={getImage(valor.diretorioArquivo)}
            alt=""
          />
        );
      },
    },
  ];

  const [filtros, setFiltro] = useObject({
    dataInicial: moment().subtract(7, 'days').toISOString().substr(0, 10),
    dataFinal: moment().subtract().toISOString().substr(0, 10),
    programa: programaPermissao ?? '',
    regiao: regiaoPermissao ? regiaoPermissao : '',
  });

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Pontuações em Análise</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <RemoteTable
          dataId="id"
          url={`/api/pontuacoes/emAnalise`}
          colunas={columns}
          ordenacaoInicial={{ campo: 'realizadoEm', direcao: 'desc' }}
          filtros={filtros}
          filtrosRow={<Filtros setFiltro={setFiltro} filtros={filtros} />}
        />
      </div>
    </Container>
  );
}
