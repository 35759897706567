import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form } from '@rocketseat/unform';
import Participantes from '../Participantes';
import Produto from '../Produto';
import Regra from '../Regra';
import api from '../../../services/API';
import { toast } from 'react-toastify';

export default function CadastrarRegra() {
  const [etapa, setEtapa] = useState(1);
  const [participantes, setParticipantes] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [idTemporada, setIdTemporada] = useState('');

  const [idPrograma, setIdPrograma] = useState('');
  const [programasSelect, setProgramasSelect] = useState([]);

  useEffect(() => {
    buscarProgramas();
  }, []);

  const zerarForm = () => {
    setEtapa(1);
    setParticipantes([]);
    setProdutos([]);
    setIdPrograma('');
    setIdTemporada('');

    Array.from(document.querySelectorAll('input[type=checkbox]')).forEach(
      (el) => (el.checked = false)
    );

    Array.from(document.querySelectorAll('input[type=number]')).forEach(
      (el) => (el.value = null)
    );
  };
  const buscarProgramas = async () => {
    const resultado = await api.get('/api/programas?ativo=1');
    if (resultado) {
      setProgramasSelect(resultado.data.programas);
    }
  };

  function etapa2() {
    if (participantes.length > 0) {
      setEtapa(2);
    }
  }

  function etapa3() {
    if (produtos.length > 0) {
      setEtapa(3);
    }
  }

  async function cadastrarRegra(regras) {
    const resultado = await api.post('/api/regras', { regras });

    if (resultado) {
      toast.success('Regra cadastrada com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
    zerarForm();
  }

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Regra</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <Form className="form" onSubmit="">
        <Row>
          <Col xs={12} md={6} className="d-flex flex-column">
            <label htmlFor="programa">Selecione um Programa</label>
            <select
              className="input-select mb-3"
              name="programa"
              id="programa"
              defaultValue="Todos"
              placeholder="Todos"
              value={idPrograma}
              onChange={(e) => setIdPrograma(e.target.value)}
            >
              <option disabled value="">
                Selecione o Programa
              </option>
              {programasSelect.map((programa) => (
                <option value={programa.id}>{programa.nome}</option>
              ))}
            </select>
          </Col>
          <Col xs={12} md={6} className="d-flex flex-column">
            <label htmlFor="temporada">Selecione a Temporada</label>
            <select
              className="input-select mb-3"
              name="temporada"
              id="temporada"
              value={idTemporada}
              onChange={(e) => setIdTemporada(e.target.value)}
            >
              <option disabled value="">
                Selecione a temporada
              </option>
              <option value="1">Temporada 2021</option>
              <option value="2">Temporada 2022</option>
            </select>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col xs={12} md={5}>
          <Participantes
            clicarBotaoAvancar={etapa2}
            setParticipantes={setParticipantes}
          />
        </Col>
        {etapa >= 2 && (
          <Col xs={12} md={3}>
            <Produto clicarBotaoAvancar={etapa3} setProdutos={setProdutos} />
          </Col>
        )}
        {etapa >= 3 && (
          <Col xs={12} md={4}>
            <Regra
              clicarBotaoSalvar={cadastrarRegra}
              participantes={participantes}
              produtos={produtos}
              idPrograma={idPrograma}
              idTemporada={idTemporada}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
}
