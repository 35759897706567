import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import axios from 'axios';
import historico from '../../../services/Historico';
import InputMask from 'react-input-mask';
import validarCpf from '../../../utils/validarCpf';
import Utils from '../../../utils/utils';

export default function AtivarPdvCadastrarGarcom(props) {
  const [enviando, setEnviando] = useState(false);
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [celular, setCelular] = useState('');
  const [endRua, setEndRua] = useState('');
  const [endComplemento, setEndComplemento] = useState('');
  const [endNumero, setEndNumero] = useState('');
  const [endCep, setEndCep] = useState('');
  const [endBairro, setEndBairro] = useState('');
  const [endEstado, setEndEstado] = useState('');
  const [endCidade, setEndCidade] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [sexo, setSexo] = useState('');
  const [senha, setSenha] = useState('1234');

  const buscarCep = async (element) => {
    let input = element.target;
    let cep = input.value.replace(/[^\d]/g, '');
    if (!cep) return;
    const resultado = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
    if (!resultado || resultado.data.erro) {
      toast.error('CEP inválido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setEndCep('');
    } else {
      setEndRua(resultado.data.logradouro);
      setEndBairro(resultado.data.bairro);
      setEndCidade(resultado.data.localidade);
      setEndEstado(resultado.data.uf);
      setEndComplemento(resultado.data.complemento);
    }
  };

  const validaCpf = async (e) => {
    const valor = e.target.value.replace(/[^\d]/g, '');
    if (validarCpf(valor)) return;

    toast.error(`CPF inválido`, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
    });
    setCpf('');
  };

  const validarDataNascimento = async (e) => {
    const data = e.target.value.replace(/[^\d]/g, '');
    if (!data) return;
    if (data.length < 8) {
      toast.error(`Data de nascimento inválida`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setDataNascimento('');
    }
  };

  const handleAvancarAtivarPdv = async (e) => {
    e.preventDefault();

    setEnviando(true);

    const garcom = {
      idRevenda: props.dadosRevenda.id,
      nome,
      email,
      cpf,
      celular,
      endRua,
      endBairro,
      endNumero,
      endCep,
      endComplemento,
      endEstado,
      endCidade,
      dataNascimento,
      sexo,
      senha,
      idTemporada: Number(props.dadosRevenda.idTemporada),
    };

    garcom.celular = garcom.celular.replace(/[^\d]/g, '');
    garcom.cpf = garcom.cpf.replace(/[^\d]/g, '');
    garcom.endCep = garcom.endCep.replace(/[^\d]/g, '');
    garcom.dataNascimento = garcom.dataNascimento.replace(/[^\d]/g, '');
    garcom.dataNascimento = `${garcom.dataNascimento.substring(
      4
    )}${garcom.dataNascimento.substring(2, 4)}${garcom.dataNascimento.substring(
      0,
      2
    )}`;

    const resultado = await api.post('/api/parceiros', garcom);

    if (resultado) {
      toast.success('Garçom cadastrado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      clearForm();
      props.setQtdGarconsCadastrados(props.qtdGarconsCadastrados + 1);
      setEnviando(false);
      props.setPageAtivarPdv(5);
    } else {
      setEnviando(false);
    }
  };

  const clearForm = () => {
    setNome('');
    setEmail('');
    setCpf('');
    setCelular('');
    setEndRua('');
    setEndComplemento('');
    setEndNumero('');
    setEndCep('');
    setEndBairro('');
    setEndEstado('');
    setEndCidade('');
    setDataNascimento('');
    setSexo('');
  };

  return (
    <Container fluid>
      <div className="d-flex flex-column align-items-center">
        <Row>
          <Col className="d-flex flex-column mb-5" xs={12} sm={12}>
            <h1 className="text-center font-weight-bold">
              Garçom, cadastre-se
            </h1>
          </Col>
          <Col className="d-flex flex-column mb-5" xs={12} sm={12}>
            <h2 className="text-center">
              Você está no PDV <b>“{props.dadosRevenda.nomeFantasia}”</b>,
              código <b>“{props.dadosRevenda.codigo}”</b> que participa do
              programa <b>“{props.dadosRevenda.nomePrograma}”</b>
            </h2>
          </Col>
        </Row>
        <form className="form">
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={12}>
              <label>Nome</label>
              <input
                id="nome"
                name="nome"
                type="text"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>CPF</label>
              <InputMask
                id="cpf"
                name="cpf"
                type="text"
                mask="999.999.999-99"
                onBlur={validaCpf}
                onChange={(e) => setCpf(e.target.value)}
                value={cpf}
              />
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>Celular</label>
              <InputMask
                id="celular"
                name="celular"
                type="text"
                mask="(99) 99999-9999"
                onChange={(e) => setCelular(e.target.value)}
                value={celular}
              />
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12}>
              <label>Email</label>
              <input
                id="email"
                name="email"
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>Data Nascimento</label>
              <InputMask
                id="dataNascimento"
                name="dataNascimento"
                type="text"
                mask="99/99/9999"
                onBlur={validarDataNascimento}
                onChange={(e) => setDataNascimento(e.target.value)}
                value={dataNascimento}
              />
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>CEP</label>
              <InputMask
                id="cep"
                name="cep"
                type="text"
                mask="99999-999"
                onBlur={buscarCep}
                onChange={(e) => setEndCep(e.target.value)}
                value={endCep}
              />
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={9}>
              <label>Endereço</label>
              <input
                id="endereco"
                name="endereco"
                type="text"
                onChange={(e) => setEndRua(e.target.value)}
                value={endRua}
              />
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={3}>
              <label>Número</label>
              <input
                id="numero"
                name="numero"
                type="text"
                onChange={(e) => setEndNumero(e.target.value)}
                value={endNumero}
              />
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>Bairro</label>
              <input
                id="bairro"
                name="bairro"
                type="text"
                onChange={(e) => setEndBairro(e.target.value)}
                value={endBairro}
              />
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>Complemento</label>
              <input
                id="complemento"
                name="complemento"
                type="text"
                onChange={(e) => setEndComplemento(e.target.value)}
                value={endComplemento}
              />
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>Cidade</label>
              <input
                id="cidade"
                name="cidade"
                type="text"
                onChange={(e) => setEndCidade(e.target.value)}
                value={endCidade}
              />
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>Estado</label>
              <input
                id="estado"
                name="estado"
                type="text"
                onChange={(e) => setEndEstado(e.target.value)}
                value={endEstado}
              />
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>Sexo</label>
              <select
                className="input-select mb-3"
                name="sexo"
                id="sexo"
                defaultValue=""
                placeholder="Selecione"
                value={sexo}
                onChange={(e) => setSexo(e.target.value)}
              >
                <option disabled value="">
                  Selecione
                </option>

                <option value="M">Masculino</option>
                <option value="F">Feminino</option>
              </select>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>Senha</label>
              <input
                disabled
                id="senha"
                name="senha"
                type="password"
                value={senha}
              />
            </Col>
          </Row>

          <Row>
            <Col className="d-flex flex-column" xs={12}>
              {' '}
              {enviando ? (
                <button disabled className="btn-primario" type="submit">
                  AVANÇANDO...
                </button>
              ) : (
                <button
                  className="btn-primario"
                  type="submit"
                  onClick={handleAvancarAtivarPdv}
                >
                  AVANÇAR
                </button>
              )}
            </Col>
            <Col className="d-flex flex-column" xs={12}>
              {' '}
              <button
                className="btn-invertido"
                onClick={(e) => historico.push('/painel')}
              >
                Voltar ao painel
              </button>
            </Col>
          </Row>
        </form>
      </div>
    </Container>
  );
}
