import React from 'react';
import { Container, Row } from 'react-bootstrap';

export default function Privacidade() {
  return (
    <Container className="mt-5 mb-5">
      <Row className="flex-column align-items-center justify-content-center mt-5">
        <img
          className="w-100 form-logo"
          src="/imagens/logo-fundo-escuro.png"
          alt="Logo Cerveja Premiada"
        ></img>
      </Row>
      <div class="text-justify">
        <div class="mt-5 mb-5">
          <h4 class="f-black text-center font-weight-bold mb-4">
            POLÍTICA DE PRIVACIDADE
          </h4>
          <p>
            Por favor, leia esta Política de Privacidade (“Política”)
            cuidadosamente para entender nossas políticas e práticas sobre seus
            Dados Pessoais (“Dados”) e como os trataremos.
          </p>
          <p>
            Esta Política se aplica a indivíduos que se cadastram no{' '}
            <b>Cerveja Premiada</b> (“você”).
          </p>
          <p>
            O presente Programa Cerveja Premiada é desenvolvido e realizado pela
            Smart Tecnologia em Vendas S/A, inscrita no CNPJ sob nº
            20.893.968/0001-00, denominada “Smart”, e promovido pela HNK BR
            INDUSTRIA DE BEBIDAS LTDA inscrita sob nº 50.221.019/0001-36, por si
            e suas filiais, e/ou CERVEJARIAS KAISER BRASIL S.A. inscrita sob nº
            19.900.000/0001-76 (“Grupo HEINEKEN”).
          </p>
          <p>
            Esta Política explica como seus Dados Pessoais são coletados, usados
            e divulgados pela Smart e/ou Grupo HEINEKEN (“Realizadora”).{' '}
          </p>
          <p>
            Ela também informa como você pode acessar e atualizar seus Dados
            Pessoais e tomar certas decisões sobre como seus Dados Pessoais são
            utilizados.
          </p>
        </div>
        <br />
        <div>
          <ol>
            <li class="font-weight-bold f-18 mb-3">
              <span>Como coletamos informações pessoais</span>
              <li class="mt-2">
                <p class="font-weight-normal f-16">
                  Quando você se cadastra no <b>Cerveja Premiada</b>, coletamos
                  Dados sobre você, para que você crie um login e senha (“Acesso
                  a Loja <b>Cerveja Premiada</b>”).
                </p>
                <p class="font-weight-normal f-16">
                  Durante o processo de cadastro, nós solicitamos Dados como seu
                  nome completo, endereço de e-mail, data de nascimento, gênero,
                  CPF, endereço, telefone fixo e/ou celular.
                </p>
              </li>
            </li>
            <li class="font-weight-bold f-18 mb-3">
              <span>Uso das informações pessoais</span>
              <li class="mt-2">
                <p class="font-weight-normal f-16">
                  Os dados coletados serão utilizados com a finalidade de
                  administração e operação do <b>Cerveja Premiada</b>, incluindo
                  o uso para os fins de:
                </p>
                <ul class="font-weight-normal f-16 ml-5">
                  <li>
                    Cadastro junto ao <b>Cerveja Premiada</b> e contato com os
                    membros cadastrados;;
                  </li>
                  <li>Acúmulo de PONTOS;</li>
                  <li>Entrega de premiações;</li>
                  <li>Otimização do catálogo de prêmios;</li>
                  <li>
                    Desenvolvimento de inteligência de mercado, parâmetros e
                    perfil de consumo, bem como outras atividades associadas ao
                    bom funcionamento e desenvolvimento do PROGRAMA;
                  </li>
                  <li>
                    Relatórios, estudos ou indicadores de mercado e sua
                    respectiva disponibilização única e exclusivamente a Nome do
                    Cliente.
                  </li>
                </ul>
              </li>
            </li>
            <li class="font-weight-bold f-18 mb-3">
              <span>Compartilhamento de informações pessoais</span>
              <li class="mt-2">
                <p class="font-weight-normal f-16">
                  A Smart não divulga, em hipótese alguma, as suas informações
                  pessoais a terceiro. Compartilhamos os seus dados pessoais
                  única e exclusivamente a <b>Cerveja Premiada</b>.
                </p>
              </li>
            </li>
            <li class="font-weight-bold f-18 mb-3">
              <span>Preservação de informações pessoais</span>
              <li class="mt-2">
                <p class="font-weight-normal f-16">
                  Não mantemos as suas informações pessoais por mais tempo do
                  que o necessário para os objetivos para os quais são
                  processadas. Se existirem limitações técnicas que impedirem a
                  exclusão ou anonimização, protegemos as informações pessoais e
                  limitamos o uso ativo dessas informações.
                </p>
              </li>
            </li>
            <li class="font-weight-bold f-18 mb-3">
              <span>Suas escolhas</span>
              <li class="mt-2">
                <p class="font-weight-normal f-16">
                  Dados Pessoais: Será possível acessar e revisar estas
                  informações pessoais utilizadas na hora do cadastro a qualquer
                  momento no menu “Minha Conta” Após o login.
                </p>
                <p class="font-weight-normal f-16">
                  Exclusão de Dados Pessoais: Se desejar cancelar sua
                  participação no programa Cerveja Premiada, você pode enviar um
                  e-mail para{' '}
                  <a href="malito:contato@cervejapremiada.com.br">
                    <i>contato@cervejapremiada.com.br</i>{' '}
                  </a>{' '}
                  fazendo a solicitação.
                </p>
              </li>
            </li>
            <li class="font-weight-bold f-18 mb-3">
              <span>Onde armazenamos seus dados</span>
              <li class="mt-2">
                <p class="font-weight-normal f-16">
                  Os dados são armazenados em bancos de dados exclusivos do
                  Programa, em ambiente totalmente seguro.
                </p>
              </li>
            </li>
            <li class="font-weight-bold f-18 mb-3">
              <span>Alterações a esta Política</span>
              <li class="mt-2">
                <p class="font-weight-normal f-16">
                  Se houver alterações a forma que tratamos seus Dados Pessoais,
                  vamos atualizar esta Política. Nos reservamos o direito de
                  fazer alterações às nossas práticas e a esta Política a
                  qualquer tempo.
                </p>
              </li>
            </li>
            <li class="font-weight-bold f-18 mb-3">
              <span>Contato</span>
              <li class="mt-2">
                <p class="font-weight-normal f-16">
                  Para dúvidas ou reclamações sobre o nosso uso das suas
                  informações pessoais ou sobre a nossa Política de privacidade,
                  entre em contato conosco pelo e-mail{' '}
                  <a href="malito:contato@cervejapremiada.com.br">
                    <i>contato@cervejapremiada.com.br</i>
                  </a>
                </p>
              </li>
            </li>
          </ol>
        </div>
      </div>
    </Container>
  );
}
