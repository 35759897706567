/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';

import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';
import sortFuncDate from '../../../utils/ordernarColunaDate';
import ModalEditar from './ModalEditar/index';
import RemoteTable from './RemoteTable/index';
import { useSelector } from 'react-redux';
import { Filtros } from './Filtros';
import { useObject } from '../../../hooks/useObject';
import { primeiraLetraMaiscula } from '../../../helpers/regioes';

export default function GarcomSemPontuacao() {
  const usuario = useSelector((state) => {
    return state.usuario;
  });
  const { regiaoPermissao, programaPermissao } = usuario;
  const columns = [
    {
      dataField: 'nome',
      text: 'Garçom',
      sort: true,
      formatter: (celula, valor) => {
        return primeiraLetraMaiscula(valor.nome);
      },
    },
    {
      text: 'PDV',
      dataField: 'pdvs',
      sort: true,
      formatter: (celula, valor) => {
        return primeiraLetraMaiscula(valor.revenda);
      },
    },
    {
      dataField: 'cpf',
      text: 'CPF',
      formatter: (celula, valor) => {
        let cpf = valor.cpf.replace(/[^\d]/g, '');
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'celular',
      text: 'Telefone',
      formatter: (celula, valor) => {
        return `(${valor.celular.substring(0, 2)}) ${valor.celular.substring(
          2,
          7
        )}-${valor.celular.substring(7)}`;
      },
      sort: true,
    },
    {
      dataField: 'endCidade',
      text: 'Cidade',
      sort: true,
      formatter: (celula, valor) => {
        return primeiraLetraMaiscula(valor.endCidade);
      },
    },
    {
      text: 'Programa',
      dataField: 'programa',
      sort: true,
      formatter: (celula, valor) => {
        return primeiraLetraMaiscula(valor.programa);
      },
    },
  ];

  const [cpf, setCpf] = useState(usuario.cpf)

  const [filtros, setFiltro] = useObject({
    programa: programaPermissao ?? '',
    // regiao: regiaoPermissao ? regiaoPermissao : '',
    pdvId: '',
    // programa: '',
    regiao: '',
    cpf: '',
    naoPontuaram: true
  });

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Garçons que nunca pontuaram</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <RemoteTable
          dataId="id"
          url={`/api/parceiros`}
          colunas={columns}
          ordenacaoInicial={{ campo: 'nome', direcao: 'asc' }}
          // fieldModalExlcluir="nome"
          // modalEdit={ModalEditar}
          filtros={filtros}
          filtrosRow={<Filtros setFiltro={setFiltro} />}
        />
      </div>
    </Container>
  );
}
