/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col } from 'react-bootstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { useFetch } from '../../../hooks/useFetch';
import sortFunc from '../../../utils/ordernarColunaInt';
import queryString from 'query-string';
import { useObject } from '../../../hooks/useObject';
import api from '../../../services/API';
import { primeiraLetraMaiscula } from '../../../helpers/regioes';
import { useSelector } from 'react-redux';
import { CsvComponent } from '../CsvComponent';

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'resultadoMensal',
    order: 'desc',
  },
];

export default function Mensuracao() {
  const { regiaoPermissao, programaPermissao, cpf } = useSelector(
    (state) => state.usuario
  );
  const columns = [
    {
      dataField: 'codigo',
      text: 'DHNK',
      sort: true,
    },
    {
      dataField: 'nome',
      text: 'PDV',
      formatter: (celula, valor) => {
        return primeiraLetraMaiscula(valor.nome);
      },
      sort: true,
    },
    {
      dataField: 'programaNome',
      text: 'Programa',
      sort: true,
    },
    {
      dataField: 'regiao',
      text: 'Região',
      sort: true,
    },
    {
      dataField: 'dataCadastro',
      text: 'Data de Cadastro',
      sort: true,
      formatter: (celula, valor) => {
        return valor.dataCadastro
          ? moment(valor.dataCadastro).format('DD/MM/YYYY')
          : '-';
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'marca',
      text: 'Marca',
      formatter: (celula, valor) => {
        if (!valor.marca) return '-';
        return valor.marca;
      },
      sort: true,
    },
    {
      dataField: 'dcc',
      text: 'DCC',
      formatter: (celula, valor) => {
        if (!valor.dcc) return '-';
        return valor.dcc;
      },
      sort: true,
    },
    {
      dataField: 'ocdb',
      text: 'OCDB',
      formatter: (celula, valor) => {
        if (!valor.ocdb) return '-';
        return valor.ocdb;
      },
      sort: true,
    },
    {
      dataField: 'rtm',
      text: 'RTM',
      formatter: (celula, valor) => {
        if (!valor.rtm) return '-';
        return valor.rtm;
      },
      sort: true,
    },
    {
      dataField: 'mediaMensal',
      text: 'Média Mensal',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.mediaMensal)
          .toLocaleString('pt-BR')
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'mediaMensalPos',
      text: 'Média Mensal Pós',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.mediaMensalPos)
          .toLocaleString('pt-BR')
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'resultadoMensal',
      text: 'Resultado Mensal',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.resultadoMensal).toLocaleString('pt-BR') + '%';
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'totalVendidoPos',
      text: 'Total Vendido Pós',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.totalVendidoPos)
          .toLocaleString('pt-BR')
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'mesesAtendidosPos',
      text: 'Meses Atendidos ',
      sort: true,
      sortFunc: sortFunc,
    },
  ];

  const buscarUsuario = async () => {
    const response = await api.get(`/api/usuario?cpf=${cpf}`)

    if (response) {
      const { marcas, rtm } = response.data.usuario;
      setMarcasPermissao(marcas)
      setRtmPermissao(rtm)
    }
  }

  const rtmSelect = [
    { id: 1, valor: 'dcc', nome: 'DCC' },
    { id: 2, valor: 'dhnk', nome: 'DHNK' },
  ]

  useEffect(() => {
    fetchRegioes({});
    fetchProgramas({});
    buscarUsuario();
    buscarMarcas();
    buscarMensuracao();
  }, []);

  const [filtros, setFiltro] = useObject({
    regiao: regiaoPermissao ? regiaoPermissao : '',
    programa: programaPermissao ?? '',
    cpf
  });
  const [buscando, setBuscando] = useState(false);
  const [data, setData] = useState([]);
  const [rtm, setRtm] = useState([]);
  const [rtmPermissao, setRtmPermissao] = useState('');
  const [marcas, setMarcas] = useState([]);
  const [marcasPermissao, setMarcasPermissao] = useState('');
  const [programas, fetchProgramas] = useFetch('/api/programas', 'get');
  const [regioes, fetchRegioes] = useFetch('/api/regioes', 'get');

  const buscarMensuracao = async () => {
    setBuscando(true);
    const resultado = await api.get(
      `/api/mensuracao?${queryString.stringify(filtros)}`
    );
    if (resultado) {
      setData(resultado.data.mensuracao);
    }
    setBuscando(false);
  };

  const buscarMarcas = async () => {
    setBuscando(true)
    const resultado = await api.get(`/api/marcas`);

    if (resultado) {
      setMarcas(resultado.data.marcas)
    }
  }

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: isEmpty(data) ? 0 : data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total px-2">
        {size > 0 ? `${from}-${to} de ${size} resultados` : 'Nenhum resultado'}
      </span>
    ),
  };
  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Mensuração</h4>
      <hr className="hr-loja mt-0 mb-4" />

      <div>
        <ToolkitProvider
          bootstrap4
          keyField="rede"
          data={isEmpty(data) ? [] : data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <Row>
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={2}
                  className="d-flex flex-column"
                >
                  <label htmlFor="regiaoSelecionada">Regiões</label>
                  <select
                    className="input-select mb-3"
                    name="regiaoSelecionada"
                    id="regiaoSelecionada"
                    onChange={(e) =>
                      setFiltro({ ...filtros, regiao: e.target.value })
                    }
                    defaultValue=""
                    placeholder=""
                    disabled={regiaoPermissao}
                  >
                    <option value="">Todas</option>
                    {!isEmpty(regioes.data)
                      ? regioes.data.regioes.map((regiao) => {
                          let isSelected = regiaoPermissao === regiao.nome;
                          return (
                            <option
                              selected={isSelected}
                              value={regiao.nome}
                              key={regiao.id}
                            >
                              {regiao.nome}
                            </option>
                          );
                        })
                      : ''}
                  </select>
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={2}
                  className="d-flex flex-column"
                >
                  <label htmlFor="programaSelecionada">Programa</label>
                  <select
                    className="input-select mb-3"
                    name="programaSelecionada"
                    id="programaSelecionada"
                    onChange={(e) =>
                      setFiltro({ ...filtros, programa: e.target.value })
                    }
                    disabled={programaPermissao}
                    defaultValue=""
                    placeholder=""
                  >
                    <option value="">Todas</option>
                    {!isEmpty(programas.data)
                      ? programas.data.programas.map((programa) => {
                          let isSelected =
                            Number(programaPermissao) === Number(programa.id);
                          return (
                            <option
                              value={programa.id}
                              key={programa.id}
                              selected={isSelected}
                            >
                              {programa.nome}
                            </option>
                          );
                        })
                      : ''}{' '}
                  </select>
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={2}
                  className="d-flex flex-column"
                >
                  <label htmlFor="programaSelecionada">Marca</label>
                  <select
                    className="input-select mb-3"
                    name="programaSelecionada"
                    id="programaSelecionada"
                    onChange={(e) =>
                      setFiltro({ ...filtros, marca: e.target.value })
                    }
                    disabled={(marcasPermissao === '' || String(marcasPermissao).includes('[')) ? '' : marcasPermissao}
                    defaultValue=""
                    placeholder=""
                  >
                    <option value="">Todas</option>
                    { marcas ?
                      marcas.map(marca => {
                        let isSelected = (marcasPermissao === '' ||  String(marcasPermissao).includes('[')) ?
                          undefined
                          :
                          String(marcasPermissao) === String(marca.marca).toLowerCase();
                        return (
                          <option
                            value={marca.marca}
                            key={marca.marca}
                            selected={isSelected}
                          >
                            {marca.marca}
                          </option>
                        )
                      })
                      :
                      ''
                    }{' '}
                  </select>
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={2}
                  className="d-flex flex-column"
                >
                  <label htmlFor="rtmSelecionado">RTM</label>
                  <select
                    className="input-select mb-3"
                    name="rtmSelecionado"
                    id="rtmSelecionado"
                    onChange={(e) =>
                      setFiltro({ ...filtros, rtm: e.target.value })
                    }
                    disabled={(rtmPermissao === '' || String(rtmPermissao).includes('[')) ? '' : rtmPermissao}
                    defaultValue=""
                    placeholder=""
                  >
                    <option value="">Todas</option>
                    {
                      rtmSelect.map(rtm => {
                        let isSelected = (rtmPermissao === '' || String(rtmPermissao).includes('[')) ?
                            undefined
                            :
                            String(rtmPermissao) === String(rtm.nome).toLowerCase();
                        return (
                          <option
                            value={rtm.valor}
                            key={rtm.id}
                            selected={isSelected}
                          >
                            {rtm.nome}
                          </option>
                        )
                      })
                    }
                  </select>
                </Col>
                <Col xs={12} sm={6} md={2} className="d-flex flex-column">
                  <label>Digite sua busca</label>
                  <SearchBar
                    {...props.searchProps}
                    placeholder="Buscar"
                    className="input-theme mx-auto w-100"
                  />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={2}
                  className="d-flex flex-row row-btn-filtro"
                >
                  <button
                    className="btn-busca mr-2"
                    type="submit"
                    onClick={buscarMensuracao}
                  >
                    {buscando ? (
                      <i className="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      <i className="fas fa-search"></i>
                    )}
                  </button>
                  <CsvComponent
                    {...props.csvProps}
                    data={data}
                    columns={columns}
                  />
                </Col>
              </Row>

              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </Container>
  );
}
