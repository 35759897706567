export const regioes = [
  'Acre',
  'Alagoas',
  'Amapá',
  'Amazonas',
  'Bahia',
  'Ceará',
  'Distrito Federal',
  'Espírito Santo',
  'Goías',
  'Maranhão',
  'Mato Grosso',
  'Mato Grosso do Sul',
  'Minas Gerais',
  'Pará',
  'Paraíba',
  'Paraná',
  'Pernambuco',
  'Piauí',
  'Rio de Janeiro',
  'Rio Grande do Norte',
  'Rio Grande do Sul',
  'Rondônia',
  'Roraíma',
  'Santa Catarina',
  'São Paulo',
  'Sergipe',
  'Tocantins',
];

export const meses = [
  { id: 1, nome: 'Janeiro' },
  { id: 2, nome: 'Fevereiro' },
  { id: 3, nome: 'Março' },
  { id: 4, nome: 'Abril' },
  { id: 5, nome: 'Maio' },
  { id: 6, nome: 'Junho' },
  { id: 7, nome: 'Julho' },
  { id: 8, nome: 'Agosto' },
  { id: 9, nome: 'Setembro' },
  { id: 10, nome: 'Outubro' },
  { id: 11, nome: 'Novembro' },
  { id: 12, nome: 'Dezembro' },
];

export const anos = ['2022', '2021'];

export const primeiraLetraMaiscula = (text) => {
  if (typeof text !== 'string') return text;

  text = text.toLowerCase();
  text = text.charAt(0).toUpperCase() + text.slice(1);

  for (var i = 0; i < text.length; i++) {
    if (text.charAt(i) === ' ') {
      var charToUper = text.charAt(i + 1).toUpperCase();
      var sliceBegin = text.slice(0, i + 1);
      var sliceEnd = text.slice(i + 2);

      text = sliceBegin + charToUper + sliceEnd;
    }
  }

  return text;
};

export const mascaraPontosMilhar = (pontos) => {
  pontos = Number(pontos);
  pontos = pontos.toLocaleString({
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });

  return pontos.replace(',', '.');
};
