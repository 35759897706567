/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';

import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';
import sortFuncDate from '../../../utils/ordernarColunaDate';
import ModalEditar from './ModalEditar/index';
import RemoteTable from '../RemoteTable/index';
import { useSelector } from 'react-redux';
import { Filtros } from './Filtros';
import { useObject } from '../../../hooks/useObject';
import { primeiraLetraMaiscula } from '../../../helpers/regioes';

export default function Revendas() {
  const usuario = useSelector((state) => {
    return state.usuario;
  });
  const { regiaoPermissao, programaPermissao } = usuario;
  const columns = [
    {
      dataField: 'nome',
      text: 'Garçom',
      sort: true,
      formatter: (celula, valor) => {
        return primeiraLetraMaiscula(valor.nome);
      },
    },
    {
      dataField: 'cpf',
      text: 'CPF',
      formatter: (celula, valor) => {
        let cpf = valor.cpf.replace(/[^\d]/g, '');
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'celular',
      text: 'Celular',
      formatter: (celula, valor) => {
        return `(${valor.celular.substring(0, 2)}) ${valor.celular.substring(
          2,
          7
        )}-${valor.celular.substring(7)}`;
      },
      sort: true,
    },
    {
      dataField: 'sexo',
      text: 'Gênero',
      formatter: (celula, valor) => {
        if (!valor.sexo) return '';
        if (valor.sexo === 'F') return 'Feminino';
        return 'Masculino';
      },
      sort: true,
    },
    {
      dataField: 'endCidade',
      text: 'Cidade',
      sort: true,
      formatter: (celula, valor) => {
        return primeiraLetraMaiscula(valor.endCidade);
      },
    },
    {
      text: 'PDV',
      dataField: 'revenda',
      sort: true,
      formatter: (celula, valor) => {
        return primeiraLetraMaiscula(valor.revenda);
      },
    },
    {
      text: 'Programa',
      dataField: 'programa',
      sort: true,
      formatter: (celula, valor) => {
        return primeiraLetraMaiscula(valor.programa);
      },
    },
    {
      text: 'DHNK',
      dataField: 'codigoRevenda',
      sort: true,
    },
    {
      dataField: 'marca',
      text: 'Marca',
      formatter: (celula, valor) => {
        if (!valor.marca) return '-';
        return valor.marca;
      },
      sort: true,
    },
    {
      dataField: 'dcc',
      text: 'DCC',
      formatter: (celula, valor) => {
        if (!valor.dcc) return '-';
        return valor.dcc;
      },
      sort: true,
    },
    {
      dataField: 'ocdb',
      text: 'OCDB',
      formatter: (celula, valor) => {
        if (!valor.ocdb) return '-';
        return valor.ocdb;
      },
      sort: true,
    },
    {
      dataField: 'rtm',
      text: 'RTM',
      formatter: (celula, valor) => {
        if (!valor.rtm) return '-';
        return valor.rtm;
      },
      sort: true,
    },
    {
      dataField: 'totalPontos',
      text: 'Pontuação Total',
      formatter: (celula, valor) => {
        return Number(valor.totalPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'saldoPontos',
      text: 'Saldo de Pontos',
      formatter: (celula, valor) => {
        return Number(valor.saldoPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      text: 'Última Pontuação',
      dataField: 'ultimaPontuacao',
      formatter: (celula, valor) => {
        return valor.ultimaPontuacao
          ? moment(valor.ultimaPontuacao).format('DD/MM/YYYY')
          : '-';
      },
      sort: true,
      sortFunc: sortFuncDate,
    },
    {
      text: 'Último Acesso',
      dataField: 'ultimoAcessoEm',
      formatter: (celula, valor) => {
        return valor.ultimoAcessoEm
          ? moment(valor.ultimoAcessoEm).format('DD/MM/YYYY')
          : '-';
      },
      sort: true,
      sortFunc: sortFuncDate,
    },
    {
      text: 'Dias s/ Pontuar',
      dataField: 'diasSemPontuar',
      sort: true,
      sortFunc: sortFunc,
      formatter: (celula, valor) => {
        return valor.diasSemPontuar && valor.diasSemPontuar !== 0
          ? valor.diasSemPontuar
          : '-';
      },
    },
    {
      text: 'Dias s/ Acessar',
      dataField: 'diasSemAcessar',
      sort: true,
      sortFunc: sortFunc,
      formatter: (celula, valor) => {
        return valor.diasSemAcessar && valor.diasSemAcessar !== 0
          ? valor.diasSemAcessar
          : '-';
      },
    },
    {
      dataField: 'aceitouRegulamento',
      text: 'Aceitou Regulamento',
      sort: true,
    },
    {
      dataField: 'temporada',
      text: 'Temporada',
      formatter: (celula, valor) => valor.temporada.split(' ', 2)[1],
      sort: true,
    },
    {
      dataField: 'nomeRegional',
      text: 'Regional',
      sort: true,
    },
    {
      dataField: 'disparadoMensagemBoasVindasWhats',
      text: 'Recebeu Boas-Vindas',
      sort: true,
    },
    {
      dataField: 'disparadoMensagemPesquisaWhats',
      text: 'Recebeu Pesquisa',
      sort: true,
    },
    {
      dataField: 'confirmouCredito',
      text: 'Confirmou Crédito',
      sort: true,
      formatter: (celula, valor) => {
        return valor.confirmouCredito ? 'Sim' : 'Não';
      },
    },
    {
      isDummyField: true,
      dataField: 'ativo',
      text: 'Ativo',
      sort: true,
      hidden: [2].includes(usuario.permissao) ? false : true,
    },
    {
      dataField: 'editar',
      isDummyField: true,
      text: 'Editar',
      sort: false,
      hidden: [1, 2].includes(usuario.permissao) ? false : true,
    },
    {
      dataField: 'apagar',
      isDummyField: true,
      text: 'Apagar',
      sort: false,
      hidden: [2].includes(usuario.permissao) ? false : true,
    },
  ];

  const [cpf, setCpf] = useState(usuario.cpf);

  const [filtros, setFiltro] = useObject({
    pdvId: '',
    programa: programaPermissao ?? '',
    regiao: regiaoPermissao ? regiaoPermissao : '',
    cpf,
  });

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Garçons</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <RemoteTable
          dataId="id"
          url={`/api/parceiros`}
          colunas={columns}
          ordenacaoInicial={{ campo: 'nome', direcao: 'asc' }}
          fieldModalExlcluir="nome"
          modalEdit={ModalEditar}
          filtros={filtros}
          filtrosRow={<Filtros setFiltro={setFiltro} />}
        />
      </div>
    </Container>
  );
}
