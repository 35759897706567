import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import historico from '../../../services/Historico';
import AtivarPdvCadastrarGarcom from '../AtivarPdvCadastrarGarcom';
import AtivarPdvCadastrarPesquisa from '../AtivarPdvCadastrarPesquisa';
import AtivarPdvCadastrarProprietario from '../AtivarPdvCadastrarProprietario';
import AtivarPdvDadosRevenda from '../AtivarPdvDadosRevenda';
import AtivarPdvDesejaCadastrarOutroGarcom from '../AtivarPdvDesejaCadastrarOutroGarcom';
import AtivarPdvSemGarcom from '../AtivarPdvSemGarcom';
import AtivarPdvSucesso from '../AtivarPdvSucesso';

export default function AtivarPdv() {
  const [enviando, setEnviando] = useState(false);
  const [codigoPdv, setCodigoPdv] = useState('');
  const [dadosRevenda, setDadosRevenda] = useState([]);
  const [observacao, setObservacao] = useState('');
  const [qtdGarconsCadastrados, setQtdGarconsCadastrados] = useState(0);
  const [pageAtivarPdv, setPageAtivarPdv] = useState(0);

  const handleAvancarAtivarPdv = async (e) => {
    e.preventDefault();

    setEnviando(true);
    const resultado = await api.get(`/api/revendas/codigo/${codigoPdv}`);

    if (resultado && resultado.data.ativo === 0) {
      setDadosRevenda(resultado.data);
      setPageAtivarPdv(1);
    }

    if (resultado && resultado.data.ativo === 1) {
      setDadosRevenda(resultado.data);
      setPageAtivarPdv(6);
    }

    setEnviando(false);
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black"></h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      {pageAtivarPdv === 0 && (
        <div className="d-flex flex-column align-items-center">
          <form className="form">
            <Row>
              <Col className="d-flex flex-column mb-5" xs={12} sm={12}>
                <h1 className="text-center font-weight-bold">Ativar PDV</h1>
              </Col>
              <Col className="d-flex flex-column mb-5" xs={12} sm={12}>
                <h1 className="text-center font-weight-bold">
                  Digite o código do PDV
                </h1>
              </Col>
              <Col className="d-flex flex-column" xs={12} sm={12}>
                <input
                  id="codigo-pdv"
                  name="codigo-pdv"
                  type="text"
                  value={codigoPdv}
                  onChange={(e) => setCodigoPdv(e.target.value)}
                ></input>
              </Col>
            </Row>

            {enviando ? (
              <button disabled className="btn-primario" type="submit">
                AVANÇANDO...
              </button>
            ) : (
              <button
                className="btn-primario"
                type="submit"
                onClick={handleAvancarAtivarPdv}
              >
                AVANÇAR
              </button>
            )}
            <button
              className="btn-invertido"
              onClick={(e) => historico.push('/painel')}
            >
              Voltar ao painel
            </button>
          </form>
        </div>
      )}

      {pageAtivarPdv === 1 && (
        <AtivarPdvDadosRevenda
          pageAtivarPdv={pageAtivarPdv}
          setPageAtivarPdv={setPageAtivarPdv}
          dadosRevenda={dadosRevenda}
          observacao={observacao}
          setObservacao={setObservacao}
        />
      )}
      {pageAtivarPdv === 2 && (
        <AtivarPdvCadastrarProprietario
          pageAtivarPdv={pageAtivarPdv}
          setPageAtivarPdv={setPageAtivarPdv}
          dadosRevenda={dadosRevenda}
          observacao={observacao}
          setObservacao={setObservacao}
        />
      )}
      {pageAtivarPdv === 3 && (
        <AtivarPdvCadastrarPesquisa
          pageAtivarPdv={pageAtivarPdv}
          setPageAtivarPdv={setPageAtivarPdv}
          dadosRevenda={dadosRevenda}
        />
      )}

      {pageAtivarPdv === 4 && (
        <AtivarPdvCadastrarGarcom
          pageAtivarPdv={pageAtivarPdv}
          setPageAtivarPdv={setPageAtivarPdv}
          dadosRevenda={dadosRevenda}
          qtdGarconsCadastrados={qtdGarconsCadastrados}
          setQtdGarconsCadastrados={setQtdGarconsCadastrados}
        />
      )}

      {pageAtivarPdv === 5 && (
        <AtivarPdvDesejaCadastrarOutroGarcom
          pageAtivarPdv={pageAtivarPdv}
          setPageAtivarPdv={setPageAtivarPdv}
          dadosRevenda={dadosRevenda}
        />
      )}
      {pageAtivarPdv === 6 && (
        <AtivarPdvSucesso
          pageAtivarPdv={pageAtivarPdv}
          setPageAtivarPdv={setPageAtivarPdv}
          dadosRevenda={dadosRevenda}
          qtdGarconsCadastrados={qtdGarconsCadastrados}
          setQtdGarconsCadastrados={setQtdGarconsCadastrados}
        />
      )}

      {pageAtivarPdv === 7 && (
        <AtivarPdvSemGarcom
          pageAtivarPdv={pageAtivarPdv}
          setPageAtivarPdv={setPageAtivarPdv}
          dadosRevenda={dadosRevenda}
        />
      )}
    </Container>
  );
}
