import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import historico from '../../../services/Historico';
import InputMask from 'react-input-mask';
import validarCpf from '../../../utils/validarCpf';

export default function AtivarPdvCadastrarProprietario(props) {
  const [enviando, setEnviando] = useState(false);
  const [nomeResponsavel, setNomeResponsavel] = useState(
    props.dadosRevenda.responsavel
  );
  const [emailResponsavel, setEmailResponsavel] = useState(
    props.dadosRevenda.email
  );
  const [cpfResponsavel, setCpfResponsavel] = useState(props.dadosRevenda.cpf);
  const [celularResponsavel, setCelularResponsavel] = useState(
    props.dadosRevenda.celular
  );
  const [telefoneResponsavel, setTelefoneResponsavel] = useState(
    props.dadosRevenda.telefone
  );

  const validaCpf = async (e) => {
    const valor = e.target.value.replace(/[^\d]/g, '');
    if (validarCpf(valor)) return;

    toast.error(`CPF inválido`, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
    });
    setCpfResponsavel('');
  };

  const handleAvancarAtivarPdv = async (e) => {
    e.preventDefault();

    setEnviando(true);

    const data = {
      responsavel: nomeResponsavel,
      email: emailResponsavel,
      cpf: cpfResponsavel,
      celular: celularResponsavel,
      telefone: telefoneResponsavel,
      observacao: props.observacao,
      isResponsavelUpdate: true,
    };

    data.telefone = data.telefone.replace(/[^\d]/g, '');
    data.celular = data.celular.replace(/[^\d]/g, '');
    data.cpf = data.cpf.replace(/[^\d]/g, '');

    const resultado = await api.put(
      `/api/revendas/${props.dadosRevenda.id}`,
      data
    );

    if (resultado) {
      toast.success('Proprietário cadastrado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });

      props.dadosRevenda.programas === '8'
        ? props.setPageAtivarPdv(7)
        : props.setPageAtivarPdv(4);
      //props.setPageAtivarPdv(4);
      setEnviando(false);
    }

    setEnviando(false);
  };

  return (
    <Container fluid>
      <div className="d-flex flex-column align-items-center">
        <Row>
          <Col className="d-flex flex-column mb-5" xs={12} sm={12}>
            <h1 className="text-center font-weight-bold">
              Proprietário, cadastre-se
            </h1>
          </Col>
          <Col className="d-flex flex-column mb-5" xs={12} sm={12}>
            <h2 className="text-center">
              Você está no PDV <b>“{props.dadosRevenda.nomeFantasia}”</b>,
              código <b>“{props.dadosRevenda.codigo}”</b> que participa do
              programa <b>“{props.dadosRevenda.nomePrograma}” </b>
            </h2>
          </Col>
        </Row>
        <form className="form">
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={12}>
              <label>Nome do Responsável</label>
              <input
                id="nomeResponsavel"
                name="nomeResponsavel"
                type="text"
                value={nomeResponsavel}
                onChange={(e) => setNomeResponsavel(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12}>
              <label>Email</label>
              <input
                id="emailResponsavel"
                name="emailResponsavel"
                type="text"
                value={emailResponsavel}
                onChange={(e) => setEmailResponsavel(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12}>
              <label>CPF</label>
              <InputMask
                id="cpfResponsavel"
                name="cpfResponsavel"
                type="text"
                mask="999.999.999-99"
                onChange={(e) => setCpfResponsavel(e.target.value)}
                value={cpfResponsavel}
                onBlur={validaCpf}
              />
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>Celular Responsável</label>
              <InputMask
                id="celularResponsavel"
                name="celularResponsavel"
                type="text"
                mask="(99) 99999-9999"
                onChange={(e) => setCelularResponsavel(e.target.value)}
                value={celularResponsavel}
              />
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>Telefone Responsável</label>
              <InputMask
                id="telefoneResponsavel"
                name="telefoneResponsavel"
                type="text"
                mask="(99) 9999-9999"
                onChange={(e) => setTelefoneResponsavel(e.target.value)}
                value={telefoneResponsavel}
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12}>
              {' '}
              {enviando ? (
                <button disabled className="btn-primario" type="submit">
                  AVANÇANDO...
                </button>
              ) : (
                <button
                  className="btn-primario"
                  type="submit"
                  onClick={handleAvancarAtivarPdv}
                >
                  AVANÇAR
                </button>
              )}
            </Col>
            <Col className="d-flex flex-column" xs={12}>
              {' '}
              <button
                className="btn-invertido"
                onClick={(e) => historico.push('/painel')}
              >
                Voltar ao painel
              </button>
            </Col>
          </Row>
        </form>
      </div>
    </Container>
  );
}
