import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import './styles.css';
import SelectRevenda from '../SelectRevenda/index';
import historico from '../../../services/Historico';
import { useObject } from '../../../hooks/useObject';

export default function AtivarPdvCadastrarPesquisa(props) {
  const [enviando, setEnviando] = useState(false);

  const [data, setData] = useObject({ idRevenda: props.dadosRevenda.id });
  const [produtosMaisVendidos, setProdutosMaisVendidos] = useState([]);

  const [produto, setProduto] = useObject({});

  const cadastrarPesquisa = async (e) => {
    e.preventDefault();
    const body = {
      data,
      idTemporada: Number(props.dadosRevenda.idTemporada),
      produtosMaisVendidos: JSON.stringify(produtosMaisVendidos),
    };

    setEnviando(true);

    if (
      !body.data.caixasVendidas ||
      !body.data.nDeMesas ||
      !body.data.qtdGarcons ||
      !body.data.qtdGarconsFreela ||
      !body.data.tamanho_pdv ||
      !body.data.qtdeProdutosVendidos ||
      !body.data.capacidadeClientes
    ) {
      props.setPageAtivarPdv(4);
    } else {
      const resultado = await api.post('/api/pesquisa_mercado', body);
      if (resultado) {
        toast.success('Pesquisa de Mercado cadastrado com sucesso', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });

        props.dadosRevenda.programas === '8'
          ? props.setPageAtivarPdv(7)
          : props.setPageAtivarPdv(4);
      }
    }
    setEnviando(false);
  };

  const addProduto = (e) => {
    e.preventDefault();
    if (!produto) {
      toast.error(
        'Informe produto, quantidade e valor unitário para adicionar',
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        }
      );
      return;
    }
    const achou = produtosMaisVendidos.find((e) => {
      return e.nome === produto.nome && e.qtde === produto.qtde;
    });

    if (achou) {
      toast.error('Produto já adicionado', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setProduto({ nome: '', qtde: '' });
    } else {
      setProdutosMaisVendidos([...produtosMaisVendidos, { ...produto }]);
    }
    setProduto({ nome: '', qtde: '' });
  };

  const delProduto = (e) => {
    setProdutosMaisVendidos(
      produtosMaisVendidos.filter((selecionado, index) => {
        return Number(index) !== Number(e.target.id);
      })
    );
  };

  return (
    <Container fluid>
      <div className="d-flex flex-column align-items-center">
        <Row>
          <Col className="d-flex flex-column mb-5" xs={12} sm={12}>
            <h1 className="text-center font-weight-bold">Cadastrar Pesquisa</h1>
          </Col>
          <Col className="d-flex flex-column mb-5" xs={12} sm={12}>
            <h2 className="text-center">
              Você está no PDV <b>“{props.dadosRevenda.nomeFantasia}”</b>,
              código <b>“{props.dadosRevenda.codigo}”</b> que participa do
              programa <b>“{props.dadosRevenda.nomePrograma}”</b>
            </h2>
          </Col>
        </Row>
        <form
          className="form"
          style={{ maxWidth: '460px' }}
          onSubmit={cadastrarPesquisa}
        >
          <Row>
            <Col xs={12} md={6}>
              <label htmlFor="caixasVendidas">Caixas Vendidas / semana</label>
              <input
                id="caixasVendidas"
                name="caixasVendidas"
                type="number"
                step="1"
                className="w-100"
                value={data.caixasVendidas}
                onChange={(e) => setData({ caixasVendidas: e.target.value })}
              ></input>
            </Col>
            <Col xs={12} md={6}>
              <label htmlFor="nDeMesas">Nº de Mesas</label>
              <input
                id="nDeMesas"
                name="nDeMesas"
                type="number"
                step="1"
                className="w-100"
                value={data.nDeMesas}
                onChange={(e) => setData({ nDeMesas: e.target.value })}
              ></input>
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col xs={12} md={4}>
              <label htmlFor="qntdGarcons">Qntd. Garçons</label>
              <input
                id="qntdGarcons"
                name="qntdGarcons"
                type="number"
                className="w-100"
                step="1"
                value={data.qtdGarcons}
                onChange={(e) => setData({ qtdGarcons: e.target.value })}
              ></input>
            </Col>
            <Col xs={12} md={4}>
              <label className="w-100" htmlFor="qtdGarconsFreela">
                Qntd. Garçons Freela
              </label>
              <input
                id="qtdGarconsFreela"
                name="qtdGarconsFreela"
                type="number"
                step="1"
                className="w-100"
                value={data.qtdGarconsFreela}
                onChange={(e) => setData({ qtdGarconsFreela: e.target.value })}
              ></input>
            </Col>
            <Col xs={4} md={4}>
              <label htmlFor="qtdeProdutosVendidos">
                Qtde. Produtos Vendidos
              </label>
              <input
                id="qtdeProdutosVendidos"
                name="qtdeProdutosVendidos"
                type="number"
                step="1"
                className="w-100"
                value={data.qtdeProdutosVendidos}
                onChange={(e) =>
                  setData({ qtdeProdutosVendidos: e.target.value })
                }
              ></input>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <label htmlFor="tamanho_pdv">Tamanho PDV (m²)</label>
              <input
                id="tamanho_pdv"
                name="tamanho_pdv"
                type="number"
                step="1"
                className="w-100"
                value={data.tamanho_pdv}
                onChange={(e) => setData({ tamanho_pdv: e.target.value })}
              ></input>
            </Col>
            <Col xs={12} md={6}>
              <label htmlFor="capacidadeClientes">
                Capacidade Clientes / Dia
              </label>
              <input
                id="capacidadeClientes"
                name="capacidadeClientes"
                type="number"
                step="1"
                className="w-100"
                value={data.capacidadeClientes}
                onChange={(e) =>
                  setData({ capacidadeClientes: e.target.value })
                }
              ></input>
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col className="d-flex flex-column" xs={12} sm={8} md={6}>
              <label htmlFor="produto">Produtos vendidos da concorrencia</label>
              <input
                id="produto"
                className="w-100"
                placeholder="Produto"
                value={produto.nome}
                onChange={(e) => setProduto({ nome: e.target.value })}
              ></input>
            </Col>
            <Col className="fl-d" xs={12} sm={4} md={4}>
              <label htmlFor="qtde">Unidades</label>
              <input
                id="qtde"
                className="w-100"
                placeholder="Qtde"
                type="number"
                step="1"
                value={produto.qtde}
                onChange={(e) => setProduto({ qtde: e.target.value })}
              ></input>
            </Col>

            <Col
              className="d-flex justify-content-center text-center align-items-center"
              xs={12}
              sm={3}
              md={2}
            >
              <button onClick={addProduto} className="btn-adicionar">
                <i className="fas fa-plus"></i>
              </button>
            </Col>
            <Col xs={12} sm={12}>
              <ul className="lista" id="produtos">
                {produtosMaisVendidos.map((produto, index) => (
                  <li className="lista-item" key={index}>
                    {` ${produto.nome} - ${Number(produto.qtde).toLocaleString(
                      'pt-BR'
                    )}`}
                    <button onClick={delProduto} className="btn-limpo ml-3">
                      <i id={index} className="fas fa-trash"></i>
                    </button>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>

          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              AVANÇAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
