/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';
import sortFuncDate from '../../../utils/ordernarColunaDate';
import ModalEditar from './ModalEditar/index';
import RemoteTable from './RemoteTable';
import { useSelector } from 'react-redux';
import { Filtros } from './Filtros';
import { useObject } from '../../../hooks/useObject';
import { primeiraLetraMaiscula } from '../../../helpers/regioes';
import api from '../../../services/API';
import './styles.css';
import Utils from '../../../utils/utils';

export default function GarcomSaldo() {
  const usuario = useSelector((state) => {
    return state.usuario;
  });
  const { regiaoPermissao, programaPermissao } = usuario;
  const [cpf, setCpf] = useState(usuario.cpf);
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(1, 'year').toISOString().substr(0, 10)
  );
  const [dataFinal, setDataFinal] = useState(
    moment().toISOString().substr(0, 10)
  );
  const [buscando, setBuscando] = useState(false);
  const [status, setStatus] = useState([]);
  const [resultadoGeral, setResultadoGeral] = useState([]);

  const updateStatus = async () => {
    setBuscando(true);
    const resposta = await api.get(
      `/api/parceiros/saldo?dataInicial=${dataInicial}&dataFinal=${dataFinal}`
    );

    if (resposta) {
      setStatus(resposta.data.resultado);
      setResultadoGeral(resposta.data.resultadoGeral);
      setBuscando(false);
    }
    setBuscando(false);
  };

  useEffect(() => {
    updateStatus();
  }, []);

  const formatQtdeGarcom = (qtde, qtdeComTampa) => {
    let semTampa = qtde,
      comTampa = qtdeComTampa;
    let resultado = semTampa - comTampa;

    return resultado;
  };

  return (
    <Container fluid>
      <Row className="d-flex justify-content-end">
        <Col xs={12} sm={6} md={3} lg={2} className="d-flex flex-column">
          <label>De</label>
          <input
            type="date"
            className="input-theme mb-3"
            value={dataInicial}
            onChange={(e) => setDataInicial(e.target.value)}
            max="2999-12-31"
          ></input>
        </Col>
        <Col xs={12} sm={6} md={3} lg={2} className="d-flex flex-column">
          <label>Até</label>
          <input
            type="date"
            className="input-theme mb-3"
            onChange={(e) => setDataFinal(e.target.value)}
            value={dataFinal}
            max="2999-12-31"
          ></input>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={1}
          className="d-flex flex-column justify-content-center align-items-center d-md-block"
        >
          <button className="btn-busca" type="submit" onClick={updateStatus}>
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <hr className="hr-loja mt-0 mb-4" />
      {buscando ? (
        <Row className="justify-content-center align-items-center">
          <i className="fas fa-circle-notch fa-spin aling-self-center"></i>
        </Row>
      ) : (
        <Row>
          <Col xs={12} md={6} lg={6}>
            <h4 className="pt-3 pb-3 f-black">Saldo de Garçons</h4>
            <div className="tabela-container">
              <table className="tabela">
                <thead>
                  <tr>
                    <th>Região</th>
                    <th>PDV'S</th>
                    <th>Qtde de Garçons</th>
                    <th>Soma de Saldo p/ Envio</th>
                  </tr>
                </thead>
                <tbody>
                  {status.map((item, index) => {
                    return (
                      <tr>
                        <td key={index}>{item.regiao}</td>
                        <td key={index}>
                          {Utils.formatThousandSeparator(item.pdvs)}
                        </td>
                        <td key={index}>
                          {Utils.formatThousandSeparator(item.qtdeGarcom)}
                        </td>
                        <td key={index}>
                          {Number(item.historicoQtde) -
                            Number(item.vendasItensQtde) <
                          0
                            ? '0'
                            : Utils.formatThousandSeparator(
                                item.historicoQtde - item.vendasItensQtde
                              )}
                        </td>
                      </tr>
                    );
                  })}
                  {resultadoGeral.length > 0 &&
                    resultadoGeral.map((item, index) => {
                      return (
                        <tr className="tabela-footer">
                          <td>TOTAL GERAL:</td>
                          <td key={index}>
                            {Utils.formatThousandSeparator(item.pdvs)}
                          </td>
                          <td key={index}>
                            {Utils.formatThousandSeparator(item.qtdeGarcom)}
                          </td>
                          <td key={index}>
                            {Utils.formatThousandSeparator(item.quantidade)}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Col>
          <Col xs={12} md={6} lg={6}>
            <h4 className="pt-3 pb-3 f-black">
              Garçons que nunca enviaram tampa
            </h4>
            <div className="tabela-container">
              <table className="tabela">
                <thead>
                  <tr>
                    <th>Região</th>
                    <th>PDV'S</th>
                    <th>Qtde de Garçons</th>
                  </tr>
                </thead>
                <tbody>
                  {status.map((item, index) => {
                    return (
                      <tr>
                        <td key={index}>{item.regiao}</td>
                        <td key={index}>
                          {Utils.formatThousandSeparator(item.pdvs)}
                        </td>
                        <td key={index}>
                          {formatQtdeGarcom(
                            item.qtdeGarcom,
                            item.garcomComTampa
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  {resultadoGeral.length > 0 &&
                    resultadoGeral.map((item, index) => {
                      return (
                        <tr className="tabela-footer">
                          <td>TOTAL GERAL:</td>
                          <td key={index}>
                            {Utils.formatThousandSeparator(item.pdvs)}
                          </td>
                          <td key={index}>
                            {Utils.formatThousandSeparator(item.garcomSemTampa)}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
}
