import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Form, Input } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import api from '../../../services/API';

export default function CadastrarCartao() {
  const [enviando, setEnviando] = useState(false);
  const [idTemporada, setIdTemporada] = useState('');
  const cadastrarCartao = async ({ codigoBarras, numero }, { resetForm }) => {
    if (!codigoBarras) {
      toast.error('Informe o código de barras', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!numero) {
      toast.error('Informe o número', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    setEnviando(true);
    const resultado = await api.post('/api/cartoes', {
      codigoBarras,
      numero,
      idTemporada: Number(idTemporada),
    });

    if (resultado && resultado.data.sucesso) {
      toast.success('Cartão cadastrado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      resetForm();
    } else if (resultado && !resultado.data.sucesso) {
      toast.success(resultado.data.mensagem, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
    setEnviando(false);
  };
  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Cartão</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <Form className="form" onSubmit={cadastrarCartao}>
          <label htmlFor="codigoBarras">Código de Barras</label>
          <Input
            id="codigoBarras"
            name="codigoBarras"
            type="text"
            placeholder="Código de Barras"
          ></Input>
          <label htmlFor="numero">Número</label>
          <Input
            id="numero"
            name="numero"
            type="text"
            placeholder="Número"
          ></Input>

          <label htmlFor="pdvSelecionado">Temporada</label>
          <select
            className="input-select mb-3"
            name="temporada"
            id="temporada"
            defaultValue=""
            placeholder="Selecione"
            value={idTemporada}
            onChange={(e) => setIdTemporada(e.target.value)}
          >
            <option disabled value="">
              Selecione
            </option>

            <option value="1">Temporada 2021</option>
            <option value="2">Temporada 2022</option>
          </select>

          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              CADASTRAR
            </button>
          )}
        </Form>
      </div>
    </Container>
  );
}
