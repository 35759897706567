/* eslint-disable no-alert, no-console */
/*  eslint-disable-next-line */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import './style.css';

export default function Produto({ clicarBotaoAvancar, setProdutos }) {
  // const [produto, setProduto] = useState(false);
  // const [enviando, setEnviando] = useState(false);
  const [novaEmbalagem, setNovaEmbalagem] = useState('');
  const [novoProduto, setNovoProduto] = useState('');
  const [mostrarAddProduto, setMostrarAddProduto] = useState(false);
  const [produtosSelect, setProdutosSelect] = useState([]);
  const [produtosSelecionados, setProdutosSelecionados] = useState([]);

  useEffect(() => {
    buscarProdutos();
  }, []);

  useEffect(() => {
    setProdutos(produtosSelecionados);
  }, [produtosSelecionados]);

  const zerarForm = () => {
    setNovoProduto('');
    setNovaEmbalagem('');
  };
  const cadastrarNovoProduto = async (e) => {
    e.preventDefault();
    const nome = `${novoProduto} - ${novaEmbalagem}`;
    const data = {
      nome,
    };
    const resultado = await api.post('/api/produtos', data);

    if (resultado) {
      toast.success('Produto adicionado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      zerarForm();
    }
    buscarProdutos();
  };

  const buscarProdutos = async () => {
    const resultado = await api.get('/api/produtos?ativo=1');
    if (resultado && resultado.data.produtos) {
      setProdutosSelect(resultado.data.produtos);
    }
  };

  const onClickCheckbox = (e) => {
    const { id, checked } = e.currentTarget;

    const produto = produtosSelect.filter((p) => p.nome === id);

    if (checked) {
      setProdutosSelecionados([...produtosSelecionados, produto[0]]);
    } else {
      const filtrados = produtosSelecionados.filter((p) => p.nome !== id);
      setProdutosSelecionados(filtrados);
    }
  };

  return (
    <Container fluid>
      <Form onSubmit="">
        <Row className="options-criar-regra">
          <Col xs={12} md={12} className="d-flex flex-column">
            <div className="pb-3">
              <h5>Produtos</h5>
            </div>
            <div className="d-flex flex-column align-items-start space-between-center">
              {produtosSelect.map((produto) => (
                <div key={produto.nome}>
                  <input
                    type="checkbox"
                    id={produto.nome}
                    name={produto.nome}
                    onChange={onClickCheckbox}
                  />
                  <label htmlFor={produto.nome} className="pl-2">
                    {produto.nome}
                  </label>
                </div>
              ))}
            </div>
          </Col>

          {mostrarAddProduto === true && (
            <Row className="pt-5 adicionar_produto">
              <Col xs={12} md={6} className="d-flex">
                <input
                  type="text"
                  id="produto_name"
                  name="produto_name"
                  placeholder="Nome do produto"
                  value={novoProduto}
                  onChange={(e) => setNovoProduto(e.target.value)}
                />
              </Col>
              <Col xs={12} md={6} className="d-flex">
                <input
                  type="text"
                  id="produto_ml"
                  name="produto_ml"
                  placeholder="ML"
                  value={novaEmbalagem}
                  onChange={(e) => setNovaEmbalagem(e.target.value)}
                />
                <button className="pl-2" onClick={cadastrarNovoProduto}>
                  <i className="fas fa-plus"></i>
                </button>
              </Col>
            </Row>
          )}
          <Col
            xs={12}
            md={6}
            className="d-flex pt-4"
            onClick={(e) => setMostrarAddProduto(true)}
          >
            <button type="button">+ Adicionar Produto</button>
          </Col>
          <Col xs={12} md={6} className="d-flex justify-content-end pt-4">
            <button type="button" onClick={clicarBotaoAvancar}>
              Avançar {'>>'}
            </button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
