import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import './styles.css';

export default function Perfil() {
  const parceiro = useSelector((state) => {
    return state.parceiro;
  });

  const proprietario = useSelector((state) => {
    return state.proprietario;
  });

  const [enviando, setEnviando] = useState(false);

  const cliente = parceiro ? parceiro : proprietario;
  const [data, setData] = useState(cliente);

  const enviar = async (e) => {
    e.preventDefault();

    if (
      !data.mensagem ||
      !data.nome ||
      !data.email ||
      !data.celular ||
      !data.telefone ||
      !data.endCidade
    ) {
      toast.error('Por favor, preencha todos os campos.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    setEnviando(true);
    const resultado = await api.post('/api/enviar-email-contato', { ...data });
    setData({ ...data, message: '' });
    if (resultado) {
      toast.success('Mensagem enviada com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
    setEnviando(false);
  };

  return (
    <Container className="mt-5 mb-5">
      <div>
        <h5>CONTATO</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div>
        <form
          onSubmit={enviar}
          className="form loja fundo-claro justify-content-center align-items-center"
        >
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="nome">Nome</label>
              <input
                id="nome"
                name="nome"
                type="text"
                placeholder="Nome"
                value={data.nome}
                onChange={(e) => setData({ ...data, nome: e.target.value })}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="nome">Email</label>
              <input
                id="email"
                name="email"
                type="text"
                placeholder="Email"
                value={data.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={4}>
              <label htmlFor="celular">Celular</label>
              <InputMask
                id="celular"
                name="celular"
                type="text"
                placeholder="Celular"
                value={data.celular}
                onChange={(e) => setData({ ...data, celular: e.target.value })}
                mask="(99) 99999-9999"
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={4}>
              <label htmlFor="telefone">Telefone</label>
              <InputMask
                id="telefone"
                name="telefone"
                type="text"
                placeholder="Telefone"
                value={data.telefone}
                onChange={(e) => setData({ ...data, telefone: e.target.value })}
                mask="(99) 9999-9999"
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={4}>
              <label htmlFor="endCidade">Cidade</label>
              <input
                id="endCidade"
                name="endCidade"
                type="text"
                placeholder="Cidade"
                value={data.endCidade}
                onChange={(e) =>
                  setData({ ...data, endCidade: e.target.value })
                }
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12}>
              <label htmlFor="mensagem">Mensagem</label>
              <textarea
                type="text"
                id="mensagem"
                name="mensagem"
                className="contato-mensagem pt-3 pb-3"
                placeholder="Digite sua mensagem aqui"
                value={data.mensagem}
                onChange={(e) => setData({ ...data, mensagem: e.target.value })}
              ></textarea>
            </Col>
          </Row>
          {enviando ? (
            <button disabled className="btn-enviar" type="submit">
              ENVIANDO...
            </button>
          ) : (
            <button className="btn-enviar" type="submit">
              ENVIAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
