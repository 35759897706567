import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { useSelector } from 'react-redux';

export default function Navbar() {
  const tipoUsuario = useSelector((state) => state.autenticacao.tipoUsuario);
  const isResgate = useSelector((state) => state.parceiro.isResgate);

  return (
    <nav className="nav w-100 d-flex justify-content-center align-items-center">
      <Link className="nav-link" to="/loja">
        Home
      </Link>
      {tipoUsuario === 'parceiro' && isResgate && (
        <Link className="nav-link" to="/enviar-pontuacao">
          Enviar Pontuação
        </Link>
      )}
      {/* <Link className="nav-link" to="/cartoes">
        Cartões
      </Link> */}
      <Link className="nav-link" to="/pontuacoes">
        Pontuações
      </Link>
      <Link className="nav-link" to="/resgates">
        Resgates
      </Link>
      <Link className="nav-link" to="/fotos-videos">
        Fotos e Vídeos
      </Link>
      <Link className="nav-link" to="/concurso">
        Concurso
      </Link>
      <Link className="nav-link" to="/regulamento">
        Regulamento
      </Link>
      <Link className="nav-link" to="/perfil">
        Minha Conta
      </Link>
      <Link className="nav-link" to="/contato">
        Contato
      </Link>
    </nav>
  );
}
