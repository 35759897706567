import { takeLatest, call, put, all } from 'redux-saga/effects';
import api from '../../../services/API';
import { atualizarPerfilSucesso, atualizarPerfilFalhou } from './actions';
import { toast } from 'react-toastify';

export function* atualizarPerfil({ payload }) {
  try {
    const { nome, senhaAntiga, senha } = payload;
    const response = yield call(api.put, '/api/usuarios', {
      nome,
      senha,
      senhaAntiga,
    });

    yield put(atualizarPerfilSucesso(response.data.nome, response.data.senha));
    toast.success('Perfil atualizado com sucesso', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
    });
  } catch (err) {
    yield put(atualizarPerfilFalhou());
  }
}

export default all([takeLatest('@usuario/ATUALIZAR_PERFIL', atualizarPerfil)]);
