import React from 'react';
import { Container } from 'react-bootstrap';
import { Form, Input } from '@rocketseat/unform';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { atualizarPerfil } from '../../../store/modules/usuario/actions';

export default function Perfil() {
  const atualizando = useSelector((state) => state.usuario.atualizando);
  const dispatch = useDispatch();

  const usuario = useSelector((state) => {
    return state.usuario;
  });

  const atualizarAdministrador = ({ nome, senhaAntiga, senha }) => {
    dispatch(atualizarPerfil(nome, senhaAntiga, senha));
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Meu Perfil</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <Form
          className="form"
          initialData={{
            nome: usuario.nome,
            email: usuario.email,
            cpf: usuario.cpf,
          }}
          onSubmit={atualizarAdministrador}
        >
          <label htmlFor="nome">Nome</label>
          <Input id="nome" name="nome" type="text" placeholder="Nome"></Input>
          <label htmlFor="cpf">Login</label>
          <Input
            id="cpf"
            name="cpf"
            disabled
            type="text"
            placeholder="Login"
          ></Input>
          <label htmlFor="email">Email</label>
          <Input
            id="email"
            name="email"
            disabled
            type="text"
            placeholder="Email"
          ></Input>
          <label htmlFor="senha">Senha Atual</label>
          <Input
            id="senhaAntiga"
            name="senhaAntiga"
            type="password"
            autoComplete="new-password"
            placeholder="Senha atual *"
          ></Input>
          <label htmlFor="senha">Nova Senha</label>
          <Input
            id="senha"
            name="senha"
            type="password"
            autoComplete="new-password"
            placeholder="Nova senha *"
          ></Input>
          <span className="aviso pb-3">* Deixe em branco para não alterar</span>
          {atualizando ? (
            <button disabled className="btn-primario" type="submit">
              ATUALIZANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              ATUALIZAR
            </button>
          )}
        </Form>
      </div>
    </Container>
  );
}
