import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import historico from '../../../services/Historico';

export default function ModalCancelarResgate(props) {
  const [cancelando, setCancelando] = useState(false);
  const cancelarResgate = async () => {
    setCancelando(true);
    const resultado = await api.post('/api/cancelarResgate', {
      identificador: props.resgate,
    });

    if (resultado) {
      toast.success(`Resgate cancelado com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      props.onHide({ identificador: props.resgate, status: '3' });
    }
    setCancelando(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Cancelar Resgate</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="d-flex justify-content-center align-items-center text-center w-100 p-4">
          <span>Tem certeza que deseja cancelar esse resgate?</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {cancelando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={cancelarResgate}
              disabled
            >
              CANCELANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={cancelarResgate}
            >
              SIM
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            NÃO
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
