/*  eslint-disable-next-line */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Col, Container, Row } from 'react-bootstrap';
import api from '../../../services/API';
import ModalProdutosPesquisa from '../ModalProdutosPesquisa';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import sortFunc from '../../../utils/ordernarColunaInt';
import ModalExcluir from './ModalExcluir/index';
import ModalEditar from './ModalEditar/index';
import { useSelector } from 'react-redux';
import { CsvComponent } from '../CsvComponent';

const { SearchBar } = Search;

export default function Pesquisas() {
  const usuario = useSelector((state) => {
    return state.usuario;
  });

  const columns = [
    {
      dataField: 'codigo',
      text: 'Cod. PDV',
      sort: true,
    },
    {
      dataField: 'revenda',
      text: 'PDV',
      sort: true,
    },
    {
      dataField: 'nomePrograma',
      text: 'Programa',
      sort: true,
    },
    {
      dataField: 'cidade',
      text: 'Cidade',
      sort: true,
    },
    {
      dataField: 'mediaMensal',
      text: 'Média Mensal',
      formatter: (celula, valor) => {
        return Math.ceil(
          Number(valor.mediaMensal).toLocaleString('pt-BR') / 24
        );
      },
      sort: true,
    },
    {
      dataField: 'caixasVendidas',
      text: 'Cxs Vendidas / Semana',
      formatter: (celula, valor) => {
        return Number(valor.caixasVendidas).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'qtdGarcons',
      text: 'Quantidade de Garçons',
      formatter: (celula, valor) => {
        return Number(valor.qtdGarcons).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'qtdGarconsFreela',
      text: 'Garçons Freela',
      formatter: (celula, valor) => {
        return Number(valor.qtdGarconsFreela).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'share',
      text: 'Share',
      formatter: (celula, valor) => {
        return Number(valor.share).toFixed(2);
      },
    },
    {
      dataField: 'produtosVendidos',
      text: 'Qtde. Produtos Vendidos',
    },
    {
      dataField: 'produtosMaisVendidos',
      isDummyField: true,
      text: 'Produtos mais vendidos',
      formatter: (celula, valor) => {
        return (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModal(valor.produtosMaisVendidos);
            }}
          >
            <i className="fas fa-list"></i>
          </button>
        );
      },
    },
    {
      dataField: 'editar',
      isDummyField: true,
      text: 'Editar',
      sort: false,
      hidden: [1, 2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalEditar(valor.id);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
    },
    {
      dataField: 'apagar',
      isDummyField: true,
      text: 'Apagar',
      sort: false,
      hidden: [2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalExcluir(valor.id, valor.revenda);
          }}
        >
          <i className="fas fa-trash f-red"></i>
        </button>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: 'revenda',
      order: 'asc',
    },
  ];

  useEffect(() => {
    buscarPesquisas();
  }, []);

  const buscarPesquisas = async () => {
    setBuscando(true);
    const resultado = await api.get(`/api/pesquisa_mercado`);
    if (resultado) {
      setData(resultado.data.pesquisas);
    }
    setBuscando(false);
  };
  const [buscando, setBuscando] = useState(false);
  const [data, setData] = useState([]);
  const [mostrarModal, setMostrarModal] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [concorrente] = useState([]);

  const [dataEditar, setDataEditar] = useState(null);
  const [id, setId] = useState('');
  const [revenda, setRevenda] = useState('');
  const [mostrarModalExcluir, setMostrarModalExcluir] = useState(false);
  const [mostrarModalEditar, setMostrarModalEditar] = useState(false);

  const visualizarModal = (produtos, concorrente) => {
    setProdutos(JSON.parse(produtos));
    setMostrarModal(true);
  };

  const fecharModal = () => {
    setMostrarModal(false);
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  const abrirModalExcluir = (id, revenda) => {
    setRevenda(revenda);
    setId(id);
    setMostrarModalExcluir(true);
  };

  const fecharModalExcluir = () => {
    setRevenda('');
    setId('');
    setMostrarModalExcluir(false);
  };

  const abrirModalEditar = (id) => {
    const dados = data.filter((d) => d.id === id);
    setDataEditar(dados[0]);
    setMostrarModalEditar(true);
  };

  const fecharModalEditar = () => {
    setDataEditar([]);
    setMostrarModalEditar(false);
  };

  const atualizarExclusao = (id) => {
    setData(data.filter((d) => d.id !== id));
  };

  const atualizarPesquisa = (dataUpdated) => {
    setData(
      data.map((d) => {
        if (d.id === dataUpdated.id) {
          d = { ...d, ...dataUpdated };
        }
        return d;
      })
    );
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Pesquisas de Mercado</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <Row>
                <Col xs={12} sm={6} md={3} className="d-flex flex-column">
                  <label>Digite sua busca</label>
                  <SearchBar
                    {...props.searchProps}
                    placeholder="Buscar"
                    className="input-theme mx-auto w-100"
                  />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={2}
                  className="d-flex flex-row row-btn-filtro"
                >
                  <CsvComponent
                    {...props.csvProps}
                    data={data}
                    columns={columns}
                  />
                </Col>
              </Row>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalProdutosPesquisa
        show={mostrarModal}
        onHide={fecharModal}
        produtos={produtos}
        concorrente={concorrente}
      />
      <ModalExcluir
        show={mostrarModalExcluir}
        onHide={fecharModalExcluir}
        revenda={revenda}
        id={id}
        atualizarExclusao={atualizarExclusao}
      />
      <ModalEditar
        show={mostrarModalEditar}
        onHide={fecharModalEditar}
        data={dataEditar}
        atualizarPesquisa={atualizarPesquisa}
      />
    </Container>
  );
}
