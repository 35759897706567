import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import './styles.css';

export default function Aplicativo() {
  return (
    <Container className="mt-10 mb-5">
      <Row>
        <Col xs={12} md={6}>
          <div className="celular">
            <img src="/imagens/aplicativo-celular.png" alt="Celular" />
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="app">
            <h2>BAIXE O APP AGORA</h2>
            <div className="links">
              <a href="https://play.google.com/store/apps/details?id=br.com.cervejapremiada">
                <img src="/imagens/playstore.png" alt="Android" />
              </a>{' '}
              <a href="https://apps.apple.com/br/app/cerveja-premiada/id1624581751">
                <img src="/imagens/storeapp.jpeg" alt="Appstore" />
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
