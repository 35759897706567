import moment from 'moment';
class Utils {
  static mapToDatabase(values) {
    for (const key in values) {
      const value = values[key];
      if (!value) delete values[key];
    }
    return values;
  }

  static isEmpty(value) {
    if (value === 'null' || value === null || value === 'Invalid date') {
      return true;
    } else if (
      (typeof value !== 'number' || typeof value !== 'boolean') &&
      value === ''
    ) {
      return true;
    } else if (value === 'undefined' || value === undefined) {
      return true;
    }
    return false;
  }

  static validateRequiredFields(data, requiredFields) {
    const notPassed = [];
    for (const key of requiredFields) {
      const value = data[key];
      if (Utils.isEmpty(value)) {
        notPassed.push(key);
      }
    }

    return notPassed;
  }

  static validateCpf(str) {
    if (!str) return false;
    let soma = 0;
    let resto;

    const cpf = str.replace(/[\s.-]*/gim, '');

    if (
      !cpf ||
      cpf.length !== 11 ||
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    ) {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11))) return false;

    return true;
  }

  static validateCnpj(str) {
    if (!str) return false;

    const match = str.toString().match(/\d/g);
    const numbers = Array.isArray(match) ? match.map(Number) : [];

    if (numbers.length !== 14) return false;

    const items = [...new Set(numbers)];
    if (items.length === 1) return false;
    const calc = (x) => {
      const slice = numbers.slice(0, x);
      let factor = x - 7;
      let sum = 0;

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i];
        sum += n * factor--;
        if (factor < 2) factor = 9;
      }

      const result = 11 - (sum % 11);

      return result > 9 ? 0 : result;
    };

    const digits = numbers.slice(12);

    const digit0 = calc(12);
    if (digit0 !== digits[0]) return false;

    const digit1 = calc(13);
    return digit1 === digits[1];
  }

  static formatNumber(value, currency) {
    return currency
      ? value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      : value.toLocaleString('pt-BR');
  }

  static formatDate(value, withTime) {
    return withTime
      ? moment(value).utc().format('DD/MM/YYYY hh:mm:ss')
      : moment(value).utc().format('DD/MM/YYYY');
  }

  static formatCpf(value) {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  static formatTelephone(value) {
    return value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }

  static formatCellphone(value) {
    return value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }

  static onlyNumber(value) {
    return value.replace(/\D/g, '');
  }

  static formatThousandSeparator(value) {
    return Number(value).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }
}

export default Utils;
