import React, { useEffect, useState } from 'react';
import './styles.css';
import { Link } from 'react-router-dom';
import Footer from '../../../components/Loja/Footer';
import Header from '../../../components/Loja/Header';

import { Container, Row, Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  autenticarParceiro,
  autenticarProprietario,
} from '../../../store/modules/autenticacao/actions';

import api from '../../../services/API';

export default function Home() {
  useEffect(() => {
    document.title = 'Cerveja Premiada - Mais Vantagens';
  }, []);

  const dispatch = useDispatch();
  const enviando = useSelector((state) => state.autenticacao.autenticandoLoja);

  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [senhaCpf, setSenhaCpf] = useState('');
  const [senhaCnpj, setSenhaCnpj] = useState('');
  const [temporadaSelecionado, setTemporadaSelecionada] = useState(0);
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [loginType, setLoginType] = useState('');

  const enviandoProprietario = useSelector(
    (state) => state.autenticacao.autenticandoLojaProprietario
  );

  useEffect(() => {
    document.title = 'Cerveja Premiada - Login';
  }, []);

  const solicitarAutenticacao = async (e, temp) => {
    if (e) {
      e.preventDefault();
    }

    if (temporadaSelecionado || temp) {
      return dispatch(
        autenticarParceiro(
          cpf.replace(/[^\d]/g, ''),
          senhaCpf,
          temporadaSelecionado || temp
        )
      );
    }

    const checkTemporadas = await api.post('/api/sessoes/parceiro/temporadas', {
      cpf: cpf.replace(/[^\d]/g, ''),
      senha: senhaCpf,
    });

    if (checkTemporadas && checkTemporadas.data) {
      if (checkTemporadas.data.length == 1) {
        dispatch(
          autenticarParceiro(
            cpf.replace(/[^\d]/g, ''),
            senhaCpf,
            checkTemporadas.data[0] || temporadaSelecionado
          )
        );
      } else {
        setLoginType('parceiro');
        setShowLoginForm(false);
      }
    }
  };

  const solicitarAutenticacaoProprietario = async (e, temp) => {
    if (e) {
      e.preventDefault();
    }

    if (temporadaSelecionado || temp) {
      return dispatch(
        autenticarProprietario(
          cnpj.replace(/[^\d]/g, ''),
          senhaCnpj,
          temporadaSelecionado || temp
        )
      );
    }

    const checkTemporadas = await api.post(
      '/api/sessoes/proprietario/temporadas',
      {
        cnpj: cnpj.replace(/[^\d]/g, ''),
        senha: senhaCnpj,
      }
    );

    if (checkTemporadas && checkTemporadas.data) {
      if (checkTemporadas.data.length == 1) {
        dispatch(
          autenticarProprietario(
            cnpj.replace(/[^\d]/g, ''),
            senhaCnpj,
            checkTemporadas.data[0] || temporadaSelecionado
          )
        );
      } else {
        setLoginType('proprietario');
        setShowLoginForm(false);
      }
    }
  };

  const handleSelectSeason = (showLogin, season) => {
    setTemporadaSelecionada(season);

    if (loginType == 'parceiro') {
      solicitarAutenticacao(null, season);
    } else {
      solicitarAutenticacaoProprietario(null, season);
    }
  };

  return (
    <>
      <Header auto />
      <div>
        <img
          className="w-100"
          src="imagens/GUARDE_TAMPAS_banner_home_.jpg"
          alt="Cerveja Premiada"
        ></img>
      </div>
      <Container className="d-flex flex-column justify-content-center">
        {!showLoginForm && (
          <Row Row className="justify-content-center mt-5 mb-5">
            <Col
              xs={12}
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <div className="btn-home">
                <button
                  className="btn-primario"
                  onClick={(e) => handleSelectSeason(true, 1)}
                >
                  TEMPORADA <span>2021</span>
                </button>
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <div className="btn-home">
                <button
                  className="btn-primario"
                  onClick={(e) => handleSelectSeason(true, 2)}
                >
                  TEMPORADA <span>2022</span>
                </button>
              </div>
            </Col>
          </Row>
        )}

        {showLoginForm && (
          <>
            <Row className="justify-content-center mt-5 mb-5">
              <Col
                xs={12}
                md={6}
                className="d-flex justify-content-center align-items-center"
              >
                <Form
                  onSubmit={solicitarAutenticacaoProprietario}
                  className="form-login form-login-loja"
                >
                  <h5>PROPRIETÁRIO</h5>
                  <label>Digite seu CNPJ ou CPF e acesse sua conta.</label>
                  <label htmlFor="cnpjProprietario">CNPJ / CPF</label>
                  <input
                    name="cnpjProprietario"
                    type="text"
                    placeholder="CPF / CNPJ"
                    maxLength="14"
                    value={cnpj}
                    onChange={(e) => setCnpj(e.target.value)}
                  ></input>
                  <label htmlFor="senhaProprietario">Senha</label>
                  <input
                    name="senhaProprietario"
                    type="password"
                    placeholder="Senha"
                    value={senhaCnpj}
                    onChange={(e) => setSenhaCnpj(e.target.value)}
                  ></input>
                  {enviandoProprietario ? (
                    <button disabled className="btn-primario" type="submit">
                      VALIDANDO...
                    </button>
                  ) : (
                    <button className="btn-primario" type="submit">
                      ENTRAR
                    </button>
                  )}
                  <Link
                    className="mb-3 mt-3 text-center link-clean"
                    to="/esqueceu-senha/proprietario"
                  >
                    Esqueceu sua senha?
                  </Link>
                </Form>
              </Col>
              <Col
                xs={12}
                md={6}
                className="d-flex justify-content-center align-items-center"
              >
                <Form
                  onSubmit={solicitarAutenticacao}
                  className="form-login form-login-loja"
                >
                  <h5>GARÇOM</h5>
                  <label>Entre com seu CPF e acesse sua conta</label>
                  <label htmlFor="cpf">CPF</label>
                  <input
                    name="cpf"
                    type="text"
                    placeholder="CPF"
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value)}
                  ></input>
                  <label htmlFor="senha">Senha</label>
                  <input
                    name="senha"
                    type="password"
                    placeholder="Senha"
                    value={senhaCpf}
                    onChange={(e) => setSenhaCpf(e.target.value)}
                  />
                  {enviando ? (
                    <button disabled className="btn-primario" type="submit">
                      VALIDANDO...
                    </button>
                  ) : (
                    <button className="btn-primario" type="submit">
                      ENTRAR
                    </button>
                  )}
                  <Link
                    className="mb-3 mt-3 text-center link-clean"
                    to="/esqueceu-senha/parceiro"
                  >
                    Esqueceu sua senha?
                  </Link>
                </Form>
              </Col>
              <Col
                xs={12}
                className="d-flex justify-content-center align-items-center"
              ></Col>
            </Row>
          </>
        )}
      </Container>
      {/*
                <div className="btn-voltar">
                  <button
                    className="btn-primario"
                    onClick={(e) => setShowLoginForm(false)}
                  >
                    Selecionar outra temporada
                  </button>
                </div>*/}
      {/* <Container className=" d-md-none ">
        <Row>
          <Link to="/login">
            <img
              className="w-100 mt-3 mb-3"
              src="imagens/banner-mobile.png"
              alt="Cerveja Premiada"
            ></img>
          </Link>
        </Row>
      </Container>
      <Container className="d-md-flex flex-column justify-content-center align-items-center pt-5 pb-5">
        <Row>
              <h1 className="title-home">TITULO</h1>
        </Row>
      </Container>
      <Container className="d-md-flex flex-column justify-content-center align-items-center pb-5">
        <Row>
            <Col xs={12} sm={4} className="m-auto p-2 text-center item-home">
                    <img
                      className="d-block w-100 "
                      src="imagens/02_mobile.png"
                      alt="item"
                    />
                <p className="pt-4 pb-2">Descrição do item</p>
                <Link to="/">
                    <button>Botão para ação</button>
                </Link>
            </Col>
            <Col xs={12} sm={4} className="m-auto p-2 text-center item-home">
                    <img
                      className="d-block w-100 "
                      src="imagens/02_mobile.png"
                      alt="item"
                    />
                    <p className="pt-4 pb-2">Descrição do item</p>
                <Link to="/">
                    <button>Botão para ação</button>
                </Link>
            </Col>
            <Col xs={12} sm={4} className="m-auto p-2 text-center item-home">
                    <img
                      className="d-block w-100 "
                      src="imagens/02_mobile.png"
                      alt="item"
                    />
                    <p className="pt-4 pb-2">Descrição do item</p>
                <Link to="/">
                    <button>Botão para ação</button>
                </Link>
            </Col>
        </Row>
      </Container> */}
      <Footer />
    </>
  );
}
