import { combineReducers } from 'redux';

import autenticacao from './autenticacao/reducer';
import usuario from './usuario/reducer';
import parceiro from './parceiro/reducer';
import proprietario from './proprietario/reducer';
export default combineReducers({
  autenticacao,
  usuario,
  parceiro,
  proprietario,
});
