import React, { useEffect, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import api from '../../../../../services/API';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

export default function ModalFeedback({ open, onClose, id, atualizarDados }) {
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [feedbacks, setFeedbacks] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [categoria, setCategoria] = useState('');

  useEffect(() => {
    if (id) fetchFeedbacks();
    fetchCategorias();

    return () => {
      atualizarDados();
    };
  }, [id]);

  const fetchFeedbacks = async () => {
    setLoading(true);
    const { data } = await api.get(`/api/feedbacks/${id}`);
    setFeedbacks(data.feedbacks);
    setLoading(false);
  };

  const fetchCategorias = async () => {
    const { data } = await api.get(`/api/categorias-feedback`);
    setCategorias(data);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!feedback) {
      toast.error(`Campo feedback vazio`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });

      return;
    }

    if (!categoria) {
      toast.error(`Campo categoria vazio`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });

      return;
    }

    if (!id) {
      toast.error(`Id vazio`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    setLoading(true);

    try {
      const { data } = await api.post(`/api/feedbacks/`, {
        feedback,
        categoria,
        idRevenda: id,
      });

      if (data) {
        toast.success(`Feedback feito com sucesso`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        fetchFeedbacks();
        setFeedback('');
      }
    } catch (e) {
      toast.error(`Error ao processar feedback`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }

    setLoading(false);
  };

  return (
    <Modal
      show={open}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Feedback</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="w-100 p-4">
          <form className="form loja fundo-claro justify-content-center align-items-center w-100">
            <Row>
              <Col className="d-flex flex-column" xs={12}>
                <label htmlFor="nome">Deixe seu feedback</label>
                <textarea
                  rows="6"
                  cols="50"
                  className="w-100 textarea"
                  id="feedback"
                  name="feedback"
                  type="text"
                  placeholder="Escreva seu feedback...."
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                ></textarea>
              </Col>
              <Col className="d-flex" xs={12}>
                <Row className="d-flex align-items-center justify-content-between w-100">
                  <Col className="d-flex flex-column" xs={3}>
                    <label htmlFor="nome">Selecione uma categoria</label>
                    <select
                      className="input-select mb-3"
                      name="categoria"
                      id="categoria"
                      onChange={(e) => setCategoria(e.target.value)}
                      placeholder=""
                    >
                      <option value="">Todas</option>
                      {categorias.map((item) => {
                        let isSelected = categoria === item.categoria;
                        return (
                          <option
                            selected={isSelected}
                            value={item.categoria}
                            key={item.id}
                          >
                            {item.categoria}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                  <Col xs={2}>
                    <button className="btn-enviar" onClick={onSubmit}>
                      {loading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        'Enviar'
                      )}
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
          <Row>
            {feedbacks.map((item) => {
              return (
                <Col
                  className="d-flex flex-column p-2 mt-3 rounded"
                  style={{ background: '#F0F2F5' }}
                  xs={12}
                >
                  <span> {item.feedback} </span>
                  <span className="small">
                    <i className="fas fa-tag mr-2 mt-3"></i>
                    <b>
                      Criado em {moment(item.criadoEm).format('DD/MM/YYYY')}
                    </b>
                  </span>
                  <span className="small">
                    <i className="fas fa-calendar mr-2 "></i>
                    <b>Categoria: {item.categoria}</b>
                  </span>
                </Col>
              );
            })}
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={onClose}
          >
            Fechar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
