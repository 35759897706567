import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import historico from '../../../services/Historico';

export default function AtivarPdvDesejaCadastrarOutroGarcom(props) {
  const [enviando, setEnviando] = useState(false);

  async function handleAtivarPdv(e) {
    e.preventDefault();
    setEnviando(true);

    const resultado = await api.patch(
      `/api/revendas/ativar/${props.dadosRevenda.id}`,
      {
        ativo: true,
      }
    );

    if (resultado) {
      setEnviando(false);
      props.setPageAtivarPdv(props.pageAtivarPdv + 1);
    }

    setEnviando(false);
  }
  return (
    <Container fluid>
      <div className="d-flex flex-column align-items-center">
        <form className="form">
          <Row>
            <Col className="d-flex flex-column mb-5" xs={12} sm={12}>
              <h1 className="text-center font-weight-bold">
                DESEJA CADASTRAR OUTRO GARÇOM?
              </h1>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <button
                className="btn-primario"
                onClick={(e) => props.setPageAtivarPdv(props.pageAtivarPdv - 1)}
              >
                SIM
              </button>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <button className="btn-decline" onClick={handleAtivarPdv}>
                NÃO
              </button>
            </Col>
          </Row>

          {/* <button
            className="btn-invertido"
            onClick={(e) => historico.push('/painel')}
          >
            Voltar ao painel
          </button> */}
        </form>
      </div>
    </Container>
  );
}
