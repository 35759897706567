import React, { useState, useEffect } from 'react';
import './styles.css';
import { Route, Switch } from 'react-router-dom';
import Footer from '../../../components/Loja/Footer';
import Header from '../../../components/Loja/Header';
import Aplicativo from '../../../components/Loja/Aplicativo';
import PremiosLoja from '../../../components/Loja/PremiosLoja';
import Slider from '../../../components/Loja/Slider';
import DetalhePremio from '../../../components/Loja/DetalhePremio';
import Perfil from '../../../components/Loja/Perfil';
import Pontuacoes from '../../../components/Loja/Pontuacoes';
import Resgates from '../../../components/Loja/Resgates';
import ListaPontos from '../../../components/Loja/ListaPontos';
import Contato from '../../../components/Loja/Contato';
import UploadFoto from '../../../components/Loja/UploadFoto';
import Regulamento from '../../../components/Loja/Regulamento';
import ModalRegulamento from '../../../components/Loja/ModalRegulamento';
import Cartao from '../../../components/Loja/Cartao/index';
import Midias from '../../../components/Loja/Midias/index';
import Privacidade from '../../../components/Loja/Privacidade';
import Concurso from '../../../components/Loja/Concurso';
import SuperAcelerador from '../../../components/Loja/SuperAcelerador';
import ModalVideoBemVindo from '../../../components/Loja/ModalVideoBemVindo';
import { useSelector } from 'react-redux';
import PalpiteCopa from '../../../components/Loja/PalpiteCopa';
import ModalPalpiteCopa from '../../../components/Loja/ModalPalpiteCopa';
import ModalResgate from '../../../components/Loja/ModalResgate';

export default function Home() {
  // const [showModal, setShowModal] = useState(false)

  // const getPalpite = async () => {
  //   const { pathname } = window.location;
  //   console.log("🚀 ~ file: index.js:34 ~ getPalpite ~ pathname", pathname)

  //   if (pathname === '/loja') {
  //     try {
  //       const { data } = await api.get(`/api/palpite-copa-parceiro`);
  //       if (data) setShowModal(false);
  //     } catch (error) {
  //       setShowModal(true);
  //     }
  //   } else {
  //     setShowModal(false)
  //   }
  // };

  // useEffect(()=>{
  //   getPalpite()
  // }, [])

  return (
    <>
      <Header auto />
      <main>
        <Switch>
          <Route
            exact
            path="/loja"
            component={(props) => {
              return (
                <div className="mt-5">
                  <Slider />
                  <PremiosLoja />
                </div>
              );
            }}
          />
          <Route
            exact
            path="/loja/aplicativo"
            component={(props) => <Aplicativo />}
          />
          <Route
            exact
            path="/loja/credito"
            component={(props) => <PremiosLoja tipo="credito" />}
          />
          <Route
            exact
            path="/loja/super-acelerador"
            component={(props) => <SuperAcelerador />}
          />
          <Route
            exact
            path="/premio/:codigo"
            component={(props) => <DetalhePremio />}
          />
          <Route exact path="/perfil" component={(props) => <Perfil />} />
          <Route
            exact
            path="/pontuacoes"
            component={(props) => <Pontuacoes />}
          />
          <Route exact path="/resgates" component={(props) => <Resgates />} />
          <Route
            exact
            path="/enviar-pontuacao"
            component={(props) => <UploadFoto />}
          />
          <Route
            exact
            path="/pontos-produtos"
            component={(props) => <ListaPontos />}
          />
          <Route
            exact
            path="/regulamento"
            component={(props) => <Regulamento />}
          />
          <Route exact path="/cartao" component={(props) => <Cartao />} />
          <Route
            path="/loja/politica-privacidade"
            component={(props) => <Privacidade />}
          />
          <Route exact path="/contato" component={(props) => <Contato />} />
          <Route exact path="/fotos-videos" component={(props) => <Midias />} />
          <Route exact path="/concurso" component={(props) => <Concurso />} />
          {/* <Route
            exact
            path="/palpite-copa"
            component={(props) => <PalpiteCopa />}
          /> */}
        </Switch>
      </main>
      <Footer />
      <ModalRegulamento />
      {/* <ModalPalpiteCopa /> */}
      <ModalResgate />
    </>
  );
}
