import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './styles.css';

export default function ModalResgate(props) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [nome, setNome] = useState(null);
  const [tipo, setTipo] = useState('PONTUAR');
  const h = useHistory();
  const { parceiro, proprietario } = useSelector((state) => state);

  useEffect(() => {
    const isNotificado = localStorage.getItem('avisoExpiracao');

    if (!isNotificado) {
      setOpen(true);
    }

    if (parceiro?.dataExpiracao) {
      let dataExpiracao = new Date(parceiro?.dataExpiracao).getTime();
      let current_date = new Date().getTime();

      setNome(parceiro?.nome);

      if (dataExpiracao < current_date) {
        setData(parceiro?.dataResgate);
        setTipo('RESGATE');
      } else {
        setTipo('PONTUAR');
        setData(parceiro?.dataExpiracao);
      }
    }

    if (proprietario?.cnpj) {
      setData(proprietario?.dataExpiracao);
      setNome(proprietario?.nomeFantasia);
      setTipo('RESGATE');
    }
  }, [parceiro, proprietario]);

  const handlePalpite = () => {
    h.push('/loja');

    localStorage.setItem('avisoExpiracao', '1');
    setOpen(false);
  };

  return (
    <Modal show={open} size="md" centered keyboard={false}>
      <Modal.Body className="p-6 " style={{ textAlign: 'center' }}>
        <h1>
          <strong>Olá, {nome}</strong>
        </h1>
        {tipo === 'PONTUAR' && (
          <p>
            Seu prazo para pontuar expira em {moment(data).format('DD/MM/YYYY')}
            .
            <br />
            Não perca essa oportunidade pontue agora.
          </p>
        )}

        {tipo === 'RESGATE' && (
          <p>
            Seu prazo para resgatar expira em{' '}
            {moment(data).format('DD/MM/YYYY')}.
            <br />
            Não perca essa oportunidade e resgate agora.
          </p>
        )}
        <button className="btn-primario" onClick={handlePalpite}>
          {tipo === 'PONTUAR' ? 'PONTUAR AGORA' : 'RESGATAR AGORA'}
        </button>
      </Modal.Body>
    </Modal>
  );
}
