import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import historico from '../../../services/Historico';

export default function AtivarPdvDadosRevenda(props) {
  const [enviando, setEnviando] = useState(false);
  const [codigoPdv, setCodigoPdv] = useState('');
  const [observacao, setObservacao] = useState('');

  const handleAvancarAtivarPdv = async (e) => {
    e.preventDefault();

    props.setPageAtivarPdv(2);
  };

  const handleVoltarPainel = (e) => {
    e.preventDefault();
    historico.push('/painel');
  };
  return (
    <Container fluid>
      <div className="d-flex flex-column align-items-center">
        <form className="form">
          <Row>
            <Col className="d-flex flex-column mb-5" xs={12} sm={12}>
              <h1 className="text-center font-weight-bold">Ativar PDV</h1>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12}>
              <label>PDV do Programa</label>
              <input
                disabled
                id="programaPdv"
                name="programaPdv"
                type="text"
                value={props.dadosRevenda.nomePrograma}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>Cód. PDV</label>
              <input
                disabled
                id="codigoPdv"
                name="codigoPdv"
                type="text"
                value={props.dadosRevenda.codigo}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>PDV</label>
              <input
                disabled
                id="nomePdv"
                name="nomePdv"
                type="text"
                value={props.dadosRevenda.nomeFantasia}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>CPF/CNPJ</label>
              <input
                disabled
                id="cnpj"
                name="cnpj"
                type="text"
                value={props.dadosRevenda.cnpj}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>CEP</label>
              <input
                disabled
                id="cep"
                name="cep"
                type="text"
                value={props.dadosRevenda.endCep}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12}>
              <label>Razão Social</label>
              <input
                disabled
                id="razaoSocial"
                name="razaoSocial"
                type="text"
                value={props.dadosRevenda.razaoSocial}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={9}>
              <label>Endereço</label>
              <input
                disabled
                id="endRua"
                name="endRua"
                type="text"
                value={props.dadosRevenda.endRua}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={3}>
              <label>Número</label>
              <input
                disabled
                id="endNumero"
                name="endNumero"
                type="text"
                value={props.dadosRevenda.endNumero}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>Bairro</label>
              <input
                disabled
                id="endBairro"
                name="endBairro"
                type="text"
                value={props.dadosRevenda.endBairro}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>Complemento</label>
              <input
                disabled
                id="endComplemento"
                name="endComplemento"
                type="text"
                value={props.dadosRevenda.endComplemento}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={6}>
              <label>Cidade</label>
              <input
                disabled
                id="endCidade"
                name="endCidade"
                type="text"
                value={props.dadosRevenda.endCidade}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={3}>
              <label>Estado</label>
              <input
                disabled
                id="endEstado"
                name="endEstado"
                type="text"
                value={props.dadosRevenda.endEstado}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12} lg={3}>
              <label>Região</label>
              <input
                disabled
                id="regiao"
                name="regiao"
                type="text"
                value={props.dadosRevenda.regiao}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12}>
              <label>Observação</label>
              <textarea
                className="textarea"
                id="observacao"
                name="observacao"
                type="text"
                rows="6"
                value={props.observacao}
                onChange={(e) => props.setObservacao(e.target.value)}
              ></textarea>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12}>
              {enviando ? (
                <button disabled className="btn-primario" type="submit">
                  AVANÇANDO...
                </button>
              ) : (
                <button
                  className="btn-primario"
                  type="submit"
                  onClick={handleAvancarAtivarPdv}
                >
                  AVANÇAR
                </button>
              )}
            </Col>

            <Col className="d-flex flex-column" xs={12}>
              <button className="btn-invertido" onClick={handleVoltarPainel}>
                Voltar ao painel
              </button>
            </Col>
          </Row>
        </form>
      </div>
    </Container>
  );
}
