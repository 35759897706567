/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles.css';
import { Form, Input } from '@rocketseat/unform';
import Footer from '../../../components/Loja/Footer';
import Header from '../../../components/Loja/Header';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import historico from '../../../services/Historico';
import validarCpf from '../../../utils/validarCpf';
import InputMask from 'react-input-mask';

export default function Solicitacao(props) {
  const [enviando, setEnviando] = useState(false);
  const [informacao, setInformacao] = useState('');
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [emailPbi, setEmailPbi] = useState('');
  const [celular, setCelular] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [rtm, setRtm] = useState([]);
  const [rtmSelecionado, setRtmSelecionado] = useState('Todos');
  const [marcas, setMarcas] = useState([]);
  const [marcaSelecionada, setMarcaSelecionada] = useState('Todas');

  const buscarMarcas = async () => {
    const resultado = await api.get(`/api/marcas`);
    if (resultado) setMarcas(resultado.data.marcas);
  }

  const rtmSelect = [
    { id: 1, valor: 'dcc', nome: 'DCC' },
    { id: 2, valor: 'dhnk', nome: 'DHNK' },
  ];

  const validaCpf = async (e) => {
    const valor = e.target.value.replace(/[^\d]/g, '');
    if (validarCpf(valor)) return;

    toast.error(`${e.target.placeholder} - CPF inválido`, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
    });
    setCpf('');
  };

  const enviarEmail = async () => {
    setEnviando(true);
    try {
      const data = {
        nome,
        email,
        emailPbi,
        cpf,
        celular,
        dataNascimento,
        marcas: marcaSelecionada,
        rtm: rtmSelecionado
      };

      const resultado = await api.post("/api/enviar-email-solicitacao", data)

      if (resultado) {
        toast.success("Mensagem enviada com sucesso!", {
          position: toast.POSITION.BOTTOM_CENTER
        })
      }

      setEnviando(false);
    } catch (error) {
      setEnviando(false);
      toast.error("Erro ao enviar a mensagem. Tente novamente.", {
        position: toast.POSITION.BOTTOM_CENTER
      })
    }
  }

  useEffect(() => {
    document.title = 'Cerveja Premiada - Solicitação de Acesso';
    buscarMarcas()
  }, []);

  const formEmailCpf = (
    <Form onSubmit={enviarEmail} className="form-login-solicitacao form-login-loja w-100">
      <Row className="w-100">
        <h5>SOLICITAÇÃO DE ACESSO</h5>
      </Row>
      <Row lg={12} className="w-100">
        <Col lg={6} className="d-flex flex-column">
          <label htmlFor="nome" className="mt-4">
            Nome
          </label>
          <Input
            name="nome"
            type="nome"
            placeholder="Nome e Sobrenome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            required
          ></Input>
        </Col>
        <Col lg={6} className="d-flex flex-column">
          <label htmlFor="email" className="mt-4">
            Email Corporativo
          </label>
          <Input
            name="email"
            type="email"
            placeholder="email@dhnk.com.br"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          ></Input>
        </Col>
      </Row>
      <Row lg={12} className="w-100">
        <Col lg={6} className="d-flex flex-column">
          <label htmlFor="emailPbi" className="mt-2">
            Email Power BI
          </label>
          <Input
            name="emailPbi"
            type="email"
            placeholder="email@heiway.com.br"
            value={emailPbi}
            onChange={(e) => setEmailPbi(e.target.value)}
            required
          ></Input>
        </Col>
        <Col lg={6} className="d-flex flex-column">
          <label htmlFor="celular" className="mt-2">
            Celular
          </label>
          <InputMask
            name="celular"
            type="text"
            placeholder="(11) 99999-9999"
            mask="(99) 99999-9999"
            value={celular}
            onChange={(e) => setCelular(e.target.value)}
            required
          ></InputMask>
        </Col>
      </Row>
      <Row lg={12} className="w-100">
        <Col lg={6} className="d-flex flex-column">
          <label htmlFor="cpf" className="mt-2">
            CPF
          </label>
          <InputMask
            name="cpf"
            type="text"
            placeholder="111.111.111-11"
            mask="999.999.999-99"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
            onBlur={validaCpf}
            required
          ></InputMask>
        </Col>
        <Col lg={6} className="d-flex flex-column">
          <label htmlFor="dataNascimento" className="mt-2">
            Data de Nascimento
          </label>
          <Input
            name="dataNascimento"
            type="date"
            value={dataNascimento}
            onChange={(e) => setDataNascimento(e.target.value)}
            required
          ></Input>
        </Col>
        <Col lg={6} className="d-flex flex-column">
          <label htmlFor="marcaSelecionada" className="mt-2">Marcas</label>
          <select
            className="input-select mb-3"
            name="marcaSelecionada"
            id="marcaSelecionada"
            value={marcaSelecionada}
            onChange={(e) =>
              setMarcaSelecionada(e.target.value)
            }
            defaultValue=""
            placeholder=""
            required
          >
            <option value="Todas">Todas</option>
            { marcas ?
                marcas.map(marca => {
                  return (
                    <option
                      value={marca.marca}
                      key={marca.marca}
                    >
                      {marca.marca}
                    </option>
                  )
                })
                :
                ''
              }
          </select>
        </Col>
        <Col lg={6} className="d-flex flex-column">
          <label htmlFor="rtmSelecionado" className="mt-2">RTM</label>
          <select
            className="input-select mb-3"
            name="rtmSelecionado"
            id="rtmSelecionado"
            value={rtmSelecionado}
            onChange={(e) => setRtmSelecionado(e.target.value)}
            defaultValue=""
            placeholder=""
            required
          >
            <option value="Todos">Todos</option>
            {
              rtmSelect.map(rtm => {
                return (
                  <option
                    value={rtm.valor}
                    key={rtm.id}
                  >{rtm.nome}</option>
                )
              })
            }
          </select>
        </Col>
      </Row>
      <Row className="d-flex flex-row justify-content-center">
        {enviando ? (
          <button disabled className="btn-primario mt-4" type="submit">
            ENVIANDO...
          </button>
        ) : (
          <button className="btn-primario mt-4" type="submit">
            ENVIAR
          </button>
        )}
      </Row>
    </Form>
  );

  return (
    <>
      <Header auto />
      <Container className="d-flex flex-column justify-content-center">
        <Row className="justify-content-center m-5">
          {formEmailCpf}
        </Row>
      </Container>
      <Row className="d-md-none">
        <Footer />
      </Row>

      <Row className="d-none d-md-block">
        <Footer fixed />
      </Row>
    </>
  );
}
