import React, { useEffect } from 'react';
import Menu from '../../../components/Painel/Menu';
import './styles.css';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import historico from '../../../services/Historico';
import Dashboard from '../../../components/Painel/Dashboard';
import CadastrarRede from '../../../components/Painel/CadastrarRede';
import CadastrarRegra from '../../../components/Painel/CadastrarRegra';
import CadastrarAdministrador from '../../../components/Painel/CadastrarAdministrador';
import Perfil from '../../../components/Painel/Perfil';
import CadastrarRevenda from '../../../components/Painel/CadastrarRevenda';
import MenuCadastrarProduto from '../../../components/Painel/MenuCadastrarProduto';
import CadastrarProduto from '../../../components/Painel/CadastrarProduto';
import CadastrarProdutoRevenda from '../../../components/Painel/CadastrarProdutoRevenda';
import GarcomSaldo from '../../../components/Painel/GarcomSaldo';
import GarcomSemPontuacao from '../../../components/Painel/GarcomSemPontuacao';
import CadastrarParceiro from '../../../components/Painel/CadastrarParceiro';
import CadastrarPesquisaMercado from '../../../components/Painel/CadastrarPesquisaMercado';
import MenuUploadProduto from '../../../components/Painel/MenuUploadProduto';
import UploadProduto from '../../../components/Painel/UploadProduto';
import UploadProdutoRevenda from '../../../components/Painel/UploadProdutoRevenda';
import UploadVendas from '../../../components/Painel/UploadVendas';
import Redes from '../../../components/Painel/Redes';
import Revendas from '../../../components/Painel/Revendas';
import Produtos from '../../../components/Painel/Produtos';
import Parceiros from '../../../components/Painel/Parceiros';
import Pesquisas from '../../../components/Painel/Pesquisas';
import Pontuacoes from '../../../components/Painel/Pontuacoes';
import DetalheParceiro from '../../../components/Painel/DetalheParceiro';
import DetalheRevenda from '../../../components/Painel/DetalheRevenda';
// import PontuacoesPendente from '../../../components/Painel/PontuacoesPendente';
import NovaVenda from '../../../components/Painel/NovaVenda';
import Resgates from '../../../components/Painel/Resgates';
import UploadHistorico from '../../../components/Painel/UploadHistorico/index';
// import PontuacoesEncerradas from '../../../components/Painel/PontuacoesEncerradas/index';
import PontuacoesAnalise from '../../../components/Painel/PontuacoesAnalise/index';
import IdsResgate from '../../../components/Painel/IdsResgate';
import Mensuracao from '../../../components/Painel/Mensuracao/index';
import Relatorio from '../../../components/Painel/Relatorio/index';
import Historico from '../../../components/Painel/Historico/index';
import Cartoes from '../../../components/Painel/Cartoes/index';
import UploadSaldoCartao from '../../../components/Painel/UploadSaldoCartao/index';
import AtualizarRelatorio from '../../../components/Painel/AtualizarRelatorio/index';
import CorrecaoPontuacao from '../../../components/Painel/CorrecaoPontuacao/index';
// import UploadMidia from '../../../components/Painel/UploadMidia/index';
// import Midias from '../../../components/Painel/Midias/index';
import Premios from '../../../components/Painel/Premios/index';
import CadastrarPremio from '../../../components/Painel/CadastrarPremio/index';
import CadastrarCartao from '../../../components/Painel/CadastrarCartao/index';
import Logs from '../../../components/Painel/Logs/index';
import CadastrarPrograma from '../../../components/Painel/CadastrarPrograma';
import CadastrarParticipantes from '../../../components/Painel/CadastrarParticipantes';
import CadastrarRegiao from '../../../components/Painel/CadastrarRegiao';
// import Adegas from '../../../components/Painel/Adegas';
import ProcessarPontuacao from '../../../components/Painel/ProcessarPontuacao';
import UploadMidia from '../../../components/Painel/UploadMidia';
import Midias from '../../../components/Painel/Midias';
import VerAgir from '../../../components/Painel/VerAgir';
import FotoConcurso from '../../../components/Painel/FotoConcurso';
import Concurso from '../../../components/Painel/Concurso';
import RevendasPreCadastradas from '../../../components/Painel/RevendasPreCadastradas';
import AtivarPdv from '../../../components/Painel/AtivarPdv';
import CadastrarCategoriaFeedback from '../../../components/Painel/CadastrarCategoriaFeedback';
import TableListaParceiroSemAcessar from '../../../components/Painel/TableParceirosSemAcessar';
import TableListaParceiroSemAcessar15 from '../../../components/Painel/TableParceirosSemAcessar15';
import TableListaProprietarioSemAcessar from '../../../components/Painel/TableProprietarioSemAcessar';
import TableProprietarioResgateDisponivel from '../../../components/Painel/TableProprietarioResgateDisponivel';
import TableListaProprietarioRegulamento from '../../../components/Painel/TableProprietarioRegulamento';
import TablePdvSemGarcom from '../../../components/Painel/TablePdvSemGarcom';
import TableListaParceiroRegulamento from '../../../components/Painel/TableParceirosRegulamento';
import TableListaParceiroAniversariantes from '../../../components/Painel/TableListaParceiroAniversariantes';
import TableListaResgateDisponivel from '../../../components/Painel/TableParceirosResgateDisponivel';
import TableListaParceiroEnviouTampa from '../../../components/Painel/TableParceirosEnviouTampa';
import TableListaParceiroPontosAnalise from '../../../components/Painel/TableParceirosPontosAnalise';
// import CadastrarSegmentos from '../../../components/Painel/CadastrarSegmentos';
// import ComprasPontuacao from '../../../components/Painel/ComprasPontuacao/index';
// import TaxaSucesso from '../../../components/Painel/TaxaSucesso/index';
// import Antifraude from '../../../components/Painel/Antifraude/index';
// import ProdutosVendidos from '../../../components/Painel/ProdutosVendidos/index';
import NotificacoesPush from '../../../components/Painel/NotificacoesPush';

export default function Home() {
  let match = useRouteMatch();

  useEffect(() => {
    document.title = 'Cerveja Premiada - Painel';
  }, []);

  return (
    <>
      <Menu
        path={historico.location.pathname}
        selecionarItem={(item) => {
          if (item && historico.location.pathname !== `/${item}`) {
            historico.push(`${match.path}${item}`);
          }
        }}
      />

      <main className="main-painel">
        <Switch>
          <Route
            exact
            path={`${match.path}/dashboard`}
            component={(props) => <Dashboard />}
          />
          <Route
            exact
            path={`${match.path}/ver-agir`}
            component={(props) => <VerAgir />}
          />

          <Route
            exact
            path={`${match.path}/perfil`}
            component={(props) => <Perfil />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/regra`}
            component={(props) => <CadastrarRegra />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/programa`}
            component={(props) => <CadastrarPrograma />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/participantes`}
            component={(props) => <CadastrarParticipantes />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/rede`}
            component={(props) => <CadastrarRede />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/administrador`}
            component={(props) => <CadastrarAdministrador />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/revenda`}
            component={(props) => <CadastrarRevenda />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/produto`}
            component={(props) => <MenuCadastrarProduto />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/produto/criteria`}
            component={(props) => <CadastrarProduto />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/produto/revenda`}
            component={(props) => <CadastrarProdutoRevenda />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/parceiro`}
            component={(props) => <CadastrarParceiro />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/regioes`}
            component={(props) => <CadastrarRegiao />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/cartao`}
            component={(props) => <CadastrarCartao />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/pesquisa-mercado`}
            component={(props) => <CadastrarPesquisaMercado />}
          />
          <Route
            exact
            path={`${match.path}/cadastrar/categoria-feedback`}
            component={(props) => <CadastrarCategoriaFeedback />}
          />
          <Route
            exact
            path={`${match.path}/processar/produtos`}
            component={(props) => <MenuUploadProduto />}
          />

          <Route
            exact
            path={`${match.path}/processar/produtos/criteria`}
            component={(props) => <UploadProduto />}
          />

          <Route
            exact
            path={`${match.path}/processar/produtos/revenda`}
            component={(props) => <UploadProdutoRevenda />}
          />

          <Route
            exact
            path={`${match.path}/processar/vendas`}
            component={(props) => <UploadVendas />}
          />

          <Route
            exact
            path={`${match.path}/processar/historico`}
            component={(props) => <UploadHistorico />}
          />

          <Route
            exact
            path={`${match.path}/processar/pontuacoes`}
            component={(props) => <ProcessarPontuacao />}
          />
          <Route
            exact
            path={`${match.path}/processar/foto-concurso`}
            component={(props) => <FotoConcurso />}
          />

          <Route
            exact
            path={`${match.path}/processar/ids-resgate`}
            component={(props) => <IdsResgate />}
          />

          <Route
            exact
            path={`${match.path}/redes`}
            component={(props) => <Redes />}
          />

          <Route
            exact
            path={`${match.path}/logs`}
            component={(props) => <Logs />}
          />

          <Route
            exact
            path={`${match.path}/pdvs`}
            component={(props) => <Revendas />}
          />

          <Route
            exact
            path={`${match.path}/produtos`}
            component={(props) => <Produtos />}
          />

          <Route
            exact
            path={`${match.path}/garcons`}
            component={(props) => <Parceiros />}
          />

          <Route
            exact
            path={`${match.path}/garcons/saldo`}
            component={(props) => <GarcomSaldo />}
          />

          <Route
            exact
            path={`${match.path}/garcons/nao-pontuaram`}
            component={(props) => <GarcomSemPontuacao />}
          />

          <Route
            exact
            path={`${match.path}/pesquisa-de-mercado`}
            component={(props) => <Pesquisas />}
          />

          <Route
            exact
            path={`${match.path}/pontuacoes/pontuado`}
            component={(props) => <Pontuacoes />}
          />

          {/* <Route
            exact
            path={`${match.path}/pontuacoes/encerrado`}
            component={(props) => <PontuacoesEncerradas />}
          /> */}
          <Route
            exact
            path={`${match.path}/pontuacoes/analise`}
            component={(props) => <PontuacoesAnalise />}
          />

          <Route
            exact
            path={`${match.path}/parceiro/:cpf`}
            component={(props) => <DetalheParceiro />}
          />

          <Route
            exact
            path={`${match.path}/revenda/:cnpj`}
            component={(props) => <DetalheRevenda />}
          />
          {/*
          <Route
            exact
            path={`${match.path}/processar/pontuacoes`}
            component={(props) => <PontuacoesPendente />}
          /> */}

          <Route
            exact
            path={`${match.path}/processar/nova-venda`}
            component={(props) => <NovaVenda />}
          />

          <Route
            exact
            path={`${match.path}/processar/relatorios`}
            component={(props) => <AtualizarRelatorio />}
          />

          <Route
            exact
            path={`${match.path}/processar/correcao-pontuacao`}
            component={(props) => <CorrecaoPontuacao />}
          />

          <Route
            exact
            path={`${match.path}/resgates`}
            component={(props) => <Resgates />}
          />

          <Route
            exact
            path={`${match.path}/notificacoes-push`}
            component={(props) => <NotificacoesPush />}
          />

          <Route
            exact
            path={`${match.path}/mensuracao`}
            component={(props) => <Mensuracao />}
          />

          <Route
            exact
            path={`${match.path}/relatorio`}
            component={(props) => <Relatorio />}
          />

          <Route
            exact
            path={`${match.path}/historico`}
            component={(props) => <Historico />}
          />

          <Route
            exact
            path={`${match.path}/cartoes`}
            component={(props) => <Cartoes />}
          />

          <Route
            exact
            path={`${match.path}/processar/saldo-cartao`}
            component={(props) => <UploadSaldoCartao />}
          />

          <Route
            exact
            path={`${match.path}/processar/foto-video`}
            component={(props) => <UploadMidia />}
          />

          <Route
            exact
            path={`${match.path}/fotos-videos`}
            component={(props) => <Midias />}
          />

          <Route
            exact
            path={`${match.path}/premios`}
            component={(props) => <Premios />}
          />

          <Route
            exact
            path={`${match.path}/cadastrar/premio`}
            component={(props) => <CadastrarPremio />}
          />

          <Route
            exact
            path={`${match.path}/fotos-concurso`}
            component={(props) => <Concurso />}
          />

          {/* <Route
            exact
            path={`${match.path}/adegas`}
            component={(props) => <Adegas />}
          /> */}
          {/* <Route
            exact
            path={`${match.path}/taxa-sucesso`}
            component={(props) => <TaxaSucesso />}
          /> */}
          {/* <Route
            exact
            path={`${match.path}/antifraude`}
            component={(props) => <Antifraude />}
          /> */}

          {/* <Route
            exact
            path={`${match.path}/produtos-vendidos`}
            component={(props) => <ProdutosVendidos />}
          /> */}

          {/*
          <Route
            exact
            path={`${match.path}/compras-pontuacao`}
            component={(props) => <ComprasPontuacao />}
          /> */}
          {/* <Route
            exact
            path={`${match.path}/cadastrar/segmentos`}
            component={(props) => <CadastrarSegmentos />}
          /> */}
          <Route
            exact
            path={`${match.path}/pdvs-pre-cadastrados`}
            component={(props) => <RevendasPreCadastradas />}
          />
          <Route
            exact
            path={`${match.path}/cadastrar/ativar-pdv`}
            component={(props) => <AtivarPdv />}
          />
          <Route
            exact
            path={`${match.path}/parceiros-sem-acessar`}
            component={(props) => <TableListaParceiroSemAcessar />}
          />
          <Route
            exact
            path={`${match.path}/parceiros-sem-acessar-15`}
            component={(props) => <TableListaParceiroSemAcessar15 />}
          />
          <Route
            exact
            path={`${match.path}/proprietario-sem-acessar`}
            component={(props) => <TableListaProprietarioSemAcessar />}
          />
          <Route
            exact
            path={`${match.path}/proprietario-resgate-disponivel`}
            component={(props) => <TableProprietarioResgateDisponivel />}
          />
          <Route
            exact
            path={`${match.path}/proprietario-regulamento`}
            component={(props) => <TableListaProprietarioRegulamento />}
          />
          <Route
            exact
            path={`${match.path}/pdv-sem-garcom`}
            component={(props) => <TablePdvSemGarcom />}
          />
          <Route
            exact
            path={`${match.path}/garcom-regulamento`}
            component={(props) => <TableListaParceiroRegulamento />}
          />
          <Route
            exact
            path={`${match.path}/garcom-aniversariantes`}
            component={(props) => <TableListaParceiroAniversariantes />}
          />
          <Route
            exact
            path={`${match.path}/garcom-resgate-disponivel`}
            component={(props) => <TableListaResgateDisponivel />}
          />
          <Route
            exact
            path={`${match.path}/garcom-enviou-tampa`}
            component={(props) => <TableListaParceiroEnviouTampa />}
          />
          <Route
            exact
            path={`${match.path}/garcom-pontos-analise`}
            component={(props) => <TableListaParceiroPontosAnalise />}
          />
        </Switch>
      </main>
    </>
  );
}
