const itens = [
  {
    destino: '/dashboard',
    titulo: 'Dashboard',
    icone: 'fa fa-fw fa-home',
    permissao: [0, 1, 2, 3, 4],
    idTemporada: [1, 2],
  },
  {
    destino: '/mensuracao',
    titulo: 'Mensuração',
    icone: 'fa fa-fw fa-chart-line',
    permissao: [0, 1, 2, 3, 4],
    idTemporada: [1, 2],
  },
  {
    destino: '/historico',
    titulo: 'Histórico',
    icone: 'fa fa-fw fa-chart-area',
    permissao: [0, 1, 2, 3, 4],
    idTemporada: [1, 2],
  },
  {
    destino: '/notificacoes-push',
    titulo: 'Notificações Push',
    icone: 'fa fa-fw fa-mobile',
    permissao: [2],
    idTemporada: [1, 2],
  },
  {
    destino: '/ver-agir',
    titulo: 'Ver e Agir',
    icone: 'fa fa-fw fa-dolly',
    permissao: [2, 1],
    idTemporada: [1, 2],
  },
  {
    destino: '/relatorio',
    titulo: 'Relatório',
    icone: 'fa fa-fw fa-paste',
    permissao: [0, 1, 2, 4],
    idTemporada: [1, 2],
  },
  {
    destino: '/produtos',
    titulo: 'Produtos',
    icone: 'fa fa-fw fa-box',
    permissao: [0, 1, 2, 4],
    idTemporada: [1, 2],
  },
  {
    destino: '/premios',
    titulo: 'Prêmios',
    icone: 'fa fa-fw fa-gifts',
    permissao: [2],
    idTemporada: [1, 2],
  },
  {
    destino: '/pontuacoes',
    titulo: 'Pontuações',
    icone: 'fa fa-fw fa-user-plus',
    permissao: [0, 1, 2, 3, 4],
    idTemporada: [1, 2],
    subItens: [
      {
        destino: '/pontuado',
        titulo: 'Pontuado',
        permissao: [0, 1, 2, 3, 4],
        idTemporada: [1, 2],
      },
      {
        destino: '/analise',
        titulo: 'Em análise',
        permissao: [0, 1, 2, 3, 4],
        idTemporada: [1, 2],
      },
      // {
      //   destino: '/encerrado',
      //   titulo: 'Encerrado',
      //   permissao: [0, 1, 2, 3],
      // },
    ],
  },
  {
    destino: '/pesquisa-de-mercado',
    titulo: 'Pesquisa de Mercado',
    icone: 'fa fa-fw fa-file-signature',
    permissao: [1, 2, 3],
    idTemporada: [1, 2],
  },
  {
    destino: '/resgates',
    titulo: 'Resgates',
    icone: 'fa fa-fw fa-gift',
    permissao: [0, 1, 2],
    idTemporada: [1, 2],
  },
  {
    destino: '/fotos-videos',
    titulo: 'Fotos e Vídeos',
    icone: 'fa fa-fw fa-camera',
    permissao: [1, 2, 3],
    idTemporada: [1, 2],
  },
  {
    destino: '/fotos-concurso',
    titulo: 'Concurso',
    icone: 'fa fa-fw fa-award',
    permissao: [1, 2, 3],
    idTemporada: [1, 2],
  },
  {
    destino: '/garcons',
    titulo: 'Garçons',
    icone: 'fa fa-fw fa-users',
    permissao: [0, 1, 2, 3, 4],
    idTemporada: [1, 2],
  },
  {
    destino: '/cartoes',
    titulo: 'Cartões',
    icone: 'fa fa-fw fa-credit-card',
    permissao: [1, 2],
    idTemporada: [1, 2],
  },
  {
    destino: '/pdvs',
    titulo: 'PDVs',
    icone: 'fa fa-fw fa-store',
    permissao: [0, 1, 2, 3, 4],
    idTemporada: [1, 2],
  },
  {
    destino: '/pdvs-pre-cadastrados',
    titulo: 'PDVs Pré-Cadastrados',
    icone: 'fa fa-fw fa-store',
    permissao: [0, 1, 2, 3, 4],
    idTemporada: [2],
  },
  // {
  //   destino: '/adegas',
  //   titulo: 'Adegas',
  //   icone: 'fa fa-fw fa-industry',
  //   permissao: [0, 1, 2, 3],
  // },
  {
    destino: '/logs',
    titulo: 'Logs',
    icone: 'fa fa-fw fa-file-alt',
    permissao: [2],
    idTemporada: [1, 2],
  },
  {
    destino: '/cadastrar',
    titulo: 'Cadastrar',
    icone: 'fa fa-fw fa-edit',
    permissao: [1, 2],
    idTemporada: [1, 2],
    subItens: [
      {
        destino: '/administrador',
        titulo: 'Administrador',
        permissao: [2],
        idTemporada: [1, 2],
      },
      {
        destino: '/regra',
        titulo: 'Regra',
        permissao: [2],
        idTemporada: [1, 2],
      },
      {
        destino: '/programa',
        titulo: 'Programa',
        permissao: [2],
        idTemporada: [1, 2],
      },
      {
        destino: '/participantes',
        titulo: 'Participantes',
        permissao: [2],
        idTemporada: [1, 2],
      },
      {
        destino: '/segmentos',
        titulo: 'Segmentos',
        permissao: [2],
        idTemporada: [1, 2],
      },
      {
        destino: '/regioes',
        titulo: 'Regiões',
        permissao: [2],
        idTemporada: [1, 2],
      },
      {
        destino: '/ativar-pdv',
        titulo: 'Ativar PDV',
        permissao: [1, 2],
        idTemporada: [2],
      },
      {
        destino: '/revenda',
        titulo: 'PDV',
        permissao: [2],
        idTemporada: [1, 2],
      },
      {
        destino: '/categoria-feedback',
        titulo: 'Categoria Feedback',
        permissao: [2],
        idTemporada: [1, 2],
      },
      {
        destino: '/cartao',
        titulo: 'Cartão',
        permissao: [2],
        idTemporada: [1, 2],
      },
      {
        destino: '/premio',
        titulo: 'Prêmio',
        permissao: [2],
        idTemporada: [1, 2],
      },
      {
        destino: '/produto',
        titulo: 'Produto',
        permissao: [2],
        idTemporada: [1, 2],
      },
      {
        destino: '/parceiro',
        titulo: 'Garçom',
        permissao: [2],
        idTemporada: [1, 2],
      },
      {
        destino: '/pesquisa-mercado',
        titulo: 'Pesquisa de Mercado',
        permissao: [2],
        idTemporada: [1, 2],
      },
    ],
  },
  {
    destino: '/processar',
    titulo: 'Processar',
    icone: 'fa fa-fw fa-upload',
    permissao: [1, 2],
    idTemporada: [1, 2],
    subItens: [
      {
        destino: '/historico',
        titulo: 'Histórico',
        permissao: [0, 1, 2, 3, 4],
        idTemporada: [1, 2],
      },
      {
        destino: '/pontuacoes',
        titulo: 'Pontuações',
        permissao: [0, 1, 2, 3, 4],
        idTemporada: [1, 2],
      },
      {
        destino: '/ids-resgate',
        titulo: 'Ids de Resgate',
        permissao: [1, 2],
        idTemporada: [1, 2],
      },
      // {
      //   destino: '/saldo-cartao',
      //   titulo: 'Saldo Cartão',
      //   permissao: [0, 1, 2, 3],
      // },
      {
        destino: '/relatorios',
        titulo: 'Relatórios',
        permissao: [0, 1, 2, 3, 4],
        idTemporada: [1, 2],
      },
      {
        destino: '/foto-video',
        titulo: 'Foto/Vídeo',
        permissao: [0, 1, 2, 3, 4],
        idTemporada: [1, 2],
      },
      {
        destino: '/foto-concurso',
        titulo: 'Foto Concurso',
        permissao: [0, 1, 2, 3, 4],
        idTemporada: [1, 2],
      }
    ],
  },
];

export default itens;
