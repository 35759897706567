import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { isCnpj, isCpf } from '../../../helpers/validators';

import { isEmpty } from 'lodash';
export default function CadastrarRevenda() {
  useEffect(() => {
    carregarProgramas();
    carregarRegioes();
    carregarSegmentos();
  }, []);

  const [enviando, setEnviando] = useState(false);
  const [codigo, setCodigo] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [endRua, setRua] = useState('');
  const [endNumero, setNumero] = useState('');
  const [endComplemento, setComplemento] = useState('');
  const [endBairro, setBairro] = useState('');
  const [endCidade, setCidade] = useState('');
  const [endEstado, setEstado] = useState('');
  const [endCep, setCep] = useState('');
  const [responsavel, setResponsavel] = useState('');
  const [cpf, setCpf] = useState('');
  const [celular, setCelular] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [regiao, setRegiao] = useState('');
  const [programas, setProgramas] = useState('');
  const [segmentos, setSegmentos] = useState('');
  const [idTemporada, setIdTemporada] = useState('');

  const [programasSelect, setProgramasSelect] = useState([]);
  const [regioesSelect, setRegioesSelect] = useState([]);
  const [segmentosSelect, setSegmentosSelect] = useState([]);

  const validarTelefoneCelular = async (e) => {
    const valor = e.target.value.replace(/[^\d]/g, '');
    if (!valor) return;
    if (valor.length < 10) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      e.target.placeholder === 'Telefone' ? setTelefone('') : setCelular('');
    }
  };

  // const validarCampos = (data) => {
  //   const entries = Object.entries(data);
  //   let temNulo = false;
  //   entries.forEach((param) => {
  //     if (temNulo) return;
  //     if (
  //       param[1] === '' &&
  //       param[0] !== 'endComplemento' &&
  //       param[0] !== 'telefone' &&
  //       param[0] !== 'cpf' &&
  //       param[0] !== 'responsavel' &&
  //       param[0] !== 'celular'
  //     ) {
  //       const elemento = document.getElementsByName(param[0])[0];
  //       toast.error(`${elemento.placeholder} obrigatório`, {
  //         position: toast.POSITION.BOTTOM_CENTER,
  //         autoClose: 10000,
  //       });
  //       temNulo = true;
  //       return;
  //     }
  //   });

  //   return temNulo;
  // };

  const zerarForm = () => {
    setCodigo('');
    setProgramas('');
    setCnpj('');
    setRazaoSocial('');
    setNomeFantasia('');
    setRua('');
    setNumero('');
    setComplemento('');
    setBairro('');
    setCidade('');
    setCpf('');
    setCep('');
    setEstado('');
    setResponsavel('');
    setEmail('');
    setCelular('');
    setSegmentos('');
    setTelefone('');
    setIdTemporada('');
  };

  const carregarProgramas = async () => {
    const resultado = await api.get('/api/programas?ativo=1');
    if (resultado) {
      setProgramasSelect(resultado.data.programas);
    }
  };
  const carregarSegmentos = async () => {
    const resultado = await api.get('/api/segmentos?ativo=1');
    if (resultado) {
      setSegmentosSelect(resultado.data.segmentos);
    }
  };
  const carregarRegioes = async () => {
    const resultado = await api.get('/api/regioes?ativo=1');
    if (resultado) {
      setRegioesSelect(resultado.data.regioes);
    }
  };

  const buscarCep = async (element) => {
    let input = element.target;
    let cep = input.value.replace(/[^\d]/g, '');
    if (!cep) return;
    const resultado = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
    if (!resultado || resultado.data.erro) {
      toast.error('CEP inválido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCep('');
    } else {
      setRua(resultado.data.logradouro);
      setBairro(resultado.data.bairro);
      setCidade(resultado.data.localidade);
      setEstado(resultado.data.uf);
      setComplemento(resultado.data.complemento);
    }
  };

  const buscarCnpj = async (element) => {
    let input = element.target;
    let cnpj = input.value.replace(/[^\d]/g, '');

    if (!isCnpj(cnpj) && !isCpf(cnpj)) {
      toast.error('CNPJ ou CPF inválido.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCnpj('');
      return;
    }

    // const resultado = await api.get(`/api/revendas/cnpj/${cnpj}`);
    // if (!isEmpty(resultado.data.revendas)) {
    //   toast.error('CPF ou CNPJ já cadastrado', {
    //     position: toast.POSITION.BOTTOM_CENTER,
    //     autoClose: 10000,
    //   });
    //   setCnpj('');
    // }
  };

  const cadastrarRevenda = async (e) => {
    e.preventDefault();
    const data = {
      programas,
      codigo,
      cnpj,
      segmentos,
      cpf,
      nomeFantasia,
      razaoSocial,
      endRua,
      endNumero,
      endComplemento,
      endBairro,
      endCidade,
      endEstado,
      endCep,
      responsavel,
      email,
      telefone,
      celular,
      idTemporada: Number(idTemporada),
    };

    data.endCep = data.endCep.replace(/[^\d]/g, '');
    data.cnpj = data.cnpj.replace(/[^\d]/g, '');
    data.cpf = data.cpf.replace(/[^\d]/g, '');
    data.telefone = data.telefone.replace(/[^\d]/g, '');
    data.celular = data.celular.replace(/[^\d]/g, '');

    setEnviando(true);
    const resultado = await api.post('/api/revendas', data);

    if (resultado) {
      toast.success('PDV cadastrado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      zerarForm();
    }
    setEnviando(false);
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar PDV</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={cadastrarRevenda}>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={12}>
              <label htmlFor="programa">Selecione o programa</label>
              <select
                id="programa"
                name="programa"
                value={programas}
                onChange={(e) => setProgramas(e.target.value)}
              >
                <option disabled value="">
                  Selecione
                </option>
                {programasSelect.map((programa) => (
                  <option key={programa.nome} value={programa.id}>
                    {programa.nome}
                  </option>
                ))}
              </select>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="codigo">Cód. PDV</label>
              <input
                id="codigo"
                name="codigo"
                type="text"
                placeholder="Código"
                value={codigo}
                onChange={(e) => setCodigo(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="name_pdv">PDV</label>
              <input
                id="name_pdv"
                name="name_pdv"
                type="text"
                placeholder="Nome Fantasia"
                value={nomeFantasia}
                onChange={(e) => setNomeFantasia(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="cnpj">CPF / CNPJ</label>
              <InputMask
                id="cnpj"
                name="cnpj"
                type="text"
                placeholder="CPF / CNPJ"
                value={cnpj}
                onChange={(e) => setCnpj(e.target.value)}
                maxLength={14}
                onBlur={buscarCnpj}
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="segmentos">Segmento</label>
              <select
                id="segmentos"
                name="segmentos"
                value={segmentos}
                onChange={(e) => setSegmentos(e.target.value)}
              >
                <option disabled value="">
                  Selecione
                </option>
                {segmentosSelect.map((segmento) => (
                  <option key={segmento.nome} value={segmento.nome}>
                    {segmento.nome}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={12}>
              <label htmlFor="razaoSocial">Razão Social</label>
              <input
                id="razaoSocial"
                name="razaoSocial"
                type="text"
                placeholder="Razão Social"
                value={razaoSocial}
                onChange={(e) => setRazaoSocial(e.target.value)}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12}>
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="telefone">Telefone</label>
              <InputMask
                id="telefone"
                name="telefone"
                type="text"
                placeholder="Telefone"
                mask="(99) 9999-9999"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
                onBlur={validarTelefoneCelular}
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="regiao">Região</label>
              <select
                id="regiao"
                name="regiao"
                value={regiao}
                onChange={(e) => setRegiao(e.target.value)}
              >
                <option disabled value="">
                  Selecione
                </option>
                {regioesSelect.map((regiao) => (
                  <option key={regiao.nome} value={regiao.nome}>
                    {regiao.nome}
                  </option>
                ))}
              </select>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="responsavel">Responsável</label>
              <input
                id="responsavel"
                name="responsavel"
                type="text"
                placeholder="Nome e sobrenome"
                value={responsavel}
                onChange={(e) => setResponsavel(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="cpf">CPF</label>
              <InputMask
                id="cpf"
                name="cpf"
                type="text"
                placeholder="CPF"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                mask="999.999.999-99"
              ></InputMask>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="celular">Celular Responsável</label>
              <InputMask
                id="celular"
                name="celular"
                type="text"
                placeholder="Celular"
                value={celular}
                mask="(99) 99999-9999"
                onChange={(e) => setCelular(e.target.value)}
                onBlur={validarTelefoneCelular}
              ></InputMask>
            </Col>

            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endCep">CEP</label>
              <InputMask
                id="endCep"
                name="endCep"
                type="text"
                placeholder="CEP"
                mask="99999-999"
                onBlur={buscarCep}
                value={endCep}
                onChange={(e) => setCep(e.target.value)}
              ></InputMask>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={9}>
              <label htmlFor="endRua">Endereço</label>
              <input
                id="endRua"
                name="endRua"
                type="text"
                placeholder="Endereço"
                value={endRua}
                onChange={(e) => setRua(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={3}>
              <label htmlFor="endNumero">Número</label>
              <input
                id="endNumero"
                name="endNumero"
                type="text"
                placeholder="Número"
                value={endNumero}
                onChange={(e) => setNumero(e.target.value)}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endBairro">Bairro</label>
              <input
                id="endBairro"
                name="endBairro"
                type="text"
                placeholder="Bairro"
                value={endBairro}
                onChange={(e) => setBairro(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endComplemento">Complemento</label>
              <input
                id="endComplemento"
                name="endComplemento"
                type="text"
                placeholder="Complemento"
                value={endComplemento}
                onChange={(e) => setComplemento(e.target.value)}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endEstado">Estado</label>
              <input
                id="endEstado"
                name="endEstado"
                type="text"
                placeholder="Estado"
                value={endEstado}
                onChange={(e) => setEstado(e.target.value)}
                maxLength={2}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endCidade">Cidade</label>
              <input
                id="endCidade"
                name="endCidade"
                type="text"
                placeholder="Cidade"
                value={endCidade}
                onChange={(e) => setCidade(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="temporada">Temporada</label>
              <select
                id="temporada"
                name="temporada"
                value={idTemporada}
                onChange={(e) => setIdTemporada(e.target.value)}
              >
                <option disabled value="">
                  Selecione a temporada
                </option>

                <option value="1">Temporada 2021</option>
                <option value="2">Temporada 2022</option>
              </select>
            </Col>
          </Row>

          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              CADASTRAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
