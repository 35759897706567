import axios from 'axios';
import { toast } from 'react-toastify';

import { store } from '../store/index';
import { desautenticar } from '../store/modules/autenticacao/actions';
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: { AppLocation: window.location.href },
});

function errorResponseHandler(error) {
  if (
    error.config.hasOwnProperty('errorHandle') &&
    error.config.errorHandle === false
  ) {
    return Promise.reject(error);
  }

  if (!error.response) {
    const idToast = 'semConexao';
    if (toast.isActive(idToast)) {
      return;
    }

    toast.error('Problema de conexão ao servidor, tente novamente mais tarde', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
      toastId: idToast,
    });

    store.dispatch(desautenticar());

    return;
  }

  if (error.response.status === 401) {
    store.dispatch(desautenticar());
  }

  if (error.response.data.erro) {
    toast.error(error.response.data.erro, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
    });
  }

  if (error.response.data.mensagem) {
    return error.response;
  }
}

// apply interceptor on response
api.interceptors.response.use((response) => response, errorResponseHandler);

export default api;
