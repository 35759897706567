export function isCpf(y) {
  const x = y.replace(/\D/g, '');

  if (x.length > 11) {
    return false;
  }

  let soma = 0;
  let resto;
  let i = 0;
  if (x === '00000000000') {
    return false;
  }
  for (i = 1; i <= 9; i += 1) {
    soma += parseInt(x.substring(i - 1, i), 10) * (11 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== parseInt(x.substring(9, 10), 10)) {
    return false;
  }
  soma = 0;
  for (i = 1; i <= 10; i++) {
    soma += parseInt(x.substring(i - 1, i), 10) * (12 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== parseInt(x.substring(10, 11), 10)) {
    return false;
  }
  return true;
}

export function isCnpj(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '');
  // verificando se tem a quantidade certa de caracter e se não tem todos caracteres iguais
  if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) return false;
  let t = cnpj.length - 2,
    d = cnpj.substring(t),
    d1 = parseInt(d.charAt(0)),
    d2 = parseInt(d.charAt(1)),
    calc = (x) => {
      let n = cnpj.substring(0, x),
        y = x - 7,
        s = 0,
        r = 0;
      for (let i = x; i >= 1; i--) {
        s += n.charAt(x - i) * y--;
        if (y < 2) y = 9;
      }
      r = 11 - (s % 11);
      return r > 9 ? 0 : r;
    };
  return calc(t) === d1 && calc(t + 1) === d2;
}
