/*  eslint-disable-next-line */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import api from '../../../services/API';
import './styles.css';

export default function EnviarPontuacao(props) {
  useEffect(() => {
    carregarProdutos();
  }, []);

  const [enviando, setEnviando] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [produtosSelect, setProdutosSelect] = useState([]);
  const [embalagensSelect, setEmbalagensSelect] = useState([]);
  const [produtoSelecionado, setProdutoSelecionado] = useState('');
  const [embalagemSelecionada, setEmbalagemSelecionada] = useState('');
  const [quantidade, setQuantidade] = useState('');
  const [concordarTermosEnvio, setConcordarTermosEnvio] = useState(false);
  const [itens, setItens] = useState([]);
  const [pontuacaoPrevista, setPontuacaoPrevista] = useState(0);
  const [arquivos, setArquivos] = useState([]);
  const [status, setStatus] = useState('Procurar...');

  useEffect(() => {
    if (itens.length > 0) {
      calcularPontuacao();
    } else {
      setPontuacaoPrevista(0);
    }
  }, [itens]);

  const setarMarca = (e) => {
    e.preventDefault();
    setProdutoSelecionado(e.target.value);
    const produtosFiltradosDaMarca = produtos
      .filter((produto) => produto.nome.split(' - ')[0] === e.target.value)
      .map((produto) => produto.nome.split(' - ')[1]);
    setEmbalagensSelect(produtosFiltradosDaMarca);
  };

  const clearState = () => {
    setProdutoSelecionado('');
    setEmbalagemSelecionada('');
    setQuantidade('');
    setConcordarTermosEnvio(false);
    setEmbalagensSelect([]);
    setItens([]);
    setArquivos(null);
    setStatus('Procurar...');
  };

  const addPontuacao = (e) => {
    e.preventDefault();

    if (!produtoSelecionado) {
      toast.error('Por favor selecione um produto', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });

      return;
    }

    if (!embalagemSelecionada) {
      toast.error('Por favor selecione uma embalagem', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });

      return;
    }

    if (quantidade <= 0) {
      toast.error('A quantidade deve ser maior que zero', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });

      return;
    }

    setItens([
      ...itens,
      {
        produto: `${produtoSelecionado} - ${embalagemSelecionada}`,
        quantidade: quantidade,
      },
    ]);

    setProdutoSelecionado('');
    setEmbalagemSelecionada('');
    setQuantidade('');
  };

  const enviarPontuacao = async (e) => {
    e.preventDefault();

    if (!concordarTermosEnvio) {
      toast.success('Você deve concordar com os termos', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (arquivos.length === 0) {
      toast.error(`Você precisa enviar uma foto das suas tampas.`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    let formData = new FormData();

    for (let i = 0; i < arquivos.length; i++) {
      formData.append('fotos', arquivos[i]);
    }

    formData.append('itens', JSON.stringify(itens));
    formData.append('concordarTermosEnvio', concordarTermosEnvio);

    setEnviando(true);
    const resultado = await api.post('/api/pontuacoes/pontuar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (resultado) {
      toast.success('Pontuação enviada com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      clearState();
    }
    setEnviando(false);
  };

  const calcularPontuacao = async () => {
    const pontuacao = {
      itens,
      concordarTermosEnvio,
    };

    const resultado = await api.post('/api/pontuacoes/calcular', pontuacao);

    if (resultado) {
      setPontuacaoPrevista(resultado.data.pontuacao);
    }
  };

  const carregarProdutos = async () => {
    const resultado = await api.get('/api/produtos?ativo=1');
    if (resultado) {
      const data = resultado.data.produtos;
      setProdutos(data);
      const produtos = [
        ...new Map(
          data.map((item) => [item['nome'].split(' - ')[0], item])
        ).values(),
      ].map((produto) => produto.nome.split(' - ')[0]);

      setProdutosSelect(produtos);
    }
  };

  const date = moment().format('DD/MM/YYYY');

  useEffect(() => {
    if (itens.length === 0) return;
    if (!arquivos) {
      document.getElementById('arquivos').value = null;
    }
  }, [arquivos]);

  return (
    <form className="w-100 form-send-points" onSubmit={enviarPontuacao}>
      <Row
        className={`${props.cname || `bg-send-points`} p-3 align-items-center`}
      >
        <Col xs={12} lg={props.large || 4}>
          <div>
            <h5>Enviar Pontuação</h5>
            <p>
              Selecione o produto, a embalagem e a quantidade que você vendeu e
              lance seus pontos de maneira simples, adicionando todos os itens
              em poucos cliques.
            </p>
          </div>
        </Col>
        <Col xs={12} md={7}>
          <Row className="d-flex">
            <Col className="space-between-cols" xs={12} sm={6} lg={4}>
              <div className="d-flex flex-column">
                <label className={props.text === 'white' && 'text-white'}>
                  Qual produto você vendeu?
                </label>
                <select
                  id="produto"
                  name="produto"
                  value={produtoSelecionado}
                  onChange={setarMarca}
                >
                  <option value="" disabled>
                    Selecione
                  </option>

                  {produtosSelect.map((produto) => (
                    <option key={produto} value={produto}>
                      {produto}
                    </option>
                  ))}
                </select>
              </div>
            </Col>

            <Col className="space-between-cols" xs={12} sm={6} lg={4}>
              <div className="d-flex flex-column">
                <label className={props.text === 'white' && 'text-white'}>
                  Selecione a embalagem
                </label>
                <select
                  id="embalagem"
                  name="embalagem"
                  value={embalagemSelecionada}
                  onChange={(e) => setEmbalagemSelecionada(e.target.value)}
                >
                  <option value="" disabled>
                    Selecione
                  </option>

                  {embalagensSelect.map((embalagem) => (
                    <option key={embalagem} value={embalagem}>
                      {embalagem}
                    </option>
                  ))}
                </select>
              </div>
            </Col>

            <Col className="space-between-cols" xs={12} sm={8} lg={3}>
              <div className="d-flex flex-column">
                <label className={props.text === 'white' && 'text-white'}>
                  Quantidade
                </label>
                <input
                  type="number"
                  min="0"
                  id="quantidade"
                  name="quantidade"
                  value={quantidade}
                  onChange={(e) => setQuantidade(e.target.value)}
                ></input>
              </div>
            </Col>
            <Col className="space-between-cols" xs={12} sm={1}>
              <div className="d-flex pt-4">
                <label
                  className={props.text === 'white' && 'text-white'}
                ></label>
                <button
                  type="button"
                  onClick={addPontuacao}
                  className="btn-resgatar"
                  style={{ width: '125px' }}
                >
                  <i className="fas fa-plus"></i>
                </button>
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs={12}>
          {itens.length > 0 && (
            <div>
              <Row className="pt-5">
                <Col>
                  <h5>Detalhes do seu envio:</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  {itens.map((data) => (
                    <div>
                      <p>
                        {Number(data.quantidade).toLocaleString('pt-BR')}{' '}
                        UNIDADE(S) - {data.produto}
                      </p>
                    </div>
                  ))}
                </Col>
              </Row>

              <Row className="mt-5 mb-5">
                <Col xs={12}>
                  <h6>Enviar fotos das tampas</h6>
                  <input
                    type="file"
                    id="arquivos"
                    name="arquivos"
                    multiple
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      setArquivos(e.target.files);
                      e.target.files.length > 0
                        ? setStatus(
                            `${e.target.files.length} foto(s) selecionada(s)`
                          )
                        : setStatus('Procurar...');
                    }}
                  />
                  <input
                    id="botao"
                    type="button"
                    value={status}
                    className={`input-foto-tampa ${
                      props.text === 'white' && 'text-white'
                    }`}
                    onClick={(e) => {
                      document.getElementById('arquivos').click();
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <p>
                    {`As quantidades acima enviadas em ${date} representam um total
              de ${pontuacaoPrevista.toLocaleString(
                'pt-BR'
              )} pontos, que serão creditados até o dia 20 do mês seguinte.`}
                  </p>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col xs={12} md={12}>
                  <div className="d-flex w-100 align-items-center">
                    <input
                      type="radio"
                      id="concordo-input"
                      name="concordo-input"
                      checked={concordarTermosEnvio}
                      onChange={(e) => setConcordarTermosEnvio(true)}
                    />

                    <label
                      htmlFor="concordo-input"
                      className={`concordo pl-2 ${
                        props.text === 'white' && 'text-white'
                      }`}
                    >
                      CONCORDO EM GUARDAR AS TAMPAS POR UM PERÍODO DE 30 DIAS{' '}
                      <br />
                      PARA POSSÍVEL REVISÃO DA PONTUAÇÃO.
                    </label>
                  </div>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col xs={12} md={6}>
                  <div className="d-flex flex-column">
                    <input
                      className="btn-send-points"
                      type="submit"
                      value={enviando ? 'ENVIANDO' : 'ENVIAR'}
                      disabled={enviando}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </form>
  );
}
