import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import historico from '../../../services/Historico';

export default function CadastrarRegiao() {
  const [enviando, setEnviando] = useState(false);
  const [nome, setNome] = useState('');
  const [idTemporada, setIdTemporada] = useState('');
  const cadastrarRegiao = async (e) => {
    e.preventDefault();
    const data = {
      nome,
      idTemporada,
    };

    setEnviando(true);
    const resultado = await api.post('/api/regioes', data);

    if (resultado) {
      toast.success('Região cadastrada com sucesso!', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      historico.push('/painel/cadastrar/regioes');
    }
    setEnviando(false);
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Regiões</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={cadastrarRegiao}>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={12}>
              <label htmlFor="nome">Nome</label>
              <input
                id="nome"
                name="nome"
                type="text"
                placeholder="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={12}>
              <label htmlFor="pdvSelecionado">Temporada</label>
              <select
                className="input-select mb-3"
                name="temporada"
                id="temporada"
                defaultValue=""
                placeholder="Selecione"
                value={idTemporada}
                onChange={(e) => setIdTemporada(e.target.value)}
              >
                <option disabled value="">
                  Selecione
                </option>

                <option value="1">Temporada 2021</option>
                <option value="2">Temporada 2022</option>
              </select>
            </Col>
          </Row>

          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              CADASTRAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
