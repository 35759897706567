import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import { useEffect } from 'react';
import { sortBy } from 'lodash';
import './styles.css';
import { useSelector } from 'react-redux';

export default function IdsResgate() {
  const tipoUsuario = useSelector((state) => state.autenticacao.tipoUsuario)
  const [status, setStatus] = useState('');
  const [idsInvalidos, setIdsInvalidos] = useState([]);

  const updateStatus = async (e) => {
    e.preventDefault()

    const ids = document.getElementById("ids").value.split('\n').filter(item => item !== '')

    if (tipoUsuario !== 'administrador') return toast.error('Você não tem permissão para realizar essa tarefa', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
    })

    if (ids.length === 0) return toast.error('Insira pelo menos um ID', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
    })

    if (status === '') return toast.error('Selecione um status', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
    })

    const response = await api.put('/api/resgates/alterarStatusEmMassa', { ids, status })

    if (!response || response.status !== 200) {
      if (response?.data.idsInvalidos) setIdsInvalidos(response?.data.idsInvalidos)
      return toast.error(response?.data.mensagem || 'Erro ao alterar o status', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      })
    }

    setIdsInvalidos([])
    return toast.success('Status alterados com sucesso', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
    })
  }

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Enviar Ids</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <form onSubmit={updateStatus}>
        <Row>
          <Col className="mb-3" xs={12} md={4} lg={4}>
            <label htmlFor="ids">Insira os ids a serem alterados</label><br/>
            <textarea id="ids" className="input-textarea"></textarea>
          </Col>
          <Col className="mb-3" xs={12} md={3} lg={3}>
            <Col xs={12}>
              <label htmlFor="status">Escolha o status do resgate</label><br/>
              <select
                id="status"
                name="status"
                placeholder="Status"
                className="input-select mb-3"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option disabled value="">
                  Selecione um status
                </option>
                <option value="0">Não entregue</option>
                <option value="1">Entregue</option>
                <option value="2">Em trânsito</option>
              </select>
            </Col>
            {idsInvalidos.length !== 0 && (
              <Col xs={12}>
                <div className="idsInvalidos">
                  <label>Os seguintes ids não foram atualizados:</label><br/>
                  <div>
                    {idsInvalidos.map((item, index) => {
                      return (
                        <>
                          <span key={index}>{item}</span><br/>
                        </>
                      )
                    })}
                  </div>
                </div>
              </Col>
            )}
          </Col>
          <Col className="mb-3" xs={12} sm={12}>
            <button id="btn-enviar" type="submit">Enviar</button>
          </Col>
        </Row>
      </form>
    </Container>
  );
}
