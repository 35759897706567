import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './styles.css';

export default function Footer(props) {
  const ano = new Date();

  const autenticacao = useSelector((state) => {
    return state.autenticacao;
  });

  return (
    <footer className={props.fixed ? 'footer-fixed' : 'footer pt-2 pb-2'}>
      <Container className="d-flex justify-content-center align-items-center text-center h-100">
        <Row className="d-flex justify-content-center align-items-center text-center w-100">
          <Col xs={12} md={4} className="pb-2 pt-2">
            <span>
              {` Todos os direitos reservados -
          ${ano.getFullYear()} - Cerveja Premiada`}
            </span>
          </Col>
          <Col xs={12} md={4} className="pb-2 pt-2">
            <span>(11) 91100-3423 - contato@cervejapremiada.com.br</span>
          </Col>
          <Col xs={12} md={4} className="pb-2 pt-2">
            <span>
              <Link
                to={
                  autenticacao.autenticadoLoja
                    ? '/loja/politica-privacidade'
                    : '/politica-privacidade'
                }
              >
                <span className="text-white">Política de Privacidade</span>
              </Link>
            </span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
