/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import './styles.css';
import Toggle from 'react-bootstrap-toggle';
import ModalExcluir from './ModalExcluir/index';
import { Row, Col } from 'react-bootstrap';
import { CsvComponent } from '../CsvComponent';
import ModalFeedback from './ModalFeedback';

const { SearchBar } = Search;

export default function RemoteTable(props) {
  const ModalEditar = props.modalEdit;

  const [id, setId] = useState('');
  const [dataEditar, setDataEditar] = useState(null);
  const [mostrarModalEditar, setMostrarModalEditar] = useState(false);
  const [tituloModalExcluir, setTituloModalExcluir] = useState('');
  const [mostrarModalExcluir, setMostrarModalExcluir] = useState(false);
  const [mostrarModalFeedback, setMostrarModalFeedback] = useState(false);

  const [total, setTotal] = useState(1);
  const [data, setData] = useState([]);
  const [ordenacao, setOrdenacao] = useState([
    {
      dataField: props.ordenacaoInicial.campo,
      order: props.ordenacaoInicial.direcao,
    },
  ]);
  const [busca, setBusca] = useState('');
  const [limite, setLimite] = useState(10);
  const [pagina, setPagina] = useState(1);
  const [estaCarregando, setEstaCarregando] = useState(false);
  const [processandoCsv, setProcessandoCsv] = useState(false);

  useEffect(() => {
    searchData();
  }, [props.refresh]);

  useEffect(() => {
    estaCarregando && setData([]);
  }, [estaCarregando]);

  useEffect(() => {
    searchData();
  }, [ordenacao, limite, pagina]);

  const handleClick = () => {
    searchData();
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      searchData();
    }
  };

  const onTableChange = (type, newState) => {
    switch (type) {
      case 'sort':
        if (
          ordenacao[0].dataField !== newState.sortField ||
          ordenacao[0].order !== newState.sortOrder
        ) {
          setOrdenacao([
            {
              dataField: newState.sortField,
              order: newState.sortOrder,
            },
          ]);
        }

        break;
      case 'search':
        if (busca !== newState.searchText) {
          if (pagina > 1) {
            setPagina(1);
          }
          setBusca(newState.searchText);
        }

        break;
      case 'pagination':
        limite !== newState.sizePerPage && setLimite(newState.sizePerPage);
        pagina !== newState.page && setPagina(newState.page);
        break;
      default:
        break;
    }
  };

  const getData = async () => {
    setProcessandoCsv(true);
    const ordernar = {
      campo: ordenacao[0].dataField,
      direcao: ordenacao[0].order,
    };

    const result = await api.get(
      `${props.url}?&pagina=${pagina}&ordenacao=${JSON.stringify(
        ordernar
      )}&buscar=${busca}&limite=${limite}&filtros=${JSON.stringify(
        props.filtros ? props.filtros : {}
      )}`
    );
    setProcessandoCsv(false);
    return result.data.rows;
  };

  const searchData = async () => {
    try {
      setEstaCarregando(true);
      const ordernar = {
        campo: ordenacao[0].dataField,
        direcao: ordenacao[0].order,
      };

      const result = await api.get(
        `${props.url}?&pagina=${pagina}&ordenacao=${JSON.stringify(
          ordernar
        )}&buscar=${busca}&limite=${limite}&filtros=${JSON.stringify(
          props.filtros ? props.filtros : {}
        )}`
      );

      if (result) {
        setData(result.data.rows);
        setTotal(Number(result.data.count));
      }
      setEstaCarregando(false);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setEstaCarregando(false);
    }
  };

  const paginationOptions = {
    page: pagina,
    sizePerPage: limite,
    totalSize: total,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: total,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  function getColums() {
    const colunas = props.colunas;

    if (
      colunas.filter((c) => c.dataField === 'ativo' && c.isDummyField).length >
      0
    ) {
      const ativos = colunas.filter(
        (c) => c.dataField === 'ativo' && c.isDummyField
      )[0];

      ativos.formatter = (celula, valor) => {
        return (
          <Toggle
            onClick={(state) => updateStatus(state, valor.id)}
            on={<span>Sim</span>}
            off={<span>Não</span>}
            size="sm"
            offstyle="danger"
            active={valor.ativo === 1 ? true : false}
          />
        );
      };
    }

    if (
      colunas.filter((c) => c.dataField === 'apagar' && c.isDummyField).length >
      0
    ) {
      const apagar = colunas.filter(
        (c) => c.dataField === 'apagar' && c.isDummyField
      )[0];

      apagar.formatter = (celula, valor) => (
        <>
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              abrirModalExcluir(valor.id, valor[props.fieldModalExlcluir]);
            }}
          >
            <i className="fas fa-trash f-red"></i>
          </button>
        </>
      );
    }

    if (
      colunas.filter((c) => c.dataField === 'editar' && c.isDummyField).length >
      0
    ) {
      const editar = colunas.filter(
        (c) => c.dataField === 'editar' && c.isDummyField
      )[0];

      editar.formatter = (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalEditar(valor.id);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      );
    }

    if (
      colunas.filter((c) => c.dataField === 'feedback' && c.isDummyField)
        .length > 0
    ) {
      const feedback = colunas.filter(
        (c) => c.dataField === 'feedback' && c.isDummyField
      )[0];

      feedback.formatter = (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            handleModalFeedback(valor.id);
          }}
        >
          <i className="fas fa-comment"></i>
        </button>
      );
    }

    return colunas;
  }

  async function updateStatus(state, id) {
    const resultado = await api.put(`${props.url}/${id}`, {
      ativo: state ? 1 : 0,
    });

    if (resultado) {
      toast.success('Status atualizado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    }

    atualizarDados();
  }

  const abrirModalExcluir = (id, tituloModalExcluir) => {
    setTituloModalExcluir(tituloModalExcluir);
    setId(id);
    setMostrarModalExcluir(true);
  };

  const handleModalFeedback = (id) => {
    setId(id);
    setMostrarModalFeedback(true);
  };

  const handleCloseModalFeedback = () => {
    setId();
    setMostrarModalFeedback(false);
  };

  const fecharModalExcluir = () => {
    setTituloModalExcluir('');
    setId('');
    setMostrarModalExcluir(false);
  };

  const abrirModalEditar = (id) => {
    const dados = data.filter((d) => d.id === id);
    setDataEditar(dados[0]);
    setMostrarModalEditar(true);
  };

  const fecharModalEditar = () => {
    setDataEditar([]);
    setMostrarModalEditar(false);
  };

  const atualizarDados = () => {
    searchData();
  };

  return (
    <>
      <ToolkitProvider
        bootstrap4
        remote
        keyField={props.dataId}
        data={data}
        columns={getColums()}
        search
      >
        {(toolkitProps) => (
          <div>
            <Row>
              {props.filtrosRow}
              <Col xs={12} sm={6} md={3} className="d-flex flex-column">
                <label>Digite sua busca</label>
                <input
                  className="input-theme busca mr-1"
                  onChange={(e) => {
                    setBusca(e.target.value);
                  }}
                  value={busca}
                  type="text"
                  onKeyUp={handleEnterKey}
                />
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={1}
                className="d-flex flex-row row-btn-filtro"
              >
                <button
                  className="btn-busca mr-2"
                  type="submit"
                  onClick={handleClick}
                >
                  {estaCarregando ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    <i className="fas fa-search"></i>
                  )}
                </button>
                <CsvComponent
                  {...props.csvProps}
                  data={data}
                  columns={getColums()}
                  getData={getData}
                />
              </Col>
            </Row>
            <BootstrapTable
              remote
              loading={estaCarregando}
              onTableChange={onTableChange}
              classes="table-dark text-center rounded"
              wrapperClasses="rounded"
              bordered={false}
              pagination={paginationFactory(paginationOptions)}
              defaultSorted={ordenacao}
              noDataIndication={() =>
                estaCarregando ? (
                  <span>
                    Carregando
                    <i className="fas fa-circle-notch fa-spin ml-3"></i>
                  </span>
                ) : (
                  <span>Sem resultados</span>
                )
              }
              {...toolkitProps.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <ModalExcluir
        show={mostrarModalExcluir}
        onHide={fecharModalExcluir}
        tituloModalExcluir={tituloModalExcluir}
        id={id}
        url={props.url}
        atualizarDados={atualizarDados}
      />

      <ModalFeedback
        open={mostrarModalFeedback}
        onClose={handleCloseModalFeedback}
        id={id}
        atualizarDados={atualizarDados}
      />

      {!!props.modalEdit && (
        <ModalEditar
          show={mostrarModalEditar}
          onHide={fecharModalEditar}
          data={dataEditar}
          atualizarDados={atualizarDados}
        />
      )}
    </>
  );
}
